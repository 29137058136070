export const mockConsistIssue = {
    quota_consist_issue: true,
    stock_consist_issue: false,
    delivery_consist_issue: false,
    transaction_consist_issue: false,
    request_consist_issue: false
}

export const mockOverallIssue = {
    overall_quota_issue: 1,
    overall_stock_issue: 0,
    overall_devlivery_issue: 0,
    overall_transaction_issue: 0,
    overall_request_issue: 0
}

export const mockStateQuotaIssue = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: 'MY-10',
        state_name: 'Selangor',
        state_category: "State",
        total_company: 5,
        description: "Overall exceed quota issues in states of Selangor",
        total_issues: 1,
        consist_issue: true,
        total_exceed_quota_metric_ton: 1,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall exceed quota issues in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall exceed quota issues in federal territory of Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall exceed quota issues in federal territory of Labuan",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall exceed quota issues in federal territory of Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_exceed_quota_metric_ton: 0,
        total_exceed_quota_packaging: 0,
        total_exceed_quota_distribution: 0,
        total_exceed_quota_unit_sold: 0
    }
];

export const mockStockIssue = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Pahang",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-10",
        state_name: "Selangor",
        state_category: "State",
        total_company: 10,
        description: "Overall stock issues in states of Selangor",
        total_issues: 1,
        consist_issue: true,
        total_stock_loss: 10,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 10,
        description: "Overall stock issues in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0  
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall stock issues in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0  
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan Kuala Lumpur",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall stock issues in states of Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0  
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall stock issues in federal territory of Labuan",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall stock issues in federal territory of Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_stock_loss: 0,
        total_stock_defects: 0,
        total_stock_damage: 0,
        total_others: 0
    }
]

export const mockDeliveryIssue = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Pahang",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-10",
        state_name: "Selangor",
        state_category: "State",
        total_company: 10,
        description: "Overall delivery issues in states of Selangor",
        total_issues: 1,
        consist_issue: true,
        total_delivery_missing: 0,
        total_delivery_delay: 1,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 10,
        description: "Overall delivery issues in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0  
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall delivery issues in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0  
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan Kuala Lumpur",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall delivery issues in states of Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0  
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall delivery issues in federal territory of Labuan",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall delivery issues in federal territory of Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_delivery_missing: 0,
        total_delivery_delay: 0,
        total_delivery_misrouted: 0,
        total_others: 0
    }
];

export const mockTransactionIssue = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Pahang",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-10",
        state_name: "Selangor",
        state_category: "State",
        total_company: 10,
        description: "Overall transaction issues in states of Selangor",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 10,
        description: "Overall transaction issues in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0  
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall transaction issues in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0  
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan Kuala Lumpur",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall transaction issues in states of Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0  
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall transaction issues in federal territory of Labuan",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall transaction issues in federal territory of Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_suspicious_transaction: 0,
        total_unbalance_transaction: 0,
        total_others: 0
    }
];

export const mockRequestIssue = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Pahang",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-10",
        state_name: "Selangor",
        state_category: "State",
        total_company: 10,
        description: "Overall request issues in states of Selangor",
        total_issues: 1,
        consist_issue: true,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 10,
        description: "Overall request issues in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0  
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall request issues in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0  
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan Kuala Lumpur",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall request issues in states of Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0  
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall request issues in federal territory of Labuan",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall request issues in federal territory of Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_request_missing: 0,
        total_request_damage: 0,
        total_request_shortage: 0,
        total_others: 0
    }
];