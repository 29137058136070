import { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    Box, 
    Button,
    Dialog, 
    DialogTitle, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery,
    useTheme} from "@mui/material";

import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab"

import {
    DownloadOutlined,
    FactoryOutlined,
    LocalShippingOutlined,
    CloseOutlined,
    Inventory2Outlined,
    CalendarTodayOutlined,
    CalendarMonthOutlined,
    RequestPageOutlined,
    PendingOutlined,
    DoneOutlined
} from "@mui/icons-material"

import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import MapOrders from "modules/maps/orders/MapOrders";

import StatProgressBox from "components/stats/StatProgressBox";
import { getOrder } from "state/fetch/orders/orderApi";
import BarChartOrdersDaily from "components/barcharts/orders/BarChartOrdersDaily";
import BarChartOrdersMonthly from "components/barcharts/orders/BarChartOrdersMonthly";
import BarChartOrdersYearly from "components/barcharts/orders/BarChartOrdersYearly";
import StatesOrderStat from "modules/statesDistricts/orders/StatesOrderStat";
import { mockGovOrderChart, mockGovOrders } from "data/mockOrder";

const BootstrapDialog = styled(Dialog) (({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const Order = ({ isDashboard = false }) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    // region data
    const [region, setRegion] = useState({})

    // states and districts data
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])

    // tabbing handle change
    const [value, setValue] = useState('1')

    // handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    // handle bar chart tab change
    const [valueBarChartProducer, setValueBarChartProducer] = useState('1')
    const [valueBarChartPackager, setValueBarChartPackager] = useState('1')
    const [valueBarChartWholesaler, setValueBarChartWholesaler] = useState('1')

    const [totalReceiveDailyOrder, setTotalReceiveDailyOrder] = useState(0)
    const [totalReceiveDailyPendingOrder, setTotalReceiveDailyPendingOrder] = useState(0)
    const [totalReceiveDailyShippedOrder, setTotalReceiveDailyShippedOrder] = useState(0)
    const [totalReceiveDailyDeliveredOrder, setTotalReceiveDailyDeliveredOrder] = useState(0)
    const [totalReceiveDailyPaidOrder, setTotalReceiveDailyPaidOrder] = useState(0)
    const [totalReceiveDailyRejectOrder, setTotalReceiveDailyRejectOrder] = useState(0)

    const [totalReceiveMonthlyOrder, setTotalReceiveMonthlyOrder] = useState(0)
    const [totalReceiveMonthlyPendingOrder, setTotalReceiveMonthlyPendingOrder] = useState(0)
    const [totalReceiveMonthlyShippedOrder, setTotalReceiveMonthlyShippedOrder] = useState(0)
    const [totalReceiveMonthlyDeliveredOrder, setTotalReceiveMonthlyDeliveredOrder] = useState(0)
    const [totalReceiveMonthlyPaidOrder, setTotalReceiveMonthlyPaidOrder] = useState(0)
    const [totalReceiveMonthlyRejectOrder, setTotalReceiveMonthlyRejectOrder] = useState(0)

    const [totalReceiveYearlyOrder, setTotalReceiveYearlyOrder] = useState(0)
    const [totalReceiveYearlyPendingOrder, setTotalReceiveYearlyPendingOrder] = useState(0)
    const [totalReceiveYearlyShippedOrder, setTotalReceiveYearlyShippedOrder] = useState(0)
    const [totalReceiveYearlyDeliveredOrder, setTotalReceiveYearlyDeliveredOrder] = useState(0)
    const [totalReceiveYearlyPaidOrder, setTotalReceiveYearlyPaidOrder] = useState(0)
    const [totalReceiveYearlyRejectOrder, setTotalReceiveYearlyRejectOrder] = useState(0)

    // producer total receive order, pending, shipped, delivered, paid and reject order
    const [totalReceiveProducerDailyOrder, setTotalReceiveProducerDailyOrder] = useState(0);
    const [totalReceiveProducerDailyPendingOrder, setTotalReceiveProducerDailyPendingOrder] = useState(0);
    const [totalReceiveProducerDailyShippedOrder, setTotalReceiveProducerDailyShippedOrder] = useState(0);
    const [totalReceiveProducerDailyDeliveredOrder, setTotalReceiveProducerDailyDeliveredOrder] = useState(0);
    const [totalReceiveProducerDailyPaidOrder, setTotalReceiveProducerDailyPaidOrder] = useState(0);
    const [totalReceiveProducerDailyRejectOrder, setTotalReceiveProducerDailyRejectOrder] = useState(0);
    const [totalReceiveProducerMonthlyOrder, setTotalReceiveProducerMonthlyOrder] = useState(0);
    const [totalReceiveProducerMonthlyPendingOrder, setTotalReceiveProducerMonthlyPendingOrder] = useState(0);
    const [totalReceiveProducerMonthlyShippedOrder, setTotalReceiveProducerMonthlyShippedOrder] = useState(0);
    const [totalReceiveProducerMonthlyDeliveredOrder, setTotalReceiveProducerMonthlyDeliveredOrder] = useState(0);
    const [totalReceiveProducerMonthlyPaidOrder, setTotalReceiveProducerMonthlyPaidOrder] = useState(0);
    const [totalReceiveProducerMonthlyRejectOrder, setTotalReceiveProducerMonthlyRejectOrder] = useState(0);
    const [totalReceiveProducerYearlyOrder, setTotalReceiveProducerYearlyOrder] = useState(0)
    const [totalReceiveProducerYearlyPendingOrder, setTotalReceiveProducerYearlyPendingOrder] = useState(0);
    const [totalReceiveProducerYearlyShippedOrder, setTotalReceiveProducerYearlyShippedOrder] = useState(0);
    const [totalReceiveProducerYearlyDeliveredOrder, setTotalReceiveProducerYearlyDeliveredOrder] = useState(0);
    const [totalReceiveProducerYearlyPaidOrder, setTotalReceiveProducerYearlyPaidOrder] = useState(0);
    const [totalReceiveProducerYearlyRejectOrder, setTotalReceiveProducerYearlyRejectOrder] = useState(0);

    // packager total receive order, pending, shipped, delivered, paid and reject order
    const [totalReceivePackagerDailyOrder, setTotalReceivePackagerDailyOrder] = useState(0);
    const [totalReceivePackagerDailyPendingOrder, setTotalReceivePackagerDailyPendingOrder] = useState(0);
    const [totalReceivePackagerDailyShippedOrder, setTotalReceivePackagerDailyShippedOrder] = useState(0);
    const [totalReceivePackagerDailyDeliveredOrder, setTotalReceivePackagerDailyDeliveredOrder] = useState(0);
    const [totalReceivePackagerDailyPaidOrder, setTotalReceivePackagerDailyPaidOrder] = useState(0);
    const [totalReceivePackagerDailyRejectOrder, setTotalReceivePackagerDailyRejectOrder] = useState(0)
    const [totalReceivePackagerMonthlyOrder, setTotalReceivePackagerMonthlyOrder] = useState(0);
    const [totalReceivePackagerMonthlyPendingOrder, setTotalReceivePackagerMonthlyPendingOrder] = useState(0);
    const [totalReceivePackagerMonthlyShippedOrder, setTotalReceivePackagerMonthlyShippedOrder] = useState(0);
    const [totalReceivePackagerMonthlyDeliveredOrder, setTotalReceivePackagerMonthlyDeliveredOrder] = useState(0);
    const [totalReceivePackagerMonthlyPaidOrder, setTotalReceivePackagerMonthlyPaidOrder] = useState(0);
    const [totalReceivePackagerMonthlyRejectOrder, setTotalReceivePackagerMonthlyRejectOrder] = useState(0);
    const [totalReceivePackagerYearlyOrder, setTotalReceivePackagerYearlyOrder] = useState(0)
    const [totalReceivePackagerYearlyPendingOrder, setTotalReceivePackagerYearlyPendingOrder] = useState(0);
    const [totalReceivePackagerYearlyShippedOrder, setTotalReceivePackagerYearlyShippedOrder] = useState(0);
    const [totalReceivePackagerYearlyDeliveredOrder, setTotalReceivePackagerYearlyDeliveredOrder] = useState(0);
    const [totalReceivePackagerYearlyPaidOrder, setTotalReceivePackagerYearlyPaidOrder] = useState(0);
    const [totalReceivePackagerYearlyRejectOrder, setTotalReceivePackagerYearlyRejectOrder] = useState(0);

    // wholesaler total receive order, pending, shipped, delivered, paid and reject order
    const [totalReceiveWholesalerDailyOrder, setTotalReceiveWholesalerDailyOrder] = useState(0);
    const [totalReceiveWholesalerDailyPendingOrder, setTotalReceiveWholesalerDailyPendingOrder] = useState(0);
    const [totalReceiveWholesalerDailyShippedOrder, setTotalReceiveWholesalerDailyShippedOrder] = useState(0);
    const [totalReceiveWholesalerDailyDeliveredOrder, setTotalReceiveWholesalerDailyDeliveredOrder] = useState(0);
    const [totalReceiveWholesalerDailyPaidOrder, setTotalReceiveWholesalerDailyPaidOrder] = useState(0);
    const [totalReceiveWholesalerDailyRejectOrder, setTotalReceiveWholesalerDailyRejectOrder] = useState(0)
    const [totalReceiveWholesalerMonthlyOrder, setTotalReceiveWholesalerMonthlyOrder] = useState(0);
    const [totalReceiveWholesalerMonthlyPendingOrder, setTotalReceiveWholesalerMonthlyPendingOrder] = useState(0);
    const [totalReceiveWholesalerMonthlyShippedOrder, setTotalReceiveWholesalerMonthlyShippedOrder] = useState(0);
    const [totalReceiveWholesalerMonthlyDeliveredOrder, setTotalReceiveWholesalerMonthlyDeliveredOrder] = useState(0);
    const [totalReceiveWholesalerMonthlyPaidOrder, setTotalReceiveWholesalerMonthlyPaidOrder] = useState(0);
    const [totalReceiveWholesalerMonthlyRejectOrder, setTotalReceiveWholesalerMonthlyRejectOrder] = useState(0);
    const [totalReceiveWholesalerYearlyOrder, setTotalReceiveWholesalerYearlyOrder] = useState(0)
    const [totalReceiveWholesalerYearlyPendingOrder, setTotalReceiveWholesalerYearlyPendingOrder] = useState(0);
    const [totalReceiveWholesalerYearlyShippedOrder, setTotalReceiveWholesalerYearlyShippedOrder] = useState(0);
    const [totalReceiveWholesalerYearlyDeliveredOrder, setTotalReceiveWholesalerYearlyDeliveredOrder] = useState(0);
    const [totalReceiveWholesalerYearlyPaidOrder, setTotalReceiveWholesalerYearlyPaidOrder] = useState(0);
    const [totalReceiveWholesalerYearlyRejectOrder, setTotalReceiveWholesalerYearlyRejectOrder] = useState(0);

    // stock order producer, packager, wholesaler states
    const [stockOrderProducerState, setStockOrderProducerState] = useState([])
    const [stockOrderPackagerState, setStockOrderPackagerState] = useState([])
    const [stockOrderWholesalerState, setStockOrderWholesalerState] = useState([])

    const [producerCompany, setProducerCompany] = useState([]);
    const [packagerCompany, setPackagerCompany] = useState([]);
    const [wholesalerCompany, setWholesalerCompany] = useState([]);

    // handle bar chart tab change
    const handleBarChartProducerChange = (event, newValue) => {
        setValueBarChartProducer(newValue);
    }

    const handleBarChartPackagerChange = (event, newValue) => {
        setValueBarChartPackager(newValue);
    }

    const handleBarChartWholesalerChange = (event, newValue) => {
        setValueBarChartWholesaler(newValue);
    }

    useEffect(() => {
        getOrder().then((result) => {
            // initialize total order
            setTotalReceiveDailyOrder(result.total_receive_daily_order)
            setTotalReceiveDailyPendingOrder(result.total_receive_daily_pending_order)
            setTotalReceiveDailyShippedOrder(result.total_receive_daily_shipped_order)
            setTotalReceiveDailyDeliveredOrder(result.total_receive_daily_delivered_order)
            setTotalReceiveDailyPaidOrder(result.total_receive_daily_paid_order)
            setTotalReceiveDailyRejectOrder(result.total_receive_daily_reject_order)
            setTotalReceiveMonthlyOrder(result.total_receive_monthly_order)
            setTotalReceiveMonthlyPendingOrder(result.total_receive_monthly_pending_order)
            setTotalReceiveMonthlyShippedOrder(result.total_receive_monthly_shipped_order)
            setTotalReceiveMonthlyDeliveredOrder(result.total_receive_monthly_delivered_order)
            setTotalReceiveMonthlyPaidOrder(result.total_receive_monthly_paid_order)
            setTotalReceiveMonthlyRejectOrder(result.total_receive_monthly_reject_order)
            setTotalReceiveYearlyOrder(result.total_receive_yearly_order)
            setTotalReceiveYearlyPendingOrder(result.total_receive_yearly_pending_order)
            setTotalReceiveYearlyShippedOrder(result.total_receive_yearly_shipped_order)
            setTotalReceiveYearlyDeliveredOrder(result.total_receive_yearly_delivered_order)
            setTotalReceiveYearlyPaidOrder(result.total_receive_yearly_paid_order)
            setTotalReceiveYearlyRejectOrder(result.total_receive_yearly_reject_order)
            
            // initialize producer total order
            setTotalReceiveProducerDailyOrder(result.total_receive_producer_daily_order)
            setTotalReceiveProducerDailyPendingOrder(result.total_receive_producer_daily_pending_order)
            setTotalReceiveProducerDailyShippedOrder(result.total_receive_producer_daily_shipped_order)
            setTotalReceiveProducerDailyDeliveredOrder(result.total_receive_producer_daily_delivered_order)
            setTotalReceiveProducerDailyPaidOrder(result.total_receive_producer_daily_paid_order)
            setTotalReceiveProducerDailyRejectOrder(result.total_receive_producer_daily_reject_order)
            setTotalReceiveProducerMonthlyOrder(result.total_receive_producer_monthly_order)
            setTotalReceiveProducerMonthlyPendingOrder(result.total_receive_producer_monthly_pending_order)
            setTotalReceiveProducerMonthlyShippedOrder(result.total_receive_producer_monthly_shipped_order)
            setTotalReceiveProducerMonthlyDeliveredOrder(result.totat_receive_producer_monthly_delivered_order)
            setTotalReceiveProducerMonthlyPaidOrder(result.total_receive_producer_monthly_paid_order)
            setTotalReceiveProducerMonthlyRejectOrder(result.total_receive_producer_monthly_reject_order)
            setTotalReceiveProducerYearlyOrder(result.total_receive_producer_yearly_order)
            setTotalReceiveProducerYearlyPendingOrder(result.total_receive_producer_yearly_pending_order)
            setTotalReceiveProducerYearlyShippedOrder(result.total_receive_producer_yearly_shipped_order)
            setTotalReceiveProducerYearlyDeliveredOrder(result.total_receice_producer_yearly_delivered_order)
            setTotalReceiveProducerYearlyPaidOrder(result.total_receive_producer_yearly_paid_order)
            setTotalReceiveProducerYearlyRejectOrder(result.total_receive_producer_yearly_reject_order)

            // initialize packager total order
            setTotalReceivePackagerDailyOrder(result.total_receive_packager_daily_order)
            setTotalReceivePackagerDailyPendingOrder(result.total_receive_pacakger_daily_pending_order)
            setTotalReceivePackagerDailyShippedOrder(result.total_receive_packager_daily_shipped_order)
            setTotalReceivePackagerDailyDeliveredOrder(result.total_receive_packager_daily_delivered_order)
            setTotalReceivePackagerDailyPaidOrder(result.total_receive_packager_daily_paid_order)
            setTotalReceivePackagerDailyRejectOrder(result.total_receive_pacakger_daily_reject_order)
            setTotalReceivePackagerMonthlyOrder(result.total_receive_packager_monthly_order)
            setTotalReceivePackagerMonthlyPendingOrder(result.total_receive_packager_monthly_pending_order)
            setTotalReceivePackagerMonthlyShippedOrder(result.total_receive_packager_monthly_shipped_order)
            setTotalReceivePackagerMonthlyDeliveredOrder(result.total_receive_packager_monthly_delivered_order)
            setTotalReceivePackagerMonthlyPaidOrder(result.total_receive_packager_monthly_paid_order)
            setTotalReceivePackagerMonthlyRejectOrder(result.total_receive_packager_monthly_reject_order)
            setTotalReceivePackagerYearlyOrder(result.total_receive_packager_yearly_order)
            setTotalReceivePackagerYearlyPendingOrder(result.total_receive_packager_yearly_pending_order)
            setTotalReceivePackagerYearlyShippedOrder(result.total_receive_packager_yearly_shipped_order)
            setTotalReceivePackagerYearlyDeliveredOrder(result.total_receive_packager_yearly_delivered_order)
            setTotalReceivePackagerYearlyPaidOrder(result.total_receive_packager_yearly_paid_order)
            setTotalReceivePackagerYearlyRejectOrder(result.total_receive_pacakger_yearly_reject_order)

            // initialize wholesaler total order
            setTotalReceiveWholesalerDailyOrder(result.total_receive_wholesaler_daily_order)
            setTotalReceiveWholesalerDailyPendingOrder(result.total_receive_wholesaler_daily_pending_order)
            setTotalReceiveWholesalerDailyShippedOrder(result.total_receive_wholesaler_daily_shipped_order)
            setTotalReceiveWholesalerDailyDeliveredOrder(result.total_receive_wholesaler_daily_delivered_order)
            setTotalReceiveWholesalerDailyPaidOrder(result.total_receive_wholesaler_daily_paid_order)
            setTotalReceiveWholesalerDailyRejectOrder(result.total_receive_wholesaler_daily_reject_order)
            setTotalReceiveWholesalerMonthlyOrder(result.total_receive_wholesaler_monthly_order)
            setTotalReceiveWholesalerMonthlyPendingOrder(result.total_receive_wholesaler_monthly_pending_order)
            setTotalReceiveWholesalerMonthlyShippedOrder(result.total_receive_wholesaler_monthly_shipped_order)
            setTotalReceiveWholesalerMonthlyDeliveredOrder(result.total_receive_wholesaler_monthly_delivered_order)
            setTotalReceiveWholesalerMonthlyPaidOrder(result.total_receive_wholesaler_monthly_paid_order)
            setTotalReceiveWholesalerMonthlyRejectOrder(result.total_receive_wholesaler_monthly_reject_order)
            setTotalReceiveWholesalerYearlyOrder(result.total_receive_wholesaler_yearly_order)
            setTotalReceiveWholesalerYearlyPendingOrder(result.total_receive_wholesaler_yearly_pending_order)
            setTotalReceiveWholesalerYearlyShippedOrder(result.total_receive_wholesaler_yearly_shipped_order)
            setTotalReceiveWholesalerYearlyDeliveredOrder(result.total_receive_wholesaler_yearly_delivered_order)
            setTotalReceiveWholesalerYearlyPaidOrder(result.total_receive_wholesaler_yearly_paid_order)
            setTotalReceiveWholesalerYearlyRejectOrder(result.total_receive_wholesaler_yearly_reject_order)

            // initialize stock order state data
            setStockOrderProducerState(result.stock_order_producer_states)
            setStockOrderPackagerState(result.stock_order_packager_states)
            setStockOrderWholesalerState(result.stock_order_wholesaler_states)

            setProducerCompany(result.producers)
            setPackagerCompany(result.packagers)
            setWholesalerCompany(result.wholesalers)

            setRegion({
                center: result.center,
                zoom: result.zoom
            })
            setStates(result.states)
            setDistricts(result.districts)
        })
    }, [])

    const emptyData = [];

    const createColumn = ({ isProducer = false }) => {
        const columns = [
            {
                field: "stock_order_id",
                headerName: "ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "qr_id",
                headerName: "QRID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "provider_unique_id",
                headerName: "Supplier ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "provider_company_name",
                headerName: "Supplier Name",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "recipient_unique_id",
                headerName: "Requester ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "recipient_company_name",
                headerName: "Requester Name",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "total_order",
                headerName: isProducer ? "Total Order (MT)" : "Total Order",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "total_price",
                headerName: "Total Price",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "payment_status",
                headerName: "Payment Status",
                headerAlign: "center",
                align: "center",
                width: 180,
                renderCell: (params) => {
                    switch(params.row.payment_status) {
                        case "Paid":
                            return (
                                <Typography
                                    sx={{
                                        backgroundColor: "green",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {params.row.payment_status}
                                </Typography>
                            )
                        case "Cancel":
                            return (
                                <Typography
                                    sx={{
                                        backgroundColor: "red",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {params.row.payment_status}
                                </Typography>
                            )
                        default:
                            return (
                                <Typography
                                    sx={{
                                        backgroundColor: "gray",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {params.row.payment_status}
                                </Typography>
                            )
                    }
                }
            },
            {
                field: "status_order",
                headerName: "Status",
                headerAlign: "center",
                align: "center",
                width: 180,
                renderCell: (params) => {
                    switch(params.row.status_order) {
                        case "Shipped":
                            return (
                                <Typography
                                    sx={{
                                        backgroundColor: "yellow",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {params.row.status_order}
                                </Typography>
                            )
                        case "Delivered":
                            return (
                                <Typography
                                    sx={{
                                        backgroundColor: "green",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {params.row.status_order}
                                </Typography>
                            )
                        case "Reject":
                            return (
                                <Typography
                                    sx={{
                                        backgroundColor: "red",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {params.row.status_order}
                                </Typography>
                            )
                        default:
                            return (
                                <Typography
                                sx={{
                                    backgroundColor: "grey",
                                    fontWeight: "bold",
    
                                }}
                                >
                                    {params.row.status_order}
                                </Typography>
                            )
                    }
                }
            },
            {
                field: "remark",
                headerName: "Remark",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "date_order",
                headerName: "Date Order",
                headerAlign: "center",
                align: "center",
                width: 100
            },
            {
                field: "time_order",
                headerName: "Time Order",
                headerAlign: "center",
                align: "center",
                width: 100
            }
        ]
        return columns
    }

    const manageColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: "100%"
                        }}
                        onClick={() => {
                            
                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        }
    ]

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Order Report Dashboard" subtitle="Order Report Activity"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                onClick={() => {
                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                {/* Daily */ }
                <StatBox
                    title="Total Order"
                    value={totalReceiveDailyOrder}
                    description="Daily Receive"
                    icon={
                        <RequestPageOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Pending Order"
                    value={totalReceiveDailyPendingOrder}
                    description="Daily Receive"
                    icon={
                        <PendingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Shipped Order"
                    value={totalReceiveDailyShippedOrder}
                    description="Daily Receive"
                    icon={
                        <LocalShippingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Delivered Order"
                    value={totalReceiveDailyDeliveredOrder}
                    description="Daily Receive"
                    icon={
                        <Inventory2Outlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Paid Order"
                    value={totalReceiveDailyPaidOrder}
                    description="Daily Receive"
                    icon={
                        <DoneOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Reject Order"
                    value={totalReceiveDailyRejectOrder}
                    description="Daily Receive"
                    icon={
                        <CloseOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
            </Box>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                {/* Monthly */ }
                <StatBox
                    title="Total Order"
                    value={totalReceiveMonthlyOrder}
                    description="Monthly Receive"
                    icon={
                        <RequestPageOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Pending Order"
                    value={totalReceiveMonthlyPendingOrder}
                    description="Monthly Receive"
                    icon={
                        <PendingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Shipped Order"
                    value={totalReceiveMonthlyShippedOrder}
                    description="Monthly Receive"
                    icon={
                        <LocalShippingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Delivered Order"
                    value={totalReceiveMonthlyDeliveredOrder}
                    description="Monthly Receive"
                    icon={
                        <Inventory2Outlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Paid Order"
                    value={totalReceiveMonthlyPaidOrder}
                    description="Monthly Receive"
                    icon={
                        <DoneOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Reject Order"
                    value={totalReceiveMonthlyRejectOrder}
                    description="Monthly Receive"
                    icon={
                        <CloseOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
            </Box>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                {/* Yearly */ }
                <StatBox
                    title="Total Order"
                    value={totalReceiveYearlyOrder}
                    description="Yearly Receive"
                    icon={
                        <RequestPageOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Pending Order"
                    value={totalReceiveYearlyPendingOrder}
                    description="Yearly Receive"
                    icon={
                        <PendingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Shipped Order"
                    value={totalReceiveYearlyShippedOrder}
                    description="Yearly Receive"
                    icon={
                        <LocalShippingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Delivered Order"
                    value={totalReceiveYearlyDeliveredOrder}
                    description="Yearly Receive"
                    icon={
                        <Inventory2Outlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Paid Order"
                    value={totalReceiveYearlyPaidOrder}
                    description="Yearly Receive"
                    icon={
                        <DoneOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Reject Order"
                    value={totalReceiveYearlyRejectOrder}
                    description="Yearly Receive"
                    icon={
                        <CloseOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
            </Box>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(10, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                variant="fullWidth"
                                onChange={handleChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                            >
                                <Tab icon={<FactoryOutlined/>} iconPosition="start" label="Producer (Level 1)" value="1"/>
                                <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Packager (Level 2)" value="2"/>
                                <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Wholesaler (Level 3)" value="3"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Order"
                                    value={totalReceiveProducerDailyOrder}
                                    description="Daily Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Order"
                                    value={totalReceiveProducerMonthlyOrder}
                                    description="Monthly Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Order"
                                    value={totalReceiveProducerYearlyOrder}
                                    description="Yearly Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                            </Box>
                            <Box height="100%" width="100%">
                                <MapOrders items={producerCompany} position={region}/>
                            </Box>
                            <TabContext value={valueBarChartProducer}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleBarChartProducerChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersDaily data={mockGovOrderChart}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceiveProducerDailyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceiveProducerDailyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceiveProducerDailyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceiveProducerDailyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceiveProducerDailyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersMonthly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceiveProducerMonthlyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceiveProducerMonthlyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceiveProducerMonthlyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceiveProducerMonthlyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceiveProducerMonthlyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersYearly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceiveProducerYearlyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceiveProducerYearlyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceiveProducerYearlyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceiveProducerYearlyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceiveProducerYearlyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <Box
                                    mt="10px"
                                    height="60vh"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            borderRadius: "5rem"
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: theme.palette.background.alt,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${theme.palette.secondary[200]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.stock_order_id}
                                        rows={mockGovOrders}
                                        columns={createColumn({ isProducer: true }).concat(manageColumn)}
                                        components={{ Toolbar: QuickSearchToolbar }}
                                    />
                                </Box>
                            </TabContext>
                            <Box
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                justifyContent="space-between"
                                rowGap="20px"
                                columnGap="1.33%"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
                                }}
                            >
                                {stockOrderProducerState.map(
                                    ({
                                        sid,
                                        state_name,
                                        state_category,
                                        total_company,
                                        description,
                                        total_issues,
                                        consist_issue,
                                        total_receive_daily_order,
                                        total_receive_daily_pending_order,
                                        total_receive_daily_shipped_order,
                                        total_receive_daily_delivered_order,
                                        total_receive_daily_paid_order,
                                        total_receive_daily_reject_order,
                                        total_receive_monthly_order,
                                        total_receive_monthly_pending_order,
                                        total_receive_monthly_shipped_order,
                                        total_receive_monthly_delivered_order,
                                        total_receive_monthly_paid_order,
                                        total_receive_monthly_reject_order,
                                        total_receive_yearly_order,
                                        total_receive_yearly_pending_order,
                                        total_receive_yearly_shipped_order,
                                        total_receive_yearly_delivered_order,
                                        total_receive_yearly_paid_order,
                                        total_receive_yearly_reject_order
                                    }) => (
                                        <StatesOrderStat
                                            key={sid}
                                            sid={sid}
                                            state_name={state_name}
                                            state_category={state_category}
                                            total_company={total_company}
                                            description={description}
                                            total_issues={total_issues}
                                            consist_issue={consist_issue}
                                            total_receive_daily_order={total_receive_daily_order}
                                            total_receive_daily_pending_order={total_receive_daily_pending_order}
                                            total_receive_daily_shipped_order={total_receive_daily_shipped_order}
                                            total_receive_daily_delivered_order={total_receive_daily_delivered_order}
                                            total_receive_daily_paid_order={total_receive_daily_paid_order}
                                            total_receive_daily_reject_order={total_receive_daily_reject_order}
                                            total_receive_monthly_order={total_receive_monthly_order}
                                            total_receive_monthly_pending_order={total_receive_monthly_pending_order}
                                            total_receive_monthly_shipped_order={total_receive_monthly_shipped_order}
                                            total_receive_monthly_delivered_order={total_receive_monthly_delivered_order}
                                            total_receive_monthly_paid_order={total_receive_monthly_paid_order}
                                            total_receive_monthly_reject_order={total_receive_monthly_reject_order}
                                            total_receive_yearly_order={total_receive_yearly_order}
                                            total_receive_yearly_pending_order={total_receive_yearly_pending_order}
                                            total_receive_yearly_shipped_order={total_receive_yearly_shipped_order}
                                            total_receive_yearly_delivered_order={total_receive_yearly_delivered_order}
                                            total_receive_yearly_paid_order={total_receive_yearly_paid_order}
                                            total_receive_yearly_reject_order={total_receive_yearly_reject_order}
                                        />
                                    )
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Order"
                                    value={totalReceivePackagerDailyOrder}
                                    description="Daily Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Order"
                                    value={totalReceivePackagerMonthlyOrder}
                                    description="Monthly Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Order"
                                    value={totalReceivePackagerYearlyOrder}
                                    description="Yearly Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                            </Box>
                            <Box height="100%" width="100%">
                                <MapOrders items={packagerCompany} position={region}/>
                            </Box>
                            <TabContext value={valueBarChartPackager}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleBarChartPackagerChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersDaily/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceivePackagerDailyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceivePackagerDailyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceivePackagerDailyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceivePackagerDailyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceivePackagerDailyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersMonthly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceivePackagerMonthlyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceivePackagerMonthlyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceivePackagerMonthlyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceivePackagerMonthlyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceivePackagerMonthlyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersYearly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceivePackagerYearlyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceivePackagerYearlyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceivePackagerYearlyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceivePackagerYearlyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceivePackagerYearlyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <Box
                                    mt="10px"
                                    height="60vh"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            borderRadius: "5rem"
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: theme.palette.background.alt,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${theme.palette.secondary[200]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.stock_order_id}
                                        rows={mockGovOrders}
                                        columns={createColumn({ isProducer: false }).concat(manageColumn)}
                                        components={{ Toolbar: QuickSearchToolbar }}
                                    />
                                </Box>
                            </TabContext>
                            <Box
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                justifyContent="space-between"
                                rowGap="20px"
                                columnGap="1.33%"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
                                }}
                            >
                                {stockOrderPackagerState.map(
                                    ({
                                        sid,
                                        state_name,
                                        state_category,
                                        total_company,
                                        description,
                                        total_issues,
                                        consist_issue,
                                        total_receive_daily_order,
                                        total_receive_daily_pending_order,
                                        total_receive_daily_shipped_order,
                                        total_receive_daily_delivered_order,
                                        total_receive_daily_paid_order,
                                        total_receive_daily_reject_order,
                                        total_receive_monthly_order,
                                        total_receive_monthly_pending_order,
                                        total_receive_monthly_shipped_order,
                                        total_receive_monthly_delivered_order,
                                        total_receive_monthly_paid_order,
                                        total_receive_monthly_reject_order,
                                        total_receive_yearly_order,
                                        total_receive_yearly_pending_order,
                                        total_receive_yearly_shipped_order,
                                        total_receive_yearly_delivered_order,
                                        total_receive_yearly_paid_order,
                                        total_receive_yearly_reject_order
                                    }) => (
                                        <StatesOrderStat
                                            key={sid}
                                            sid={sid}
                                            state_name={state_name}
                                            state_category={state_category}
                                            total_company={total_company}
                                            description={description}
                                            total_issues={total_issues}
                                            consist_issue={consist_issue}
                                            total_receive_daily_order={total_receive_daily_order}
                                            total_receive_daily_pending_order={total_receive_daily_pending_order}
                                            total_receive_daily_shipped_order={total_receive_daily_shipped_order}
                                            total_receive_daily_delivered_order={total_receive_daily_delivered_order}
                                            total_receive_daily_paid_order={total_receive_daily_paid_order}
                                            total_receive_daily_reject_order={total_receive_daily_reject_order}
                                            total_receive_monthly_order={total_receive_monthly_order}
                                            total_receive_monthly_pending_order={total_receive_monthly_pending_order}
                                            total_receive_monthly_shipped_order={total_receive_monthly_shipped_order}
                                            total_receive_monthly_delivered_order={total_receive_monthly_delivered_order}
                                            total_receive_monthly_paid_order={total_receive_monthly_paid_order}
                                            total_receive_monthly_reject_order={total_receive_monthly_reject_order}
                                            total_receive_yearly_order={total_receive_yearly_order}
                                            total_receive_yearly_pending_order={total_receive_yearly_pending_order}
                                            total_receive_yearly_shipped_order={total_receive_yearly_shipped_order}
                                            total_receive_yearly_delivered_order={total_receive_yearly_delivered_order}
                                            total_receive_yearly_paid_order={total_receive_yearly_paid_order}
                                            total_receive_yearly_reject_order={total_receive_yearly_reject_order}
                                        />
                                    )
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                        title="Total Order"
                                        value={totalReceiveWholesalerDailyOrder}
                                        description="Daily Receive"
                                        icon={
                                            <RequestPageOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                            />
                                        }
                                    />
                                <StatBox
                                    title="Total Order"
                                    value={totalReceiveWholesalerMonthlyOrder}
                                    description="Monthly Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Order"
                                    value={totalReceiveWholesalerYearlyOrder}
                                    description="Yearly Receive"
                                    icon={
                                        <RequestPageOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                            </Box>
                            <Box height="100%" width="100%">
                                <MapOrders items={wholesalerCompany} position={region}/>
                            </Box>
                            <TabContext value={valueBarChartWholesaler}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleBarChartWholesalerChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersDaily/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceiveWholesalerDailyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceiveWholesalerDailyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceiveWholesalerDailyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceiveWholesalerDailyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceiveWholesalerDailyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Daily"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersMonthly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceiveWholesalerMonthlyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceiveWholesalerMonthlyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceiveWholesalerMonthlyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceiveWholesalerMonthlyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceiveWholesalerMonthlyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Monthly"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartOrdersYearly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatProgressBox
                                            title="Total Pending Order"
                                            value={totalReceiveWholesalerYearlyPendingOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <PendingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Shipped Order"
                                            value={totalReceiveWholesalerYearlyShippedOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <LocalShippingOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Delivered Order"
                                            value={totalReceiveWholesalerYearlyDeliveredOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <Inventory2Outlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Paid Order"
                                            value={totalReceiveWholesalerYearlyPaidOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <DoneOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                                />
                                            }
                                        />
                                        <StatProgressBox
                                            title="Total Reject Order"
                                            value={totalReceiveWholesalerYearlyRejectOrder}
                                            rate="0%"
                                            progress={0}
                                            description="Yearly"
                                            icon={
                                                <CloseOutlined
                                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <Box
                                    mt="10px"
                                    height="60vh"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            borderRadius: "5rem"
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: theme.palette.background.alt,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${theme.palette.secondary[200]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.stock_order_id}
                                        rows={mockGovOrders}
                                        columns={createColumn({ isProducer: false }).concat(manageColumn)}
                                        components={{ Toolbar: QuickSearchToolbar }}
                                    />
                                </Box>
                            </TabContext>
                            <Box
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                justifyContent="space-between"
                                rowGap="20px"
                                columnGap="1.33%"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
                                }}
                            >
                                {stockOrderWholesalerState.map(
                                    ({
                                        sid,
                                        state_name,
                                        state_category,
                                        total_company,
                                        description,
                                        total_issues,
                                        consist_issue,
                                        total_receive_daily_order,
                                        total_receive_daily_pending_order,
                                        total_receive_daily_shipped_order,
                                        total_receive_daily_delivered_order,
                                        total_receive_daily_paid_order,
                                        total_receive_daily_reject_order,
                                        total_receive_monthly_order,
                                        total_receive_monthly_pending_order,
                                        total_receive_monthly_shipped_order,
                                        total_receive_monthly_delivered_order,
                                        total_receive_monthly_paid_order,
                                        total_receive_monthly_reject_order,
                                        total_receive_yearly_order,
                                        total_receive_yearly_pending_order,
                                        total_receive_yearly_shipped_order,
                                        total_receive_yearly_delivered_order,
                                        total_receive_yearly_paid_order,
                                        total_receive_yearly_reject_order
                                    }) => (
                                        <StatesOrderStat
                                            key={sid}
                                            sid={sid}
                                            state_name={state_name}
                                            state_category={state_category}
                                            total_company={total_company}
                                            description={description}
                                            total_issues={total_issues}
                                            consist_issue={consist_issue}
                                            total_receive_daily_order={total_receive_daily_order}
                                            total_receive_daily_pending_order={total_receive_daily_pending_order}
                                            total_receive_daily_shipped_order={total_receive_daily_shipped_order}
                                            total_receive_daily_delivered_order={total_receive_daily_delivered_order}
                                            total_receive_daily_paid_order={total_receive_daily_paid_order}
                                            total_receive_daily_reject_order={total_receive_daily_reject_order}
                                            total_receive_monthly_order={total_receive_monthly_order}
                                            total_receive_monthly_pending_order={total_receive_monthly_pending_order}
                                            total_receive_monthly_shipped_order={total_receive_monthly_shipped_order}
                                            total_receive_monthly_delivered_order={total_receive_monthly_delivered_order}
                                            total_receive_monthly_paid_order={total_receive_monthly_paid_order}
                                            total_receive_monthly_reject_order={total_receive_monthly_reject_order}
                                            total_receive_yearly_order={total_receive_yearly_order}
                                            total_receive_yearly_pending_order={total_receive_yearly_pending_order}
                                            total_receive_yearly_shipped_order={total_receive_yearly_shipped_order}
                                            total_receive_yearly_delivered_order={total_receive_yearly_delivered_order}
                                            total_receive_yearly_paid_order={total_receive_yearly_paid_order}
                                            total_receive_yearly_reject_order={total_receive_yearly_reject_order}
                                        />
                                    )
                                )}
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>   
            </Box>
        </Box>
    );
}

export default Order;