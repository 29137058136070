export const mockQuotaListIssue = [
    {
        issue_id: 1,
        issue_name: 'Failed to Deliver Metric Ton Quota',
        issue_category: 'Failed Delivery',
        issue_status: 'Pending',
        issue_date_register: '15/08/2023',
        issue_time_register: '00:00:01'
    }
];

export const mockStockListIssue = [];

export const mockDeliveryListIssue = [];

export const mockTransactionListIssue = [];

export const mockRequestListIssue = [];