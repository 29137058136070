import { Box, useMediaQuery } from "@mui/material";
import NavbarLogin from "modules/navbars/NavbarLogin";
import { Outlet } from "react-router";

const LayoutLogin = () => {
    const isNoneMobile = useMediaQuery("(min-wdith: 600px)");
    
    return (
        <Box
            display={isNoneMobile ? "flex" : "block"}
            width="100%"
            height="100%"
        >
            <Box flexGrow={1}>
                <NavbarLogin/>
                <Outlet/>
            </Box>
        </Box>
    );
}

export default LayoutLogin;