import React from "react";
import { 
    Typography, 
    useMediaQuery, 
    Box,
    useTheme,
    Container,
    Button
} from "@mui/material";

import {
    google_map_api
} from 'state/constants'

import { GoogleMap, Marker, useLoadScript, InfoWindow } from "@react-google-maps/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MapCompany = ({items, position}) => {
    const theme = useTheme();
    const matches = useMediaQuery('(min-width: 600px)');
    const [activeMarker, setActiveMarker] = useState(null);
    const navigate = useNavigate();

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: google_map_api
    })

    if(!isLoaded) {
        return (
            <Typography>Unable to load Google</Typography>
        );
    }

    const handleActiveMarker = (marker) => {
        if(marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    }

    return (
        <Box height='100%' width='100%'>
            <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={position.center}
                    zoom={position.zoom}
                >
                    {items.map(
                        ({
                            cid,
                            company_name,
                            company_latitude,
                            company_longitude,
                            unique_id,
                            company_category,
                            company_ssm,
                            company_license,
                            company_start_csa_license,
                            company_end_csa_license
                        }) => (
                            <Marker
                                key={cid}
                                title={company_name}
                                position={{
                                    lat: parseFloat(company_latitude, 10),
                                    lng: parseFloat(company_longitude, 10)
                                }}

                                onClick={() => {
                                    handleActiveMarker(cid)
                                }}
                            >
                                {activeMarker === cid ? (
                                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                        <Container component="main" maxWidth="xs">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Typography variant="h6" color={theme.palette.primary.dark}>
                                                    {company_name}
                                                </Typography>
                                                <Typography variant="subtitle2" color={theme.palette.primary.dark}>
                                                    {unique_id}
                                                </Typography>
                                                <Typography variant="subtitle2" color={theme.palette.primary.dark}>
                                                    {company_category}
                                                </Typography>
                                                
                                                <Box component="main" sx={{ mt: 2}}>
                                                    <Typography variant="body2" color={theme.palette.primary.dark}>
                                                        Company SSM: {company_ssm}
                                                    </Typography>
                                                    <Typography variant="body2" color={theme.palette.primary.dark}>
                                                        Company CSA License: {company_license}
                                                    </Typography>
                                                    <Typography variant="body2" color={theme.palette.primary.dark}>
                                                        Start CSA License: {company_start_csa_license}
                                                    </Typography>
                                                    <Typography variant="body2" color={theme.palette.primary.dark}>
                                                        End CSA License: {company_end_csa_license}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        mt: 1,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            navigate(`/companies/${unique_id}`)
                                                        }}
                                                        variant="contained"
                                                        color="info"
                                                        sx={{ 
                                                            backgroundColor: theme.palette.secondary.light,
                                                            color: theme.palette.background.alt,
                                                            fontSize: "12px",
                                                            fontWeight: "bold",
                                                            width: "100px",
                                                            height: "30px"
                                                        }}
                                                    >
                                                        View
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Container>
                                    </InfoWindow>
                                ) : null}
                            </Marker>
                        )
                    )}
            </GoogleMap>
        </Box>
    )
}

export default MapCompany