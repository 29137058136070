import { network } from "../network";

const url_sale = `${network.domain_api}/sale`;

/**
 * Get sale data
 * 
 * @returns sale data
 */
export const getSale = async() => {
    let response
    try {
        response = await fetch(`${url_sale}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get sale state
 * 
 * @param {*} param0 sale state id
 * @returns sale state data
 */
export const getSaleState = async ({sid}) => {
    let response
    try {
        response = await fetch(`${url_sale}/states/${sid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get sale district data
 * 
 * @param {*} param0 sale district id
 * @returns sale district data
 */
export const getSaleDistrict = async ({sid, did}) => {
    let response
    try {
        response = await fetch(`${url_sale}/states/district/${sid}/${did}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}