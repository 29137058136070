import { Box, Button, Card, CardActions, CardContent, CardHeader, Collapse, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Tab, TextField, Typography, useTheme } from "@mui/material";
import { mockTreeCoupling, mockTreeOrg } from "data/mockTree";
import Force from 'react-force-graph-2d'

import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { CallReceivedOutlined, CloseOutlined, LocalShippingOutlined } from "@mui/icons-material";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DistributeSupplyTree from "modules/trees/DistributeSupplyTree";
import ReceiveSupplyTree from "modules/trees/ReceiveSupplyTree";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    }
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ): null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

const DistributeSupplyForce = ({forceData}) => {
    const theme = useTheme();
    const height = window.outerHeight;
    const width = window.outerWidth;

    const [distributeTreeData, setDistributeTreeData] = useState({})
    const [receivesTreeData, setReceivesTreeData] = useState({})
    const [nodeLicense, setNodeLicense] = useState("")
    const [nodeName, setNodeName] = useState("")
    const [nodeType, setNodeType] = useState("")
    const [value, setValue] = useState('1');
    const [open, setOpen] = useState(false);

    const parsedWidth = width * 0.71;
    const parsedHeight = height * 0.73;

    const handleTreeData = ({id}) => {
        console.log(id)
        setDistributeTreeData(mockTreeOrg)
        setReceivesTreeData(mockTreeOrg)
    }

    const handleValueChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleOpen = (id) => {
        setDistributeTreeData(id);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const textCanvas = useCallback((node, ctx, globalScale) => {
        const label = node.id;
        const fontSize = 14 / globalScale;

        ctx.font = `${fontSize}px Sans-Serif`;

        const textWidth = ctx.measureText(label).width;
        const backgroundDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2);

        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.fillRect(node.x - backgroundDimensions[0] / 2, node.y - backgroundDimensions[1] / 2, ...backgroundDimensions);

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = node.color;

        ctx.fillText(label, node.x, node.y);

        node.backgroundDimensions = backgroundDimensions; // to re-use in nodePointerAreaPaint
    })

    const DistributeReceiverTree = () => {
        return (
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        variant="fullWidth"
                        onChange={handleValueChange}
                        textColor="inherit"
                        indicatorColor="secondary"
                    >
                        <Tab icon={<LocalShippingOutlined/>} label="Distributions" value="1"/>
                        <Tab icon={<CallReceivedOutlined/>} label="Receives" value="2"/>
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Card
                        sx={{
                            backgroundImage: "none",
                            backgroundColor: theme.palette.background.alt,
                            borderRadius: "0.55rem",
                        }}
                    >
                        <CardHeader subheader="Company Details"/>
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="CSA License"
                                        name="csa_license"
                                        value={nodeName}
                                        type="text"
                                        color="secondary"
                                        inputProps={
                                            {readOnly: true}
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Company Name"
                                        name="company_name"
                                        value={nodeName}
                                        type="text"
                                        color="secondary"
                                        inputProps={
                                            {readOnly: true}
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Company Type"
                                        name="company_type"
                                        value={nodeName}
                                        type="text"
                                        color="secondary"
                                        inputProps={
                                            {readOnly: true}
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider/>
                        <CardContent>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold"
                                }}
                                color={theme.palette.secondary[300]}
                                gutterBottom
                            >
                                Distribution Network Flow
                            </Typography>
                            <Box mt="20px">
                                <DistributeSupplyTree data={mockTreeOrg}/>
                            </Box>
                        </CardContent>
                    </Card>
                </TabPanel>
                <TabPanel value="2">
                    <Card
                        sx={{
                            backgroundImage: "none",
                            backgroundColor: theme.palette.background.alt,
                            borderRadius: "0.55rem",
                        }}
                    >
                        <CardHeader subheader="Company Details"/>
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="CSA License"
                                        name="csa_license"
                                        value={nodeName}
                                        type="text"
                                        color="secondary"
                                        inputProps={
                                            {readOnly: true}
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Company Name"
                                        name="company_name"
                                        value={nodeName}
                                        type="text"
                                        color="secondary"
                                        inputProps={
                                            {readOnly: true}
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Company Type"
                                        name="company_type"
                                        value={nodeName}
                                        type="text"
                                        color="secondary"
                                        inputProps={
                                            {readOnly: true}
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider/>
                        <CardContent>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold"
                                }}
                                color={theme.palette.secondary[300]}
                                gutterBottom
                            >
                                Receives Network Flow
                            </Typography>
                            <Box mt="20px">
                                <ReceiveSupplyTree data={mockTreeOrg}/>
                            </Box>
                        </CardContent>
                    </Card>
                </TabPanel>
            </TabContext>
        )
    }

    return (
        <Box display="flex" flexGrow={1} flexDirection='column'>
            <Box
                sx={{
                    height: "80vh",
                    background: "white",
                }}
            >
                <Force
                    height={parsedHeight}
                    width={parsedWidth}
                    graphData={forceData}
                    nodeLabel="id"
                    nodeAutoColorBy="group"
                    nodeCanvasObject={textCanvas}
                    onNodeDragEnd={node => {
                        node.fx = node.x;
                        node.fy = node.y;
                    }}
                    linkCurvature={0.25}
                    linkDirectionalArrowLength={3.5}
                    linkDirectionalArrowRelPos={1}
                    linkDirectionalParticles="value"
                    linkDirectionalParticleSpeed={d => d.value * 0.001}
                    minZoom={1.5}
                    onNodeClick={node => {
                        handleTreeData(node.id)
                        handleOpen()

                        setNodeLicense(node.id)
                        setNodeName(node.id)
                        setNodeType(node.group)
                    }}
                />
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="view-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        id="view-dialog-title"
                        onClose={handleClose}
                        sx={{
                            backgroundImage: "none",
                            backgroundColor: theme.palette.background.default,
                        }}
                    >
                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                            {nodeName}
                        </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent
                        dividers
                        sx={{
                            backgroundImage: "none",
                            backgroundColor: theme.palette.background.default
                        }}
                    >
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <DistributeReceiverTree/>
                        </Box>
                    </DialogContent>
                </BootstrapDialog>
            </Box>
        </Box>
    )
}

export default DistributeSupplyForce;