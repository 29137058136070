import { network } from "state/fetch/network";

const url_retailer_company = `${network.domain_api}/alarm/companies/retailer`

/* View API */

/**
 * Get alarm retailer company data
 * 
 * @returns alarm retailer company data
 */
export const getAlarmRetailerCompany = async () => {
    let response
    try {
        response = await fetch(`${url_retailer_company}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Get alarm retailer company data by id
 * 
 * @param {*} param0 alarm retailer company id and data
 * @returns alarm retailer company data by id
 */
export const getAlarmRetailerCompanyID = async ({aid}) => {
    let response
    try {
        response = await fetch(`${url_retailer_company}/${aid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/* Controller API */

/**
 * Add alarm retailer company data
 * 
 * @param {*} data alarm retailer company data
 * @returns added alarm retailer company data
 */
export const addAlarmRetailerCompany = async (data) => {
    let response
    try {
        response = await fetch(`${url_retailer_company}/new`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update retailer alarm
 * 
 * @param {*} param0 retailer category id and data
 * @returns updated retailer alarm
 */
export const updateRetailerAlarm = async ({data}) => {
    let response
    try {
        response = await fetch(`${url_retailer_company}/update`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update alarm retailer company
 * 
 * @param {*} param0 alarm retailer id and data
 * @returns updated alarm retailer company
 */
export const updateAlarmRetailerCompany = async ({aid, cid, unique_id, data}) => {
    let response
    try {
        response = await fetch(`${url_retailer_company}/update/${aid}/${cid}/${unique_id}`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Delete alarm retailer company data
 * 
 * @param {*} param0 alarm retailer company id
 * @returns deleted alarm retailer company data
 */
export const deleteAlarmRetailerCompany = async ({aid, cid, unique_id}) => {
    let response
    try {
        response = await fetch(`${url_retailer_company}/delete/${aid}/${cid}/${unique_id}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}