import React, { useState, useEffect } from "react";
import {
    Box, 
    Button, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery, 
    useTheme, 
    Grid,
    Card,
    CardHeader,
    Divider,
    CardContent,
    TextField,
    InputLabel,
    Select,
    CardActions
} from "@mui/material";
import Header from "components/Header";

import { 
    AddOutlined, 
    DownloadOutlined,
    Inventory2Outlined,
    CloseOutlined,
    CheckOutlined,
    ArrowBackOutlined
} from "@mui/icons-material";

import PropTypes from "prop-types";
import { Container, styled } from "@mui/system";
import * as yup from "yup";

import FlexBetween from "modules/FlexBetween";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StatBox from "components/stats/StatBox";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Formik } from "formik";
import MapProducts from "modules/maps/products/MapProducts";
import { getData, storeData } from "state/stores/useAsyncStorage";
import { getProductsDistrict, addProduct, getProduct, deleteProduct, updateProduct } from "state/fetch/products/productApi";

const BootstrapDialog = styled(Dialog) (({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const ProductsDistrict = () => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    const path = window.location.pathname.split('/');

    // dialog box handle open add bottle packager
    const [openAddBottlePackager, setOpenAddBottlePackager] = useState(false);

    // dialog box handle open add packet packager
    const [openAddPacketPackager, setOpenAddPacketPackager] = useState(false);

    // dialog box handle open edit packager
    const [openEditPackager, setOpenEditPackager] = useState(false);

    // dialog box handle open packager detail
    const [openPackagerDetail, setOpenPackagerDetail] = useState(false);

    // dialog box handle open select company
    const [openSelectCompany, setOpenSelectCompany] = useState(false)

    // set status
    const [status, setStatus] = useState(100)
    const [statusMessage, setStatusMessage] = useState('Continue')
    const [message, setMessage] = useState('')

    // total bottle and packet brands
    const [totalBottleBrand, setTotalBottleBrand] = useState(0);
    const [totalPacketBrand, setTotalPacketBrand] = useState(0);

    // packet data
    const [bottles, setBottles] = useState([]);
    const [packets, setPackets] = useState([]);

    // region data
    const [region, setRegion] = useState({});

    // states and district data
    const [state, setState] = useState("")
    const [district, setDistrict] = useState("")

    // company
    const [companies, setCompanies] = useState([])

    const [inputProductName, setInputProductName] = useState('')
    const [inputCompanyID, setInputCompanyID] = useState('')
    const [inputCompanyName, setInputCompanyName] = useState('')
    const [inputProductBrandName, setInputProductBrandName] = useState('')
    const [inputProductSerialNo, setInputProductSerialNo] = useState('')
    const [inputProductSKU, setInputProductSKU] = useState('')
    const [inputProductCategory, setInputProductCategory] = useState('')
    const [inputProductPackage, setInputProductPackage] = useState('')

    // packager data
    const [packagerData, setPackagerData] = useState({});

    // product and brand data
    const [valueBrands, setValueBrands] = useState('1');
    const [valueProductPackager, setValueProductPackager] = useState('1');

    // handle open & close add bottle packager dialog box
    const handleOpenAddBottlePackager = () => {
        setOpenAddBottlePackager(true);

        clearStorage()
    }

    const handleCloseAddBottlePackager = () => {
        setOpenAddBottlePackager(false);
    }

    // handle open & close add packet packager dialog box
    const handleOpenAddPacketPackager = () => {
        setOpenAddPacketPackager(true);

        clearStorage()
    }

    const handleCloseAddPacketPackager = () => {
        setOpenAddPacketPackager(false);
    }

    // handle open & close edit packager dialog box
    const handleOpenEditPacakger = () => {
        setOpenEditPackager(true)

        clearStorage()
    }

    const handleCloseEditPackager = () => {
        setOpenEditPackager(false)
    }

    // handle open & close packager detail dialog box
    const handleOpenPacakgerDetail = () => {
        setOpenPackagerDetail(true)
    }

    const handleClosePacakgerDetail = () => {
        setOpenPackagerDetail(false)
    }

    // handle open & close select company dialog box
    const handleOpenSelectCompany = () => {
        setOpenSelectCompany(true)
    }

    const handleCloseSelectCompany = () => {
        setOpenSelectCompany(false)
    }

    const handleChangeProducts = (event, newValue) => {
        setValueBrands(newValue);
    }

    const handleChangeProductPackager = (event, newValue) => {
        setValueProductPackager(newValue)
    }

    const clearStorage = () => {
        storeData("product_name", "")
        storeData("unique_id", "")
        storeData("company_name", "")
        storeData("product_brand_name", "")
        storeData("product_serial_no", "")
        storeData("product_sku", "")
        storeData("product_package", "")
        
        setInputCompanyID('')
        setInputCompanyName('')
    }

    useEffect(() => {
        getProductsDistrict({
            sid: path[path.length - 2],
            did: path[path.length - 1]
        }).then((result) => {
            
            // initialize data
            setTotalBottleBrand(result.total_bottle_brand);
            setTotalPacketBrand(result.total_packet_brand);
            setBottles(result.bottles);
            setPackets(result.packets);

            // initialize data
            setRegion({
                center: result.center,
                zoom: result.zoom
            })

            setCompanies(result.companies);
            setState(result.state)
            setDistrict(result.district)

        })
        clearStorage()
    }, [])

    const emptyData = [];

    const columns = [
        {
            field: "pid",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 180,
        },
        {
            field: "packager_name",
            headerName: "Packager",
            headerAlign: "center",
            align: "center",
            width: 280,
        },
        {
            field: "product_name",
            headerName: "Product Name",
            headerAlign: "center",
            align: "center",
            width: 180,
        },
        {
            field: "product_brand_name",
            headerName: "Brand",
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            field: "product_category",
            headerName: "Category",
            headerAlign: "center",
            align: "center",
            width: 120,
        },
        {
            field: "product_package",
            headerName: "Package",
            headerAlign: "center",
            align: "center",
            width: 120
        },
        {
            field: "product_date_register",
            headerName: "Date Register",
            headerAlign: "center",
            align: "center",
            width: 120
        },
        {
            field: "product_time_register",
            headerName: "Time Register",
            headerAlign: "center",
            align: "center",
            width: 120
        }
    ];

    // column bottle concat
    const manageColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: '100%'
                            }}
                            onClick={() => {
                                getProduct({
                                    pid: params.row.pid,
                                    bid: params.row.bid,
                                    unique_id: params.row.unique_id
                                }).then((result) => {
                                    // initialize data
                                    setPackagerData(result)
                                })

                                // handle packager detail
                                handleOpenPacakgerDetail()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px",fontWeight: "bold" }}>
                                View
                            </Typography>
                        </Button>
                        <BootstrapDialog
                            aria-labelledby="view-dialog-title"
                            open={openPackagerDetail}
                        >
                            <BootstrapDialogTitle
                                id="view-dialog-title"
                                onClose={handleClosePacakgerDetail}
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.default,
                                }}
                            >
                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                    Packager Detail
                                </Typography>
                            </BootstrapDialogTitle>
                            <DialogContent
                                dividers
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.default,
                                }}
                            >
                                <Box
                                    component="main"
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <Container maxWidth="lg">
                                        <PackagerDetail data={packagerData}/>
                                    </Container>
                                </Box>
                            </DialogContent>
                        </BootstrapDialog>
                    </Box>
                );
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 120,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{ 
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: '100%'
                            }}
                            onClick={() => {
                                getProduct({
                                    pid: params.row.pid,
                                    bid: params.row.bid,
                                    unique_id: params.row.unique_id
                                }).then((result) => {
                                    // initialize data
                                    setPackagerData(result)
                                })

                                // handle edit packager
                                handleOpenEditPacakger()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px",fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                        <BootstrapDialog
                            aria-labelledby="view-dialog-title"
                            open={openEditPackager}
                        >
                            <BootstrapDialogTitle
                                id="view-dialog-title"
                                onClose={handleCloseEditPackager}
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.default,
                                }}
                            >
                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                    Edit Packager
                                </Typography>
                            </BootstrapDialogTitle>
                            <DialogContent
                                dividers
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.default,
                                }}
                            >
                                <Box
                                    component="main"
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <Container maxWidth="lg">
                                        <EditPackager data={packagerData}/>
                                    </Container>
                                </Box>
                            </DialogContent>
                        </BootstrapDialog>
                    </Box>
                );
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            headerAlign: "center",
            align: "center",
            width: 120,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        sx={{ 
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: '100%'
                        }}
                        onClick={() => {
                            deleteProduct({
                                product_category: params.row.product_category,
                                product_package: params.row.product_package,
                                pid: params.row.pid,
                                bid: params.row.bid,
                                unique_id: params.row.unique_id
                            })

                            // handle refresh page
                            handleRefreshPage()
                        }}
                    >
                        <Typography sx={{ fontSize: "12px",fontWeight: "bold" }}>
                            Delete
                        </Typography>
                    </Button>
                );
            }
        }
    ]

    // refresh page
    const handleRefreshPage = () => {
        window.location.reload(false);
    }

    const SelectCompany = () => {
        const companyColumn = [
            {
                field: "company_name",
                headerName: "Company Name",
                width: 300,
            },
        ]

        const columnActions = [
            {
                field: "actions",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                width: 120,
                renderCell: (params) => {
                    return (
                        <Box>
                            <Button
                                onClick={() => {
                                    setInputCompanyID(params.row.unique_id)
                                    setInputCompanyName(params.row.company_name)

                                    // close select company dialog box
                                    handleCloseSelectCompany()
                                }}
                                variant="contained"
                                color="info"
                                size="small"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "12px",
                                    width: '100%'
                                }}
                            >
                                <Typography sx={{ fontSize: "12px",fontWeight: "bold" }}>
                                    Select
                                </Typography>
                            </Button>
                        </Box>
                    )
                }
            }
        ]
        return (
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.alt,
                    borderRadius: "0.55rem",
                }}
            >
                <CardHeader
                    title="Select Packagers"
                    subheader="List of packager companies"
                />
                <Divider/>
                <CardContent sx={{ width: "800px"}}>
                    <Box
                        mt="10px"
                        height="60vh"
                        width="60vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                borderRadius: "5rem"
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: theme.palette.background.alt,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.alt,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: theme.palette.background.alt,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.secondary[200]} !important`,
                            },
                        }}
                    >
                        <DataGrid
                            getRowId={(row) => row.unique_id}
                            rows={companies?.length ? companies : emptyData}
                            columns={companyColumn.concat(columnActions)}
                            components={{ Toolbar: QuickSearchToolbar }}
                        />
                    </Box>
                </CardContent>
                <CardActions>
                    <Button
                            onClick={() => {
                                handleCloseSelectCompany();
                            }}
                            size="small"
                            variant="contained"
                            color="error"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "12px",
                                    width: "100%" 
                            }}
                        >
                            <CloseOutlined sx={{ mr: "10px" }}/>
                            Cancel
                        </Button>
                </CardActions>
            </Card>
        )
    }

    const AddBottlePackager = () => {

        /**
         * Bottle Size:
         * - 500g
         * - 1kg
         * - 2kg
         * - 3kg
         * - 5kg
         */
        const weights = [
            '500g',
            '1kg',
            '2kg',
            '3kg',
            '5kg'
        ]

        // Get stored data (to prevent form reinitialize)
        getData("product_name").then((result) => {setInputProductName(result)})
        getData("product_brand_name").then((result) => {setInputProductBrandName(result)})
        getData("product_serial_no").then((result) => {setInputProductSerialNo(result)})
        getData("product_sku").then((result) => {setInputProductSKU(result)})

        const initialBottlePackager = {
            product_name: inputProductName,
            unique_id: inputCompanyID,
            company_name: inputCompanyName,
            product_brand_name: inputProductBrandName,
            product_serial_no: inputProductSerialNo,
            product_sku: inputProductSKU,
            product_category: 'Bottle',
            product_package: '',
        }

        const checkoutSchema = yup.object().shape({
            product_name: yup.string().required("required"),
            unique_id: yup.string().required("required"),
            product_brand_name: yup.string().required("required"),
            product_serial_no: yup.string().required("required"),
            product_sku: yup.string().required("required"),
        })

        const handleSubmit = (values) => {
            // reconstruct json data
            let data = {
                pid: '',
                unique_id: values.unique_id,
                product_name: values.product_name,
                product_brand_name: values.product_brand_name,
                product_serial_no: values.product_serial_no,
                product_sku: values.product_sku,
                product_category: values.product_category,
                product_package: inputProductPackage,
                product_date_register: '',
                product_time_register: '',
                brand_date_register: '',
                brand_time_register: '',
            }
            console.log(data);

            // add new product data
            addProduct(data);

            // handle close add bottle brand
            handleCloseAddBottlePackager();

            // refresh page
            handleRefreshPage();
        }

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialBottlePackager}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem",
                            }}
                        >
                            <CardHeader
                                title="Register Bottle Packager"
                                subheader="Register Bottle Packager Details"
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="product_name"
                                            label="Product Name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_name", e.target.value)
                                                storeData("product_name", e.target.value)
                                            }}
                                            value={values.product_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_name && !!errors.product_name}
                                            helperText={touched.product_name && errors.product_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="product_brand_name"
                                            label="Product Brand Name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_brand_name", e.target.value)
                                                storeData("product_brand_name", e.target.value)
                                            }}
                                            value={values.product_brand_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_brand_name && !!errors.product_brand_name}
                                            helperText={touched.product_brand_name && errors.product_brand_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Serial Number"
                                            name="product_serial_no"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_serial_no", e.target.value)
                                                storeData("product_serial_no", e.target.value)
                                            }}
                                            value={values.product_serial_no}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_serial_no && !!errors.product_serial_no}
                                            helperText={touched.product_serial_no && errors.product_serial_no}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="SKU"
                                            name="product_sku"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_sku", e.target.value)
                                                storeData("product_sku", e.target.value)
                                            }}
                                            value={values.product_sku}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_sku && !!errors.product_sku}
                                            helperText={touched.product_sku && errors.product_sku}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company ID"
                                            name="unique_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={inputCompanyID}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.unique_id && !!errors.unique_id}
                                            helperText={touched.unique_id && errors.unique_id}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company Name"
                                            name="company_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={inputCompanyName}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_name && !!errors.company_name}
                                            helperText={touched.company_name && errors.company_name}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    onClick={() => {
                                        handleOpenSelectCompany()
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "100%",
                                        padding: "10px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <AddOutlined sx={{ mr: "10px" }}/>
                                    Select Company
                                </Button>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openSelectCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseSelectCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <SelectCompany/>
                                            </Container>
                                        </Box>
                                    </BootstrapDialogTitle>
                                </BootstrapDialog>
                            </CardActions>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Product Category"
                                            name="product_category"
                                            type="text"
                                            value={values.product_category}
                                            variant="outlined"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <InputLabel>Product Package</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={inputProductPackage}
                                            name="product_package"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setInputProductPackage(e.target.value)
                                            }}
                                            color="secondary"
                                            inputProps={{
                                                name: 'product_package',
                                                id: 'filled-roles-native'
                                            }}
                                            required
                                        >
                                            {weights.map((weight) => (
                                                <option key={weight} value={weight}>
                                                    {weight}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Box mt="10px" display="flex" flexDirection="row-reverse" gap={2}>
                                    <Button
                                        onClick={() => {
                                            handleCloseAddBottlePackager();

                                            // clear storage
                                            clearStorage()
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CloseOutlined sx={{ mr: "10px" }}/>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CheckOutlined sx={{ mr: "10px" }}/>
                                        Confirm
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    const AddPacketPackager = () => {

        /**
         * Packet Size:
         * - 1kg 
         */
        const weights = [
            '1kg'
        ]

        // Get stored data (to prevent form reinitialize)
        getData("product_name").then((result) => {setInputProductName(result)})
        getData("product_brand_name").then((result) => {setInputProductBrandName(result)})
        getData("product_serial_no").then((result) => {setInputProductSerialNo(result)})
        getData("product_sku").then((result) => {setInputProductSKU(result)})

        setInputProductPackage(weights[0])

        const initialPacketPackager = {
            product_name: inputProductName,
            unique_id: inputCompanyID,
            company_name: inputCompanyName,
            product_brand_name: inputProductBrandName,
            product_serial_no: inputProductSerialNo,
            product_sku: inputProductSKU,
            product_category: 'Packet',
            product_package: inputProductPackage,
        }

        const checkoutSchema = yup.object().shape({
            product_name: yup.string().required("required"),
            unique_id: yup.string().required("required"),
            product_brand_name: yup.string().required("required"),
            product_serial_no: yup.string().required("required"),
            product_sku: yup.string().required("required"),
        })

        const handleSubmit = (values) => {
            // reconstruct json data
            let data = {
                pid: '',
                unique_id: values.unique_id,
                product_name: values.product_name,
                product_brand_name: values.product_brand_name,
                product_serial_no: values.product_serial_no,
                product_sku: values.product_sku,
                product_category: values.product_category,
                product_package: inputProductPackage,
                product_date_register: '',
                product_time_register: '',
                brand_date_register: '',
                brand_time_register: '',
            }

            // add new product data
            addProduct(data);

            // handle close add packet packager
            handleCloseAddPacketPackager();

            // refresh page
            handleRefreshPage();
        }

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialPacketPackager}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem",
                            }}
                        >
                            <CardHeader
                                title="Register Packet Pacakger"
                                subheader="Register Packet Packager Details"
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="product_name"
                                            label="Product Name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_name", e.target.value)
                                                storeData("product_name", e.target.value)
                                            }}
                                            value={values.product_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_name && !!errors.product_name}
                                            helperText={touched.product_name && errors.product_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="product_brand_name"
                                            label="Product Brand Name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_brand_name", e.target.value)
                                                storeData("product_brand_name", e.target.value)
                                            }}
                                            value={values.product_brand_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_brand_name && !!errors.product_brand_name}
                                            helperText={touched.product_brand_name && errors.product_brand_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Serial Number"
                                            name="product_serial_no"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_serial_no", e.target.value)
                                                storeData("product_serial_no", e.target.value)
                                            }}
                                            value={values.product_serial_no}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_serial_no && !!errors.product_serial_no}
                                            helperText={touched.product_serial_no && errors.product_serial_no}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="SKU"
                                            name="product_sku"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_sku", e.target.value)
                                                storeData("product_sku", e.target.value)
                                            }}
                                            value={values.product_sku}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_sku && !!errors.product_sku}
                                            helperText={touched.product_sku && errors.product_sku}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company ID"
                                            name="unique_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={inputCompanyID}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.unique_id && !!errors.unique_id}
                                            helperText={touched.unique_id && errors.unique_id}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company Name"
                                            name="company_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={inputCompanyName}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_name && !!errors.company_name}
                                            helperText={touched.company_name && errors.company_name}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    onClick={() => {
                                        handleOpenSelectCompany()
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "100%",
                                        padding: "10px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <AddOutlined sx={{ mr: "10px" }}/>
                                    Select Company
                                </Button>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openSelectCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseSelectCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <SelectCompany/>
                                            </Container>
                                        </Box>
                                    </BootstrapDialogTitle>
                                </BootstrapDialog>
                            </CardActions>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Product Category"
                                            name="product_category"
                                            type="text"
                                            value={values.product_category}
                                            variant="outlined"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <InputLabel>Product Package</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={inputProductPackage}
                                            name="product_package"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                console.log(e.target.value)
                                                setInputProductPackage(e.target.value)
                                            }}
                                            color="secondary"
                                            inputProps={{
                                                name: 'product_package',
                                                id: 'filled-roles-native'
                                            }}
                                            required
                                        >
                                            {weights.map((weight) => (
                                                <option key={weight} value={weight}>
                                                    {weight}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Box mt="10px" display="flex" flexDirection="row-reverse" gap={2}>
                                    <Button
                                        onClick={() => {
                                            handleCloseAddPacketPackager();

                                            // clear storage
                                            clearStorage();
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CloseOutlined sx={{ mr: "10px" }}/>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CheckOutlined sx={{ mr: "10px" }}/>
                                        Confirm
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    const EditPackager = ({data}) => {

        let weights = []
        switch(data.product_category) {
            case 'Bottle':
                /**
                 * Bottle Size:
                 * - 500g
                 * - 1kg
                 * - 2kg
                 * - 3kg
                 * - 5kg
                 */
                weights = [
                    '500g',
                    '1kg',
                    '2kg',
                    '3kg',
                    '5kg'
                ]

                setInputProductCategory('Bottle')
                break;
            case 'Packet':
                weights = [
                    '1kg'
                ]

                setInputProductCategory('Packet')
                break;
        }

        setInputCompanyID(data.unique_id)
        setInputCompanyName(data.packager_name)
        setInputProductPackage(data.product_package)

        // Set stored data
        storeData("product_name", data.product_name)
        storeData("product_brand_name", data.product_brand_name)
        storeData("product_serial_no", data.product_serial_no)
        storeData("product_sku", data.product_sku)

        // Get stored data (to prevent form reinitialize)
        getData("product_name").then((result) => {setInputProductName(result)})
        getData("product_brand_name").then((result) => {setInputProductBrandName(result)})
        getData("product_serial_no").then((result) => {setInputProductSerialNo(result)})
        getData("product_sku").then((result) => {setInputProductSKU(result)})

        const initialPackager = {
            pid: data.pid,
            bid: data.bid,
            product_name: inputProductName,
            unique_id: inputCompanyID,
            company_name: inputCompanyName,
            product_brand_name: inputProductBrandName,
            product_serial_no: inputProductSerialNo,
            product_sku: inputProductSKU,
            product_category: inputProductCategory,
            product_package: data.product_package,
        }

        const checkoutSchema = yup.object().shape({
            product_name: yup.string().required("required"),
            unique_id: yup.string().required("required"),
            product_brand_name: yup.string().required("required"),
            product_serial_no: yup.string().required("required"),
            product_sku: yup.string().required("required"),
        })

        const handleSubmit = (values) => {
            // reconstruct json data
            let result = {
                unique_id: inputCompanyID,
                product_name: values.product_name,
                product_brand_name: values.product_brand_name,
                product_serial_no: values.product_serial_no,
                product_sku: values.product_sku,
                product_category: values.product_category,
                product_package: values.product_package,
                product_date_register: data.product_date_register,
                product_time_register: data.product_time_register,
                brand_date_register: data.product_date_register,
                brand_time_register: data.product_time_register
            }

            // update product 
            updateProduct({
                pid: data.pid,
                bid: data.bid,
                data: result}).then((result) => {
                    setStatus(result.status_code)
                    setStatusMessage(result.status_message)
                    setMessage(result.message)
                });

            // handle close update product
            handleCloseEditPackager()

            // refresh page
            handleRefreshPage()
        }

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialPackager}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title="Update Packager"
                                subheader={data.bid}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="pid"
                                            label="Product ID"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.pid}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.pid && !!errors.pid}
                                            helperText={touched.pid && errors.pid}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="product_name"
                                            label="Product Name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_name", e.target.value)
                                                storeData("product_name", e.target.value)
                                            }}
                                            value={values.product_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_name && !!errors.product_name}
                                            helperText={touched.product_name && errors.product_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="product_brand_name"
                                            label="Product Brand Name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_brand_name", e.target.value)
                                                storeData("product_brand_name", e.target.value)
                                            }}
                                            value={values.product_brand_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_brand_name && !!errors.product_brand_name}
                                            helperText={touched.product_brand_name && errors.product_brand_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Serial Number"
                                            name="product_serial_no"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_serial_no", e.target.value)
                                                storeData("product_serial_no", e.target.value)
                                            }}
                                            value={values.product_serial_no}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_serial_no && !!errors.product_serial_no}
                                            helperText={touched.product_serial_no && errors.product_serial_no}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="SKU"
                                            name="product_sku"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("product_sku", e.target.value)
                                                storeData("product_sku", e.target.value)
                                            }}
                                            value={values.product_sku}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_sku && !!errors.product_sku}
                                            helperText={touched.product_sku && errors.product_sku}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company ID"
                                            name="unique_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={inputCompanyID}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.unique_id && !!errors.unique_id}
                                            helperText={touched.unique_id && errors.unique_id}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company Name"
                                            name="company_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={inputCompanyName}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_name && !!errors.company_name}
                                            helperText={touched.company_name && errors.company_name}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    onClick={() => {
                                        handleOpenSelectCompany()
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "100%",
                                        padding: "10px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <AddOutlined sx={{ mr: "10px" }}/>
                                    Select Company
                                </Button>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openSelectCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseSelectCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <SelectCompany/>
                                            </Container>
                                        </Box>
                                    </BootstrapDialogTitle>
                                </BootstrapDialog>
                            </CardActions>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Product Category"
                                            name="product_category"
                                            type="text"
                                            value={values.product_category}
                                            variant="outlined"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <InputLabel>Product Package</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={values.product_package}
                                            name="product_package"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            color="secondary"
                                            inputProps={{
                                                name: 'product_package',
                                                id: 'filled-roles-native'
                                            }}
                                            required
                                        >
                                            {weights.map((weight) => (
                                                <option key={weight} value={weight}>
                                                    {weight}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Box mt="10px" display="flex" flexDirection="row-reverse" gap={2}>
                                    <Button
                                        onClick={() => {
                                            handleCloseEditPackager();

                                            // clear storage
                                            clearStorage()
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CloseOutlined sx={{ mr: "10px" }}/>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CheckOutlined sx={{ mr: "10px" }}/>
                                        Confirm
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    const PackagerDetail = ({data}) => {

        const initialPackager = {
            pid: data.pid,
            bid: data.bid,
            unique_id: data.unique_id,
            product_name: data.product_name,
            product_brand_name: data.product_brand_name,
            product_serial_no: data.product_serial_no,
            product_sku: data.product_sku,
            product_category: data.product_category,
            product_package: data.product_package,
            product_date_register: data.product_date_register,
            product_time_register: data.product_time_register,
            packager_name: data.packager_name,
            packager_category: data.mannufacture_cateogry,
            company_ssm: data.company_ssm,
            company_license: data.company_license,
            company_start_csa_license: data.company_start_csa_license,
            company_end_csa_license: data.company_end_csa_license,
            state_name: data.state_name,
            district_name: data.district_name,
            packager_latitude: data.packager_latitude,
            packager_longitude: data.packager_longitude
        }

        return (
            <Formik
                initialValues={initialPackager}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange
                }) => (
                    <form>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title={data.product_name}
                                subheader={data.bid}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="pid"
                                            label="Product ID"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.pid}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.pid && !!errors.pid}
                                            helperText={touched.pid && errors.pid}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="product_brand_name"
                                            label="Product Brand Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_brand_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_brand_name && !!errors.product_brand_name}
                                            helperText={touched.product_brand_name && errors.product_brand_name}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Serial Number"
                                            name="product_serial_no"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_serial_no}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_serial_no && !!errors.product_serial_no}
                                            helperText={touched.product_serial_no && errors.product_serial_no}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="SKU"
                                            name="product_sku"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_sku}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_sku && !!errors.product_sku}
                                            helperText={touched.product_sku && errors.product_sku}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Product Category"
                                            name="product_category"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_category}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_serial_no && !!errors.product_serial_no}
                                            helperText={touched.product_serial_no && errors.product_serial_no}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Product Package"
                                            name="product_package"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_package}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_package && !!errors.product_package}
                                            helperText={touched.product_package && errors.product_package}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Product Date Register"
                                            name="product_date_register"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_date_register}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_date_register && !!errors.product_date_register}
                                            helperText={touched.product_date_register && errors.product_date_register}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Product Date Time"
                                            name="product_time_register"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.product_time_register}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.product_time_register && !!errors.product_time_register}
                                            helperText={touched.product_time_register && errors.product_time_register}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company ID"
                                            name="unique_id"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.unique_id}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.unique_id && !!errors.unique_id}
                                            helperText={touched.unique_id && errors.unique_id}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="packager Name"
                                            name="packager_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.packager_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.packager_name && !!errors.packager_name}
                                            helperText={touched.packager_name && errors.packager_name}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company SSM"
                                            name="company_ssm"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_ssm}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_ssm && !!errors.company_ssm}
                                            helperText={touched.company_ssm && errors.company_ssm}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company License"
                                            name="company_license"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_license && !!errors.company_license}
                                            helperText={touched.company_license && errors.company_license}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="State"
                                            name="state_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.state_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.state_name && !!errors.state_name}
                                            helperText={touched.state_name && errors.state_name}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="District"
                                            name="district_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.district_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.district_name && !!errors.district_name}
                                            helperText={touched.district_name && errors.district_name}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company Start CSA License"
                                            name="company_start_csa_license"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_start_csa_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_start_csa_license && !!errors.company_start_csa_license}
                                            helperText={touched.company_start_csa_license && errors.company_start_csa_license}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company End CSA License"
                                            name="company_end_csa_license"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_end_csa_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_end_csa_license && !!errors.company_end_csa_license}
                                            helperText={touched.company_end_csa_license && errors.company_end_csa_license}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="packager Latitude"
                                            name="packager_latitude"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.packager_latitude}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.packager_latitude && !!errors.packager_latitude}
                                            helperText={touched.packager_latitude && errors.packager_latitude}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="packager Longitude"
                                            name="packager_longitude"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.packager_longitude}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.packager_longitude && !!errors.packager_longitude}
                                            helperText={touched.packager_longitude && errors.packager_longitude}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardActions>
                                <Button
                                    onClick={() => {
                                        navigate(`/companies/${values.unique_id}`)
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "100%",
                                        padding: "10px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    View Company Details
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleClosePacakgerDetail()
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "100%",
                                        padding: "10px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    Close Details
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Box display="flex" justifyContent="space-between">
                    <IconButton
                        onClick={() => { navigate(-1) }}
                        color="info"
                        sx={{
                            color: theme.palette.secondary.light,
                            marginRight: '10px'
                        }}
                    >
                        <ArrowBackOutlined/>
                    </IconButton>
                    <Header title="Product Dashboard" subtitle={`List of Cooking Oils Product in ${state}, ${district}`}/>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="info"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        <DownloadOutlined sx={{ mr: "10px" }}/>
                        Download Reports                      
                    </Button>
                </Box>
            </FlexBetween>
            <Box
                mt="20px"
                mb="10px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <StatBox
                    title="Total Bottle Packager"
                    value={totalBottleBrand}
                    icon={<Inventory2Outlined/>}
                    description="Registered Packager"
                />
                <StatBox
                    title="Total Packet Packager"
                    value={totalPacketBrand}
                    icon={<Inventory2Outlined/>}
                    description="Registered Packager"
                />
            </Box>
            <Box
                mt="20px"
                gridColumn="span 12"
                gridRow="span 3"
            >
                <TabContext value={valueProductPackager}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                        <TabList
                            variant="fullWidth"
                            onChange={handleChangeProductPackager}
                            textColor="inherit"
                            indicatorColor="secondary"
                        >
                            <Tab iconPosition="start" label="Bottle" value="1"/>
                            <Tab iconPosition="start" label="Packet" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box height="100%" width="100%">
                            <MapProducts items={bottles} position={region}/>
                        </Box>
                        <Box mt="40px" display="flex" flexDirection="row-reverse">
                            <Button
                                onClick={() => {
                                    handleOpenAddBottlePackager()
                                }}
                                size="small"
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <AddOutlined sx={{ mr: "10px" }}/>
                                Add Bottle Packager
                            </Button>
                            <BootstrapDialog
                                aria-labelledby="view-dialog-title"
                                open={openAddBottlePackager}
                            >
                                <BootstrapDialogTitle
                                    id="view-dialog-title"
                                    onClose={handleCloseAddBottlePackager}
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.default,
                                    }}
                                >
                                    <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                        Add Bottle Packager
                                    </Typography>
                                </BootstrapDialogTitle>
                                <DialogContent
                                    dividers
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.default
                                    }}
                                >
                                    <Box
                                        component="main"
                                        sx={{
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Container maxWidth="lg">
                                            <AddBottlePackager/>
                                        </Container>
                                    </Box>
                                </DialogContent>
                            </BootstrapDialog>
                        </Box>
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.pid}
                                rows={bottles?.length ? bottles : emptyData}
                                columns={columns.concat(manageColumn)}
                                components={{ Toolbar: QuickSearchToolbar }}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box height="100%" width="100%">
                            <MapProducts items={packets} position={region}/>
                        </Box>
                        <Box mt="40px" display="flex" flexDirection="row-reverse">
                            <Button
                                onClick={() => {
                                    handleOpenAddPacketPackager()
                                }}
                                variant="contained"
                                color="info"
                                size="small"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <AddOutlined sx={{ mr: "10px" }}/>
                                Add Packet Packager
                            </Button>
                            <BootstrapDialog
                                aria-labelledby="view-dialog-title"
                                open={openAddBottlePackager}
                            >
                                <BootstrapDialogTitle
                                    id="view-dialog-title"
                                    onClose={handleCloseAddPacketPackager}
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.default,
                                    }}
                                >
                                    <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                        Add Packet Packager
                                    </Typography>
                                </BootstrapDialogTitle>
                                <DialogContent
                                    dividers
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.default,
                                    }}
                                >
                                    <Box
                                        component="main"
                                        sx={{
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Container maxWidth="lg">
                                            <AddPacketPackager/>
                                        </Container>
                                    </Box>
                                </DialogContent>
                            </BootstrapDialog>
                        </Box>
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.pid}
                                rows={packets?.length ? packets : emptyData}
                                columns={columns.concat(manageColumn)}
                                components={{ Toolbar: QuickSearchToolbar }}
                            />
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    );
}

export default ProductsDistrict;