
import { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import {
    Box,
    Button,
    DialogTitle,
    IconButton,
    Tab,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"

import PropTypes from "prop-types"
import { useNavigate } from "react-router";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import SearchBar from "components/SearchBar";
import { CalendarMonthOutlined, CalendarTodayOutlined, CloseOutlined, DoneOutlined, DownloadOutlined, Inventory2Outlined, LocalShippingOutlined, MonetizationOnOutlined, PendingOutlined, ReportProblemOutlined, RequestPageOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StatProgressBox from "components/stats/StatProgressBox";
import PurchaseOrderRawItem from "modules/items/orders/PurchaseOrderRawItem";
import PurchaseOrderItem from "modules/items/orders/PurchaseOrderItem";
import ReceiveOrderRawItem from "modules/items/orders/ReceiveOrderRawItem";
import ReceiveOrderItem from "modules/items/orders/ReceiveOrderItem";
import { percentage, progressRate } from "utils/maths";
import SubHeader from "components/SubHeader";
import BarChartOrdersDaily from "components/barcharts/orders/BarChartOrdersDaily";
import BarChartOrdersMonthly from "components/barcharts/orders/BarChartOrdersMonthly";
import BarChartOrdersYearly from "components/barcharts/orders/BarChartOrdersYearly";
import { generateDummyRawPO } from "documents/dummyDocuments";
import { toDataURL } from "utils/encoders";

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                />
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const UserOrders = ({ isDashboard = false, isProducer = false, isPackager = false }) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min_width: 1000px)")

    // tabbing handle change
    const [value, setValue] = useState('1')
    const [valuePurchaseBarChart, setValuePurchaseBarChart] = useState('1')
    const [valueReceiveBarChart, setValueReceiveBarChart] = useState('1')

    const navigate = useNavigate();

    // handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handlePurchaseBarChart = (event, newValue) => {
        setValuePurchaseBarChart(newValue);
    }

    const handleReceiveBarChart = (event, newValue) => {
        setValueReceiveBarChart(newValue)
    }

    const [totalIssue, setTotalIssue] = useState(0)
    const [totalPurchaseOrder, setTotalPurchaseOrder] = useState(0)
    const [totalReceiveOrder, setTotalReceiveOrder] = useState(0);

    const [totalPurchasePendingOrder, setTotalPurchasePendingOrder] = useState(0)
    const [totalPurchaseShippedOrder, setTotalPurchaseShippedOrder] = useState(0)
    const [totalPurchaseDeliveredOrder, setTotalPurchaseDeliveredOrder] = useState(0)
    const [totalPurchasePaidOrder, setTotalPurchasePaidOrder] = useState(0)
    const [totalPurchaseRejectOrder, setTotalPurchaseRejectOrder] = useState(0)

    const [totalPurchaseDailyOrder, setTotalPurchaseDailyOrder] = useState(0)
    const [totalPurchaseDailyPendingOrder, setTotalPurchaseDailyPendingOrder] = useState(0)
    const [totalPurchaseDailyShippedOrder, setTotalPurchaseDailyShippedOrder] = useState(0)
    const [totalPurchaseDailyDeliveredOrder, setTotalPurchaseDailyDeliveredOrder] = useState(0)
    const [totalPurchaseDailyPaidOrder, setTotalPurchaseDailyPaidOrder] = useState(0)
    const [totalPurchaseDailyRejectOrder, setTotalPurchaseDailyRejectOrder] = useState(0)

    const [totalPurchaseMonthlyOrder, setTotalPurchaseMonthlyOrder] = useState(0)
    const [totalPurchaseMonthlyPendingOrder, setTotalPurchaseMonthlyPendingOrder] = useState(0)
    const [totalPurchaseMonthlyShippedOrder, setTotalPurchaseMonthlyShippedOrder] = useState(0)
    const [totalPurchaseMonthlyDeliveredOrder, setTotalPurchaseMonthlyDeliveredOrder] = useState(0)
    const [totalPurchaseMonthlyPaidOrder, setTotalPurchaseMonthlyPaidOrder] = useState(0)
    const [totalPurchaseMonthlyRejectOrder, setTotalPurchaseMonthlyRejectOrder] = useState(0)

    const [totalPurchaseYearlyOrder, setTotalPurchaseYearlyOrder] = useState(0)
    const [totalPurchaseYearlyPendingOrder, setTotalPurchaseYearlyPendingOrder] = useState(0)
    const [totalPurchaseYearlyShippedOrder, setTotalPurchaseYearlyShippedOrder] = useState(0)
    const [totalPurchaseYearlyDeliveredOrder, setTotalPurchaseYearlyDeliveredOrder] = useState(0)
    const [totalPurchaseYearlyPaidOrder, setTotalPurchaseYearlyPaidOrder] = useState(0)
    const [totalPurchaseYearlyRejectOrder, setTotalPurchaseYearlyRejectOrder] = useState(0)

    const [totalReceivePendingOrder, setTotalReceivePendingOrder] = useState(0)
    const [totalReceiveShippedOrder, setTotalReceiveShippedOrder] = useState(0)
    const [totalReceiveDeliveredOrder, setTotalReceiveDeliveredOrder] = useState(0)
    const [totalReceivePaidOrder, setTotalReceivePaidOrder] = useState(0)
    const [totalReceiveRejectOrder, setTotalReceiveRejectOrder] = useState(0)

    const [totalReceiveDailyOrder, setTotalReceiveDailyOrder] = useState(0)
    const [totalReceiveDailyPendingOrder, setTotalReceiveDailyPendingOrder] = useState(0)
    const [totalReceiveDailyShippedOrder, setTotalDailyShippedOrder] = useState(0)
    const [totalReceiveDailyDeliveredOrder, setTotalReceiveDailyDeliveredOrder] = useState(0)
    const [totalReceiveDailyPaidOrder, setTotalReceiveDailyPaidOrder] = useState(0)
    const [totalReceiveDailyRejectOrder, setTotalReceiveDailyRejectOrder] = useState(0)

    const [totalReceiveMonthlyOrder, setTotalReceiveMonthlyOrder] = useState(0)
    const [totalReceiveMonthlyPendingOrder, setTotalReceiveMonthlyPendingOrder] = useState(0)
    const [totalReceiveMonthlyShippedOrder, setTotalReceiveMonthlyShippedOrder] = useState(0)
    const [totalReceiveMonthlyDeliveredOrder, setTotalReceiveMonthlyDeliveredOrder] = useState(0)
    const [totalReceiveMonthlyPaidOrder, setTotalReceiveMonthlyPaidOrder] = useState(0)
    const [totalReceiveMonthlyRejectOrder, setTotalReceiveMonthlyRejectOrder] = useState(0)

    const [totalReceiveYearlyOrder, setTotalReceiveYearlyOrder] = useState(0)
    const [totalReceiveYearlyPendingOrder, setTotalReceiveYearlyPendingOrder] = useState(0)
    const [totalReceiveYearlyShippedOrder, setTotalReceiveYearlyShippedOrder] = useState(0)
    const [totalReceiveYearlyDeliveredOrder, setTotalReceiveYearlyDeliveredOrder] = useState(0)
    const [totalReceiveYearlyPaidOrder, setTotalReceiveYearlyPaidOrder] = useState(0)
    const [totalReceiveYearlyRejectOrder, setTotalReceiveYearlyRejectOrder] = useState(0)

    const [saleRawStockOrder, setSaleRawStockOrder] = useState([])
    const [saleRawStockOrderFiltered, setSaleRawStockOrderFiltered] = useState([])
    const [saleStockOrder, setSaleStockOrder] = useState([])
    const [saleStockOrderFiltered, setSaleStockOrderFiltered] = useState([])

    const [orderRawStockOrder, setOrderRawStockOrder] = useState([])
    const [orderRawStockOrderFiltered, setOrderRawStockOrderFiltered] = useState([])
    const [orderStockOrder, setRawStockOrder] = useState([])
    const [orderStockOrderFiltered, setOrderStockOrderFiltered] = useState([])

    const [purchaseOrder, setPurchaseOrder] = useState([])
    const [purchaseDailyOrder, setPurchaseDailyOrder] = useState([])
    const [purchaseMonthlyOrder, setPurhaseMonthlyOrder] = useState([])
    const [purchaseYearlyOrder, setPurchaseYearlyOrder] = useState([])

    const [receiveOrder, setReceiveOrder] = useState([])
    const [receiveDailyOrder, setReceiveDailyOrder] = useState([])
    const [receiveMonthlyOrder, setReceiveMonthlyOrder] = useState([])
    const [receiveYearlyOrder, setReceiveYearlyOrder] = useState([])

    const [searchSaleItem, setSearchSaleItem] = useState("")
    const [searchOrderItem, setSearchOrderItem] = useState("")

    useEffect(() => {
        
    }, [])

     /**
     * ==============================
     *  Dummy purpose only
     * ==============================
     */
     const [base64QRData, setBase64QRData] = useState('')
     const qrURL = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example`
 
     useEffect(() => {
         // Generate and covert QR code into base64 image
         toDataURL(qrURL).then(dataUrl => {
             setBase64QRData(dataUrl)
         })
     }, [])
     /**
      * ==============================
      *  Dummy purpose only
      * ==============================
      */

    const emptyData = [];

    const purchaseOrderColumn = () => {
        let columns = []

        if(isPackager) {
            columns = [
                {
                    field: "stock_order_id",
                    headerName: "ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "qr_id",
                    headerName: "QRID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "wid",
                    headerName: "Warehouse ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "provider_company_name",
                    headerName: "Seller Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "item_name",
                    headerName: "Item Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_order",
                    headerName: "Total Order (MT)",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_price",
                    headerName: "Total Price",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "payment_status",
                    headerName: "Payment Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    renderCell: (params) => {
                        switch(params.row.payment_status) {
                            case "Paid":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "green",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            case "Cancel":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "red",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            default:
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "gray",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                        }
                    }
                },
                {
                    field: "status_order",
                    headerName: "Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    redenrCell: (params) => {
                        switch(params.row.status_order) {
                            case "Shipped":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "yellow",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.status_order}
                                    </Typography>
                                )
                                case "Delivered":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "green",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                case "Reject":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "red",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                default:
                                    return (
                                        <Typography
                                        sx={{
                                            backgroundColor: "grey",
                                            fontWeight: "bold",
            
                                        }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                        }
                    }
                },
                {
                    field: "remark",
                    headerName: "Remark",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "date_order",
                    headerName: "Date Order",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                },
                {
                    field: "time_order",
                    headerName: "Time Order",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                }
            ]
        } else {
            columns = [
                {
                    field: "stock_order_id",
                    headerName: "ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "qr_id",
                    headerName: "QRID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "provider_company_name",
                    headerName: "Seller Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_name",
                    headerName: "Product Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_brand_name",
                    headerName: "Product Brand Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_serial_no",
                    headerName: "Product Serial No",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_sku",
                    headerName: "Product SKU",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_category",
                    headerName: "Product Category",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_package",
                    headerName: "Product Package",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_order",
                    headerName: "Total Order",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_price",
                    headerName: "Total Price",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "payment_status",
                    headerName: "Payment Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    renderCell: (params) => {
                        switch(params.row.payment_status) {
                            case "Paid":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "green",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            case "Cancel":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "red",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            default:
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "gray",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                        }
                    }
                },
                {
                    field: "status_order",
                    headerName: "Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    redenrCell: (params) => {
                        switch(params.row.status_order) {
                            case "Shipped":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "yellow",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.status_order}
                                    </Typography>
                                )
                                case "Delivered":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "green",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                case "Reject":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "red",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                default:
                                    return (
                                        <Typography
                                        sx={{
                                            backgroundColor: "grey",
                                            fontWeight: "bold",
            
                                        }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                        }
                    }
                },
                {
                    field: "remark",
                    headerName: "Remark",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "date_order",
                    headerName: "Date Order",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                },
                {
                    field: "time_order",
                    headerName: "Time Order",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                }
            ]
        }
        return columns
    }

    const receiveOrderColumn = () => {
        let columns = []
        if(isProducer) {
            columns = [
                {
                    field: "stock_order_id",
                    headerName: "ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "qr_id",
                    headerName: "QRID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "wid",
                    headerName: "Warehouse ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "recipient_company_name",
                    headerName: "Requester Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "item_name",
                    headerName: "Item Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_order",
                    headerName: "Total Order (MT)",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_price",
                    headerName: "Total Price",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "payment_status",
                    headerName: "Payment Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    renderCell: (params) => {
                        switch(params.row.payment_status) {
                            case "Paid":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "green",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            case "Cancel":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "red",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            default:
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "gray",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                        }
                    }
                },
                {
                    field: "status_order",
                    headerName: "Status Order",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    renderCell: (params) => {
                        switch(params.row.status_order) {
                            case "Shipped":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "yellow",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.status_order}
                                    </Typography>
                                )
                                case "Delivered":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "green",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                case "Reject":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "red",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                default:
                                    return (
                                        <Typography
                                        sx={{
                                            backgroundColor: "grey",
                                            fontWeight: "bold",
            
                                        }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                        }
                    }
                },
                {
                    field: "remark",
                    headerName: "Remark",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "date_order",
                    headerName: "Date Order",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "time_order",
                    headerName: "Time Order",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                }
            ]
        } else {
            columns = [
                {
                    field: "stock_order_id",
                    headerName: "ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "qr_id",
                    headerName: "QRID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "recipient_company_name",
                    headerName: "Requester Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_name",
                    headerName: "Product Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_brand_name",
                    headerName: "Product Brand Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_serial_no",
                    headerName: "Product Serial No",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_sku",
                    headerName: "Product SKU",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_category",
                    headerName: "Product Category",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_package",
                    headerName: "Product Package",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_order",
                    headerName: "Total Order (MT)",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_price",
                    headerName: "Total Price",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "payment_status",
                    headerName: "Payment Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    renderCell: (params) => {
                        switch(params.row.payment_status) {
                            case "Paid":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "green",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            case "Cancel":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "red",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                            default:
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "gray",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.payment_status}
                                    </Typography>
                                )
                        }
                    }
                },
                {
                    field: "status_order",
                    headerName: "Status Order",
                    headerAlign: "center",
                    align: "center",
                    width: 180,
                    renderCell: (params) => {
                        switch(params.row.status_order) {
                            case "Shipped":
                                return (
                                    <Typography
                                        sx={{
                                            backgroundColor: "yellow",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {params.row.status_order}
                                    </Typography>
                                )
                                case "Delivered":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "green",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                case "Reject":
                                    return (
                                        <Typography
                                            sx={{
                                                backgroundColor: "red",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                                default:
                                    return (
                                        <Typography
                                        sx={{
                                            backgroundColor: "grey",
                                            fontWeight: "bold",
            
                                        }}
                                        >
                                            {params.row.status_order}
                                        </Typography>
                                    )
                        }
                    }
                },
                {
                    field: "remark",
                    headerName: "Remark",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "date_order",
                    headerName: "Date Order",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "time_order",
                    headerName: "Time Order",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                }
            ]
        }
        return columns
    }

    const manageColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: "100%"
                        }}
                        onClick={() => {
                            
                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        }
    ];

    const handleSearchSaleItem = () => {
        if(isPackager) {
            if(searchSaleItem) {
                const newItem = saleRawStockOrder.filter(item => {
                    const itemData = item.item_name ? item.item_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchSaleItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setSaleRawStockOrderFiltered(newItem);
            } else {
                console.log(searchSaleItem);
                setSaleRawStockOrderFiltered(saleRawStockOrder)
            }
        } else {
            if(searchSaleItem) {
                const newItem = saleStockOrder.filter(item => {
                    const itemData = item.product_name ? item.product_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchSaleItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setSaleStockOrderFiltered(newItem);
            } else {
                setSaleStockOrderFiltered(saleStockOrder)
            }
        }
    }

    const handleSearchOrderItem = () => {
        if(isProducer) {
            if(searchOrderItem) {
                const newItem = orderRawStockOrder.filter(item => {
                    const itemData = item.item_name ? item.item_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchOrderItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setOrderRawStockOrderFiltered(newItem)
            } else {
                setOrderStockOrderFiltered(orderRawStockOrder)
            }
        } else {
            if(searchOrderItem) {
                const newItem = orderStockOrder.filter(item => {
                    const itemData = item.product_name ? item.product_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchOrderItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setOrderStockOrderFiltered(newItem);
            } else {
                setOrderStockOrderFiltered(orderStockOrder)
            }
        }
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Order Report" subtitle="Order Report Activity"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                onClick={() => {
                                    generateDummyRawPO({base64QR: base64QRData})
                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <StatBox
                    title="Total Issue"
                    value={totalIssue}
                    description="Issue(s)"
                    icon={
                        <ReportProblemOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Purchase Order"
                    value={totalPurchaseOrder}
                    description="Purchase Order"
                    icon={
                        <RequestPageOutlined
                            sx={{color: theme.palette.secondary[300], fontSize: "26px"}}
                        />
                    }
                />
                <StatBox
                    title="Total Receive Order"
                    value={totalReceiveOrder}
                    description="Receive Order"
                    icon={
                        <RequestPageOutlined
                        sx={{color: theme.palette.secondary[300], fontSize: "26px"}}
                        />
                    }
                />
            </Box>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            {
                                isProducer ? (
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<RequestPageOutlined/>} iconPosition="start" label="Receive Order" value="2"/>
                                    </TabList>
                                ) : (
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<MonetizationOnOutlined/>} iconPosition="start" label="Purchase Order" value="1"/>
                                        <Tab icon={<RequestPageOutlined/>} iconPosition="start" label="Receive Order" value="2"/>
                                    </TabList>
                                )
                            }
                        </Box>
                        <TabPanel value="1">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatProgressBox
                                    title="Total Pending Order"
                                    rate={`${percentage(totalPurchasePendingOrder, totalPurchaseOrder)}%`}
                                    value={totalPurchasePendingOrder}
                                    progress={progressRate(totalPurchasePendingOrder, totalPurchaseOrder)}
                                    description="Pending Order"
                                    icon={
                                        <PendingOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Shipped Order"
                                    rate={`${percentage(totalPurchaseShippedOrder, totalPurchaseOrder)}%`}
                                    value={totalPurchaseShippedOrder}
                                    progress={progressRate(totalPurchaseShippedOrder, totalPurchaseOrder)}
                                    description="Shipped Order"
                                    icon={
                                        <LocalShippingOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Delivered Order"
                                    rate={`${percentage(totalPurchaseDeliveredOrder, totalPurchaseOrder)}%`}
                                    value={totalPurchaseDeliveredOrder}
                                    progress={progressRate(totalPurchaseDeliveredOrder, totalPurchaseOrder)}
                                    description="Delivered Order"
                                    icon={
                                        <Inventory2Outlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Paid Order"
                                    rate={`${percentage(totalPurchasePaidOrder, totalPurchaseOrder)}%`}
                                    value={totalPurchasePaidOrder}
                                    progress={progressRate(totalPurchasePaidOrder, totalPurchaseOrder)}
                                    description="Paid Order"
                                    icon={
                                        <DoneOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Reject Order"
                                    rate={`${percentage(totalPurchaseRejectOrder, totalPurchaseOrder)}%`}
                                    value={totalPurchaseRejectOrder}
                                    progress={progressRate(totalPurchaseRejectOrder, totalPurchaseOrder)}
                                    description="Reject Order"
                                    icon={
                                        <CloseOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                            </Box>
                            <Box mt="20px">
                                <SubHeader title="Marketplace"/>
                            </Box>
                            <Box mt="20px">
                                <FlexBetween>
                                    <SearchBar
                                        placeholder="Search Item"
                                        value={searchSaleItem}
                                        onChange={newValue => setSearchSaleItem(newValue)}
                                        onSearch={handleSearchSaleItem}
                                    />
                                    <Box>
                                        {
                                            /**
                                             * Use for upcoming features
                                             */
                                        }
                                    </Box>
                                </FlexBetween>
                            </Box>
                            {
                                isPackager ? (
                                    <Box
                                        mt="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                        justifyContent="space-between"
                                        rowGap="20px"
                                        columnGap="1.33%"
                                        height="60vh"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            overflow: "hidden",
                                            overflowY: "scroll"
                                        }}
                                    >
                                        {saleRawStockOrderFiltered.map(
                                            ({
                                                sale_stock_id,
                                                item_name,
                                                price,
                                                unit,
                                                sale_status,
                                                sale_date_register,
                                                sale_time_register,
                                                sale_description
                                            }) => (
                                                <PurchaseOrderRawItem
                                                    key={sale_stock_id}
                                                    sale_stock_id={sale_stock_id}
                                                    item_name={item_name}
                                                    price={price}
                                                    unit={unit}
                                                    sale_status={sale_status}
                                                    sale_date_register={sale_date_register}
                                                    sale_time_register={sale_time_register}
                                                    sale_description={sale_description}
                                                />
                                            )
                                        )}
                                    </Box>
                                ) : (
                                    <Box
                                        mt="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                        justifyContent="space-between"
                                        rowGap="20px"
                                        columnGap="1.33%"
                                        height="60vh"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            overflow: "hidden",
                                            overflowY: "scroll"
                                        }}
                                    >
                                        {saleStockOrderFiltered.map(
                                            ({
                                                sale_stock_id,
                                                product_name,
                                                product_brand_name,
                                                product_category,
                                                product_package,
                                                price,
                                                unit,
                                                sale_status,
                                                product_serial_no,
                                                product_sku,
                                                sale_date_register,
                                                sale_time_register,
                                                sale_description
                                            }) => (
                                                <PurchaseOrderItem
                                                    key={sale_stock_id}
                                                    sale_stock_id={sale_stock_id}
                                                    product_name={product_name}
                                                    product_brand_name={product_brand_name}
                                                    product_category={product_category}
                                                    product_package={product_package}
                                                    price={price}
                                                    unit={unit}
                                                    sale_status={sale_status}
                                                    product_serial_no={product_serial_no}
                                                    product_sku={product_sku}
                                                    sale_date_register={sale_date_register}
                                                    sale_time_register={sale_time_register}
                                                    sale_description={sale_description}
                                                />
                                            )
                                        )}
                                    </Box>
                                )
                            }
                            <Box mt="20px">
                                <SubHeader title="Purchased Order Item"/>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.stock_order_id}
                                    rows={emptyData}
                                    columns={purchaseOrderColumn({isPackager: {isPackager}}).concat(manageColumn)}
                                    components={{ Toolbar:  QuickSearchToolbar }}
                                />
                            </Box>
                            <Box mt="20px">
                                <SubHeader title="Purchased Order Activity"/>
                            </Box>
                            <Box
                                mt="20px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <Box
                                    gridColumn="span 12"
                                    gridRow="span 3"
                                >
                                    <TabContext value={valuePurchaseBarChart}>
                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <TabList
                                                variant="fullWidth"
                                                onChange={handlePurchaseBarChart}
                                                textColor="inherit"
                                                indicatorColor="secondary"
                                            >
                                                <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                                <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                                <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <Box
                                                p="1.25rem 1rem"
                                                height="60vh"
                                                backgroundColor={theme.palette.background.alt}
                                                borderRadius="0.55rem"
                                            >
                                                <BarChartOrdersDaily/>
                                            </Box>
                                            <Box
                                                mt="10px"
                                                mb="20px"
                                                display="grid"
                                                gridTemplateColumns="repeat(10, 1fr)"
                                                gridAutoRows="160px"
                                                gap="20px"
                                                sx={{
                                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                                }}
                                            >
                                                <StatBox
                                                    title="Total Purchase Order"
                                                    value={totalPurchaseDailyOrder}
                                                    description="Daily"
                                                    icon={
                                                        <RequestPageOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px"}}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Pending Order"
                                                    value={totalPurchaseDailyPendingOrder}
                                                    rate={`${percentage(totalPurchaseDailyPendingOrder, totalPurchaseDailyOrder)}%`}
                                                    progress={progressRate(totalPurchaseDailyPendingOrder, totalPurchaseDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <PendingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Shipped Order"
                                                    value={totalPurchaseDailyShippedOrder}
                                                    rate={`${percentage(totalPurchaseDailyShippedOrder, totalPurchaseDailyOrder)}%`}
                                                    progress={progressRate(totalPurchaseDailyShippedOrder, totalPurchaseDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <LocalShippingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Delivered Order"
                                                    value={totalPurchaseDailyDeliveredOrder}
                                                    rate={`${percentage(totalPurchaseDailyDeliveredOrder, totalPurchaseDailyOrder)}%`}
                                                    progress={progressRate(totalPurchaseDailyDeliveredOrder, totalPurchaseDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <Inventory2Outlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Paid Order"
                                                    value={totalPurchaseDailyPaidOrder}
                                                    rate={`${percentage(totalPurchaseDailyPaidOrder, totalPurchaseDailyOrder)}`}
                                                    progress={progressRate(totalPurchaseDailyPaidOrder, totalPurchaseDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <MonetizationOnOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Reject Order"
                                                    value={totalPurchaseDailyRejectOrder}
                                                    rate={`${percentage(totalPurchaseDailyRejectOrder, totalPurchaseDailyOrder)}%`}
                                                    progress={progressRate(totalPurchaseDailyRejectOrder, totalPurchaseDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <CloseOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                mt="10px"
                                                height="60vh"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        borderRadius: "5rem"
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        backgroundColor: theme.palette.background.alt,
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderTop: "none",
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                        color: `${theme.palette.secondary[200]} !important`,
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.stock_order_id}
                                                    rows={emptyData}
                                                    columns={purchaseOrderColumn({isPackager: {isPackager}}).concat(manageColumn)}
                                                    components={{ Toolbar:  QuickSearchToolbar }}
                                                />
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <Box
                                                p="1.25rem 1rem"
                                                height="60vh"
                                                backgroundColor={theme.palette.background.alt}
                                                borderRadius="0.55rem"
                                            >
                                                <BarChartOrdersMonthly/>
                                            </Box>
                                            <Box
                                                mt="10px"
                                                mb="20px"
                                                display="grid"
                                                gridTemplateColumns="repeat(10, 1fr)"
                                                gridAutoRows="160px"
                                                gap="20px"
                                                sx={{
                                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                                }}
                                            >
                                                <StatBox
                                                    title="Total Purchase Order"
                                                    value={totalPurchaseMonthlyOrder}
                                                    description="Monthly"
                                                    icon={
                                                        <RequestPageOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px"}}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Pending Order"
                                                    value={totalPurchaseMonthlyPendingOrder}
                                                    rate={`${percentage(totalPurchaseMonthlyPendingOrder, totalPurchaseMonthlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseMonthlyPendingOrder, totalPurchaseMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <PendingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Shipped Order"
                                                    value={totalPurchaseMonthlyShippedOrder}
                                                    rate={`${percentage(totalPurchaseMonthlyShippedOrder, totalPurchaseMonthlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseMonthlyShippedOrder, totalPurchaseMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <LocalShippingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Delivered Order"
                                                    value={totalPurchaseMonthlyDeliveredOrder}
                                                    rate={`${percentage(totalPurchaseMonthlyDeliveredOrder, totalPurchaseMonthlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseMonthlyDeliveredOrder, totalPurchaseMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <Inventory2Outlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Paid Order"
                                                    value={totalPurchaseMonthlyPaidOrder}
                                                    rate={`${percentage(totalPurchaseMonthlyPaidOrder, totalPurchaseMonthlyOrder)}`}
                                                    progress={progressRate(totalPurchaseMonthlyPaidOrder, totalPurchaseMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <MonetizationOnOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Reject Order"
                                                    value={totalPurchaseMonthlyRejectOrder}
                                                    rate={`${percentage(totalPurchaseMonthlyRejectOrder, totalPurchaseMonthlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseMonthlyRejectOrder, totalPurchaseMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <CloseOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                mt="10px"
                                                height="60vh"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        borderRadius: "5rem"
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        backgroundColor: theme.palette.background.alt,
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderTop: "none",
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                        color: `${theme.palette.secondary[200]} !important`,
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.stock_order_id}
                                                    rows={emptyData}
                                                    columns={purchaseOrderColumn({isPackager: {isPackager}}).concat(manageColumn)}
                                                    components={{ Toolbar:  QuickSearchToolbar }}
                                                />
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <Box
                                                p="1.25rem 1rem"
                                                height="60vh"
                                                backgroundColor={theme.palette.background.alt}
                                                borderRadius="0.55rem"
                                            >
                                                <BarChartOrdersYearly/>
                                            </Box>
                                            <Box
                                                mt="10px"
                                                mb="20px"
                                                display="grid"
                                                gridTemplateColumns="repeat(10, 1fr)"
                                                gridAutoRows="160px"
                                                gap="20px"
                                                sx={{
                                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                                }}
                                            >
                                                <StatBox
                                                    title="Total Purchase Order"
                                                    value={totalPurchaseYearlyOrder}
                                                    description="Yearly"
                                                    icon={
                                                        <RequestPageOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px"}}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Pending Order"
                                                    value={totalPurchaseYearlyPendingOrder}
                                                    rate={`${percentage(totalPurchaseYearlyPendingOrder, totalPurchaseYearlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseYearlyPendingOrder, totalPurchaseYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <PendingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Shipped Order"
                                                    value={totalPurchaseYearlyShippedOrder}
                                                    rate={`${percentage(totalPurchaseYearlyShippedOrder, totalPurchaseYearlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseYearlyShippedOrder, totalPurchaseYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <LocalShippingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Delivered Order"
                                                    value={totalPurchaseYearlyDeliveredOrder}
                                                    rate={`${percentage(totalPurchaseYearlyDeliveredOrder, totalPurchaseYearlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseYearlyDeliveredOrder, totalPurchaseYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <Inventory2Outlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Paid Order"
                                                    value={totalPurchaseYearlyPaidOrder}
                                                    rate={`${percentage(totalPurchaseYearlyPaidOrder, totalPurchaseYearlyOrder)}`}
                                                    progress={progressRate(totalPurchaseYearlyPaidOrder, totalPurchaseYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <MonetizationOnOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Reject Order"
                                                    value={totalPurchaseYearlyRejectOrder}
                                                    rate={`${percentage(totalPurchaseYearlyRejectOrder, totalPurchaseYearlyOrder)}%`}
                                                    progress={progressRate(totalPurchaseYearlyRejectOrder, totalPurchaseYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <CloseOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                mt="10px"
                                                height="60vh"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        borderRadius: "5rem"
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        backgroundColor: theme.palette.background.alt,
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderTop: "none",
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                        color: `${theme.palette.secondary[200]} !important`,
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.stock_order_id}
                                                    rows={emptyData}
                                                    columns={purchaseOrderColumn({isPackager: {isPackager}}).concat(manageColumn)}
                                                    components={{ Toolbar:  QuickSearchToolbar }}
                                                />
                                            </Box>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatProgressBox
                                    title="Total Pending Order"
                                    rate={`${percentage(totalReceivePendingOrder, totalReceiveOrder)}%`}
                                    value={totalReceivePendingOrder}
                                    progress={progressRate(totalReceivePendingOrder, totalReceiveOrder)}
                                    description="Pending Order"
                                    icon={
                                        <PendingOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Shipped Order"
                                    rate={`${percentage(totalReceiveShippedOrder, totalReceiveOrder)}%`}
                                    value={totalReceiveShippedOrder}
                                    progress={progressRate(totalReceiveShippedOrder, totalReceiveOrder)}
                                    description="Shipped Order"
                                    icon={
                                        <LocalShippingOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Delivered Order"
                                    rate="0%"
                                    value={0}
                                    progress={0}
                                    description="Delivered Order"
                                    icon={
                                        <Inventory2Outlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Paid Order"
                                    rate="0%"
                                    value={0}
                                    progress={0}
                                    description="Paid Order"
                                    icon={
                                        <DoneOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatProgressBox
                                    title="Total Reject Order"
                                    rate="0%"
                                    value={0}
                                    progress={0}
                                    description="Reject Order"
                                    icon={
                                        <CloseOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                            </Box>
                            <Box mt="20px">
                                <SubHeader title="Received Order Item"/>
                            </Box>
                            <Box mt="20px">
                                <FlexBetween>
                                    <SearchBar
                                        placeholder="Search Order"
                                        value={searchSaleItem}
                                        onChange={newValue => setSearchOrderItem(newValue)}
                                        onSearch={handleSearchOrderItem}
                                    />
                                    <Box>
                                        
                                        {
                                            /**
                                             * Use for upcoming features
                                             */
                                        }
                                    </Box>
                                </FlexBetween>
                            </Box>
                            {
                                isProducer ? (
                                    <Box
                                        mt="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                        justifyContent="space-between"
                                        rowGap="20px"
                                        columnGap="1.33%"
                                        height="60vh"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            overflow: "hidden",
                                            overflowY: "scroll"
                                        }}
                                    >
                                        {orderRawStockOrderFiltered.map(
                                            ({
                                                stock_order_id,
                                                item_name,
                                                total_order,
                                                total_order_receive,
                                                total_price,
                                                total_amount,
                                                payment_status,
                                                status_order,
                                                date_order,
                                                time_order,
                                                remark
                                            }) => {
                                                <ReceiveOrderRawItem
                                                    key={stock_order_id}
                                                    stock_order_id={stock_order_id}
                                                    item_name={item_name}
                                                    total_order={total_order}
                                                    total_order_receive={total_order_receive}
                                                    total_price={total_price}
                                                    total_amount={total_amount}
                                                    payment_status={payment_status}
                                                    status_order={status_order}
                                                    date_order={date_order}
                                                    time_order={time_order}
                                                    remark={remark}
                                                />
                                            }
                                        )}
                                    </Box>
                                ) : (
                                    <Box
                                        mt="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                        justifyContent="space-between"
                                        rowGap="20px"
                                        columnGap="1.33%"
                                        height="60vh"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            overflow: "hidden",
                                            overflowY: "scroll"
                                        }}
                                    >
                                        {orderStockOrderFiltered.map(
                                            ({
                                                stock_order_id,
                                                product_name,
                                                product_brand_name,
                                                product_category,
                                                product_package,
                                                product_serial_no,
                                                product_sku,
                                                total_order,
                                                total_order_receive,
                                                total_price,
                                                total_amount,
                                                payment_status,
                                                status_order,
                                                remark,
                                                date_order,
                                                time_order
                                            }) => (
                                                <ReceiveOrderItem
                                                    key={stock_order_id}
                                                    stock_order_id={stock_order_id}
                                                    product_name={product_name}
                                                    product_brand_name={product_brand_name}
                                                    product_category={product_category}
                                                    product_package={product_package}
                                                    product_serial_no={product_serial_no}
                                                    product_sku={product_sku}
                                                    total_order={total_order}
                                                    total_order_receive={total_order_receive}
                                                    total_price={total_price}
                                                    total_amount={total_amount}
                                                    payment_status={payment_status}
                                                    status_order={status_order}
                                                    remark={remark}
                                                    date_order={date_order}
                                                    time_order={time_order}
                                                />
                                            )
                                        )}
                                    </Box>
                                )
                            }
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.stock_order_id}
                                    rows={emptyData}
                                    columns={receiveOrderColumn({isProducer: {isProducer}}).concat(manageColumn)}
                                    components={{ Toolbar:  QuickSearchToolbar }}
                                />
                            </Box>
                            <Box mt="20px">
                                <SubHeader title="Received Order Activity"/>
                            </Box>
                            <Box
                                mt="20px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <Box
                                    gridColumn="span 12"
                                    gridRow="span 3"
                                >
                                    <TabContext value={valueReceiveBarChart}>
                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <TabList
                                                variant="fullWidth"
                                                onChange={handleReceiveBarChart}
                                                textColor="inherit"
                                                indicatorColor="secondary"
                                            >
                                                <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                                <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                                <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <Box
                                                p="1.25rem 1rem"
                                                height="60vh"
                                                backgroundColor={theme.palette.background.alt}
                                                borderRadius="0.55rem"
                                            >
                                                <BarChartOrdersDaily/>
                                            </Box>
                                            <Box
                                                mt="10px"
                                                mb="20px"
                                                display="grid"
                                                gridTemplateColumns="repeat(10, 1fr)"
                                                gridAutoRows="160px"
                                                gap="20px"
                                                sx={{
                                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                                }}
                                            >
                                                <StatBox
                                                    title="Total Receive Order"
                                                    value={totalReceiveDailyOrder}
                                                    description="Daily"
                                                    icon={
                                                        <RequestPageOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px"}}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Pending Order"
                                                    value={totalReceiveDailyPendingOrder}
                                                    rate={`${percentage(totalReceiveDailyPendingOrder, totalReceiveDailyOrder)}%`}
                                                    progress={progressRate(totalReceiveDailyPendingOrder, totalReceiveDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <PendingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Shipped Order"
                                                    value={totalReceiveDailyShippedOrder}
                                                    rate={`${percentage(totalReceiveDailyShippedOrder, totalReceiveDailyOrder)}%`}
                                                    progress={progressRate(totalReceiveDailyShippedOrder, totalReceiveDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <LocalShippingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Delivered Order"
                                                    value={totalReceiveDailyDeliveredOrder}
                                                    rate={`${percentage(totalReceiveDailyDeliveredOrder, totalReceiveDailyOrder)}%`}
                                                    progress={progressRate(totalReceiveDailyDeliveredOrder, totalReceiveDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <Inventory2Outlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Paid Order"
                                                    value={totalReceiveDailyPaidOrder}
                                                    rate={`${percentage(totalReceiveDailyPaidOrder, totalReceiveDailyOrder)}`}
                                                    progress={progressRate(totalReceiveDailyPaidOrder, totalReceiveDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <MonetizationOnOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Reject Order"
                                                    value={totalReceiveDailyRejectOrder}
                                                    rate={`${percentage(totalPurchaseDailyRejectOrder, totalPurchaseDailyOrder)}%`}
                                                    progress={progressRate(totalPurchaseDailyRejectOrder, totalPurchaseDailyOrder)}
                                                    description="Daily"
                                                    icon={
                                                        <CloseOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                mt="10px"
                                                height="60vh"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        borderRadius: "5rem"
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        backgroundColor: theme.palette.background.alt,
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderTop: "none",
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                        color: `${theme.palette.secondary[200]} !important`,
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.stock_order_id}
                                                    rows={emptyData}
                                                    columns={receiveOrderColumn({isProducer: {isProducer}}).concat(manageColumn)}
                                                    components={{ Toolbar:  QuickSearchToolbar }}
                                                />
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <Box
                                                p="1.25rem 1rem"
                                                height="60vh"
                                                backgroundColor={theme.palette.background.alt}
                                                borderRadius="0.55rem"
                                            >
                                                <BarChartOrdersMonthly/>
                                            </Box>
                                            <Box
                                                mt="10px"
                                                mb="20px"
                                                display="grid"
                                                gridTemplateColumns="repeat(10, 1fr)"
                                                gridAutoRows="160px"
                                                gap="20px"
                                                sx={{
                                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                                }}
                                            >
                                                <StatBox
                                                    title="Total Receive Order"
                                                    value={totalReceiveMonthlyOrder}
                                                    description="Monthly"
                                                    icon={
                                                        <RequestPageOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px"}}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Pending Order"
                                                    value={totalReceiveMonthlyPendingOrder}
                                                    rate={`${percentage(totalReceiveMonthlyPendingOrder, totalReceiveMonthlyOrder)}%`}
                                                    progress={progressRate(totalReceiveMonthlyPendingOrder, totalReceiveMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <PendingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Shipped Order"
                                                    value={totalReceiveMonthlyShippedOrder}
                                                    rate={`${percentage(totalReceiveMonthlyShippedOrder, totalReceiveMonthlyOrder)}%`}
                                                    progress={progressRate(totalReceiveMonthlyShippedOrder, totalReceiveMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <LocalShippingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Delivered Order"
                                                    value={totalReceiveMonthlyDeliveredOrder}
                                                    rate={`${percentage(totalReceiveMonthlyDeliveredOrder, totalReceiveMonthlyOrder)}%`}
                                                    progress={progressRate(totalReceiveMonthlyDeliveredOrder, totalReceiveMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <Inventory2Outlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Paid Order"
                                                    value={totalReceiveMonthlyPaidOrder}
                                                    rate={`${percentage(totalReceiveMonthlyPaidOrder, totalReceiveMonthlyOrder)}`}
                                                    progress={progressRate(totalReceiveMonthlyPaidOrder, totalReceiveMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <MonetizationOnOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Reject Order"
                                                    value={totalReceiveMonthlyRejectOrder}
                                                    rate={`${percentage(totalPurchaseMonthlyRejectOrder, totalPurchaseMonthlyOrder)}%`}
                                                    progress={progressRate(totalReceiveMonthlyRejectOrder, totalPurchaseMonthlyOrder)}
                                                    description="Monthly"
                                                    icon={
                                                        <CloseOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                mt="10px"
                                                height="60vh"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        borderRadius: "5rem"
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        backgroundColor: theme.palette.background.alt,
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderTop: "none",
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                        color: `${theme.palette.secondary[200]} !important`,
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.stock_order_id}
                                                    rows={emptyData}
                                                    columns={receiveOrderColumn({isProducer: {isProducer}}).concat(manageColumn)}
                                                    components={{ Toolbar:  QuickSearchToolbar }}
                                                />
                                            </Box>
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <Box
                                                p="1.25rem 1rem"
                                                height="60vh"
                                                backgroundColor={theme.palette.background.alt}
                                                borderRadius="0.55rem"
                                            >
                                                <BarChartOrdersYearly/>
                                            </Box>
                                            <Box
                                                mt="10px"
                                                mb="20px"
                                                display="grid"
                                                gridTemplateColumns="repeat(10, 1fr)"
                                                gridAutoRows="160px"
                                                gap="20px"
                                                sx={{
                                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                                }}
                                            >
                                                <StatBox
                                                    title="Total Receive Order"
                                                    value={totalReceiveYearlyOrder}
                                                    description="Yearly"
                                                    icon={
                                                        <RequestPageOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px"}}   
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Pending Order"
                                                    value={totalReceiveYearlyPendingOrder}
                                                    rate={`${percentage(totalReceiveYearlyPendingOrder, totalReceiveYearlyOrder)}%`}
                                                    progress={progressRate(totalReceiveYearlyPendingOrder, totalReceiveYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <PendingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Shipped Order"
                                                    value={totalReceiveYearlyShippedOrder}
                                                    rate={`${percentage(totalReceiveYearlyShippedOrder, totalReceiveYearlyOrder)}%`}
                                                    progress={progressRate(totalReceiveYearlyShippedOrder, totalReceiveYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <LocalShippingOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Delivered Order"
                                                    value={totalReceiveYearlyDeliveredOrder}
                                                    rate={`${percentage(totalReceiveYearlyDeliveredOrder, totalReceiveYearlyOrder)}%`}
                                                    progress={progressRate(totalReceiveYearlyDeliveredOrder, totalReceiveYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <Inventory2Outlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Paid Order"
                                                    value={totalReceiveYearlyPaidOrder}
                                                    rate={`${percentage(totalReceiveYearlyPaidOrder, totalReceiveYearlyOrder)}`}
                                                    progress={progressRate(totalReceiveYearlyPaidOrder, totalReceiveYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <MonetizationOnOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                                        />
                                                    }
                                                />
                                                <StatProgressBox
                                                    title="Total Reject Order"
                                                    value={totalReceiveYearlyRejectOrder}
                                                    rate={`${percentage(totalReceiveYearlyRejectOrder, totalReceiveYearlyOrder)}%`}
                                                    progress={progressRate(totalReceiveYearlyRejectOrder, totalReceiveYearlyOrder)}
                                                    description="Yearly"
                                                    icon={
                                                        <CloseOutlined
                                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box
                                                mt="10px"
                                                height="60vh"
                                                sx={{
                                                    "& .MuiDataGrid-root": {
                                                        border: "none",
                                                        borderRadius: "5rem"
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderBottom: "none",
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        backgroundColor: theme.palette.background.alt,
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        backgroundColor: theme.palette.background.alt,
                                                        color: theme.palette.secondary[100],
                                                        borderTop: "none",
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                        color: `${theme.palette.secondary[200]} !important`,
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    getRowId={(row) => row.stock_order_id}
                                                    rows={emptyData}
                                                    columns={receiveOrderColumn({isProducer: {isProducer}}).concat(manageColumn)}
                                                    components={{ Toolbar:  QuickSearchToolbar }}
                                                />
                                            </Box>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
        </Box>
    )
}

export default UserOrders;