export const mockGovOrders = [
    {
        stock_order_id: 'CB451455112',
        qr_id: 'QR134551122',
        provider_unique_id: 'WHL-13451122',
        provider_company_name: 'BGC Distributor',
        recipient_unique_id: 'RTL-123441223',
        recipient_company_name: 'B/G Runcit',
        total_order: 20,
        total_price: 145.80,
        payment_status: 'Paid',
        status_order: 'Delivered',
        remark: 'Daily supplied product',
        date_order: '10/10/2023',
        time_order: '13:30:00'
    },
    {
        stock_order_id: 'CB671815112',
        qr_id: 'QR1390590122',
        provider_unique_id: 'WHL-1251122',
        provider_company_name: 'C&G Trading',
        recipient_unique_id: 'RTL-123441223',
        recipient_company_name: 'BTR Runcit',
        total_order: 20,
        total_price: 145.80,
        payment_status: 'Paid',
        status_order: 'Shipped',
        remark: 'Daily supplied product',
        date_order: '10/10/2023',
        time_order: '12:30:10'
    }
]

export const mockGovOrderChart = [
    {
        days: "11/10/2023",
        orders: 10,
        shipped: 5,
        delivered: 4,
        pending: 15,
        paid: 17
    },
    {
        days: "12/10/2023",
        orders: 5,
        shipped: 10,
        delivered: 12,
        pending: 4,
        paid: 20

    }
]