import React, { useEffect } from "react";

import {
    Typography,
    useMediaQuery,
    Box,
    useTheme,
    Container,
    Button
} from "@mui/material"

import {
    google_map_api
} from 'state/constants'

import { network } from "state/fetch/network";

import {
    GoogleMap, 
    Marker, 
    useLoadScript,
    DirectionsService,
    DirectionsRenderer
} from "@react-google-maps/api";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MapProducerDistribution = ({ delivery, drop, pickup, position }) => {
    const theme = useTheme();
    const matches = useMediaQuery('(min-width: 600px)');
    const navigate = useNavigate();

    const [activeMarker, setActiveMarker] = useState(null);
    const [deliveryDirectionResponse, setDeliveryDirectionResponse] = useState(null)
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')

    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    /*
    async function deliveryDropRoute() {
        const directionsService = new window.google.maps.DirectionsService()
        if(delivery.latitude && delivery.longitude) {
            if(drop.latitude && drop.longitude) {
                const resultDropDelivery = await directionsService.route({
                    origin: {
                        lat: delivery.latitude,
                        lng: delivery.longitude
                    },
                    destination: {
                        lat: drop.latitude,
                        lng: drop.longitude
                    },
                    travelMode: window.google.maps.TravelMode.DRIVING 
                })
                setDeliveryDirectionResponse(resultDropDelivery);
            }
        }
    }
    */

    useEffect(() => {
        try {
        } catch(e) {
        }
    })

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: google_map_api
    })

    if(!isLoaded) {
        return (
            <Typography>Unable to load Google</Typography>
        );
    }

    return (
        <Box height="100%" width="100%">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={position.center}
                zoom={position.zoom}
            >
                {delivery.latitude && delivery.longitude ? (
                    <Box>
                        <Marker
                            position={{
                                lat: delivery.latitude,
                                lng: delivery.longitude
                            }}
                        />
                        
                        {
                            /*
                            deliveryDirectionResponse && (
                                <DirectionsRenderer 
                                    directions={deliveryDirectionResponse}
                                    
                                />
                            )
                            */
                        }
                        
                    </Box>
                ) : (
                    null
                )}
            </GoogleMap>
        </Box>
    )
}

export default MapProducerDistribution;