import { network } from "state/fetch/network";

const url_packager_company = `${network.domain_api}/alarm/companies/packager`;

/* View API */

/**
 * Get alarm packager company data
 * 
 * @returns alarm packager company data
 */
export const getAlarmPackagerCompany = async () => {
    let response
    try {
        response = await fetch(`${url_packager_company}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Get alarm packager company by id
 * 
 * @param {*} param0 alarm packager company id
 * @returns alarm packager company by id
 */
export const getAlarmPackagerCompanyID = async ({aid}) => {
    let response
    try {
        response = await fetch(`${url_packager_company}/${aid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/* Controller API */

/**
 * Add alarm packager company data
 * 
 * @param {*} data alarm packager data
 * @returns alarm packager company data
 */
export const addAlarmPackagerCompany = async (data) => {
    let response
    try {
        response = await fetch(`${url_packager_company}/new`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update pacakger alarm
 * 
 * @param {*} param0 packager category id and data
 * @returns updated packager alarm
 */
export const updatePackagerAlarm = async ({data}) => {
    let response
    try {
        response = await fetch(`${url_packager_company}/update`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update alarm packager company data
 * 
 * @param {*} param0 alarm pacakger company id and data
 * @returns updated alarm packager company data
 */
export const updateAlarmPackagerCompany = async ({aid, cid, unique_id, data}) => {
    let response
    try {
        response = await fetch(`${url_packager_company}/update/${aid}/${cid}/${unique_id}`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Delete alarm packager company data
 * 
 * @param {*} param0 alarm pacakger company id and data
 * @returns deleted packager company data
 */
export const deleteAlarmPackagerCompany = async ({aid, cid, unique_id}) => {
    let response
    try {
        response = await fetch(`${url_packager_company}/delete/${aid}/${cid}/${unique_id}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}