import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, Collapse, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import FlexBetween from "../../../FlexBetween";
import { useNavigate } from "react-router";

const StatesIssueStockStat = ({
    sid,
    state_name,
    state_category,
    total_company,
    total_issues,
    consist_issue,
    description,
    total_stock_loss,
    total_stock_defects,
    total_stock_damage,
    total_others
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
            }}
        >
            <CardContent>
                <FlexBetween>
                    <Typography
                        sx={{
                            fontize: 14,
                            fontWeight: "bold"
                        }}
                        color={theme.palette.secondary[400]}
                        gutterBottom
                    >
                        {state_name} ({sid})
                    </Typography>
                    {
                        (consist_issue ? (
                            <ErrorOutlineOutlined sx={{ color: "red", fontSize: "26px"}}/>
                        ) : (
                            <></>
                        ))
                    }
                </FlexBetween>

                <Typography sx={{ mb: "1.5rem" }} color={theme.palette.secondary[400]}>
                    Total Company : { total_company }
                </Typography>
                <TextField
                    size="small"
                    label="Total Issues Occurs"
                    value={total_issues}
                    variant="outlined"
                    type="number"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    sx={{
                        mb: "20px"
                    }}
                />
                <Typography variant="body2">{description}</Typography>
                <TextField
                    size="small"
                    label="Subdivision Category"
                    value={state_category}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    sx={{
                        mt:"20px"
                    }}
                />
            </CardContent>
            <CardActions>
                <Button
                    variant="primary"
                    size="small"
                    sx={{
                        width:"100%"
                    }}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See More
                </Button>
            </CardActions>
            <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300],
                }}
            >
                <CardContent>
                    <Typography
                        variant="body2"
                        color={theme.palette.secondary[400]}
                        sx={{mb: "0.5rem"}}
                    >
                        Stock Issue Report
                    </Typography>
                    <TextField
                        label="Total Stock Loss"
                        value={total_stock_loss}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Total Stock Defects"
                        value={total_stock_defects}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Total Stock Damage"
                        value={total_stock_damage}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Total Others"
                        value={total_others}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                </CardContent>
            </Collapse>
            <CardActions>
                <Button
                    variant="primary"
                    size="small"
                    onClick={() => {
                        navigate(`/issues/states/${sid}`)
                    }}
                    sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "12px",
                        width: "100%"
                    }}
                >
                    <Typography sx={{ fontWeight: "bold" }}>
                        Navigate
                    </Typography>
                </Button>
            </CardActions>
        </Card>
    );
}

export default StatesIssueStockStat;