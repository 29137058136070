export const google_map_api = 'AIzaSyD3Vd781Ved7NdJOWr8nMiIbJjIYzZ3Wpc'

export const socketEvents = {
    SUBSCRIBE_TO_SHIPMENT: 'SUBSCRIBE_TO_SHIPMENT',
    SUBSCRIBE_TO_DA: 'SUBSCRIBE_TO_DA',
    DA_LOCATION_CHANGED: 'DA_LOCATION_CHANGED',
    LEAVE_ROOM: 'LEAVE_ROOM',
    SHIPMENT_UPDATED: 'SHIPMENT_UPDATED',
}

export const ACTIONS = {
    FIRST_LOAD: 'FIRST_LOAD',
    NEW_DELIVERY_CLICKED: 'NEW_DELIVERY_CLICKED',
    SET_DRIVER_LOCATION: 'SET_DRIVER_LOCATION',
    SET_PICKUP_LOCATION: 'SET_PICKUP_LOCATION',
    SET_DROP_LOCATION: 'SET_DROP_LOCATION'
}