import React from "react";
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";

import {
    HomeOutlined,
    BusinessOutlined,
    ShoppingCartOutlined,
    ChevronLeft,
    ChevronRightOutlined,
    SettingsOutlined,
    WarningOutlined,
    LocalShipping,
    LocalShippingOutlined,
    MonetizationOnOutlined,
    RequestQuoteOutlined
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "modules/FlexBetween";

import kpdnImage from "assets/kpdn-logo.png";

const navItems = [
    {
        url: "/dashboard",
        text: "Dashboard",
        icon: <HomeOutlined />,
    },
    {
        url: "/companies",
        text: "Companies",
        icon: <BusinessOutlined/>
    },
    {
        url: "/products",
        text: "Products",
        icon: <ShoppingCartOutlined/>
    },
    {
        url: "/distributions",
        text: "Distributions",
        icon: <LocalShippingOutlined/>
    },
    {
        url: "/sales",
        text: "Sales",
        icon: <MonetizationOnOutlined/>
    },
    {
        url: "/orders",
        text: "Orders",
        icon: <RequestQuoteOutlined/>
    },
    {
        url: "/issues",
        text: "Issues",
        icon: <WarningOutlined/>
    },
    {
        url: "/settings",
        text: "Settings",
        icon: <SettingsOutlined/>
    },
];

const Sidebar = ({
    user,
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile,
}) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setActive(pathname.substring(1));
    }, [pathname]);

    return (
        <Box component="nav">
            {isSidebarOpen && (
                <Drawer
                    open={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    variant="persistent"
                    anchor="left"
                    sx={{
                    width: drawerWidth,
                    "& .MuiDrawer-paper": {
                        color: theme.palette.secondary[200],
                        backgroundColor: theme.palette.background.alt,
                        boxSixing: "border-box",
                        borderWidth: isNonMobile ? 0 : "2px",
                        width: drawerWidth,
                    },
                    }}
                >
                    <Box width="100%">
                        <Box m="1.5rem 2rem 2rem 3rem">
                            <FlexBetween color={theme.palette.secondary.main}>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                    <Box
                                        component="img"
                                        alt="profile"
                                        src={kpdnImage}
                                        height="120px"
                                        width="120px"
                                    />
                                </Box>
                                {!isNonMobile && (
                                    <IconButton onClick={() => isSidebarOpen}>
                                        <ChevronLeft/>
                                    </IconButton>
                                )}
                            </FlexBetween>
                        </Box>
                        <List>
                            {navItems.map(({ text, icon }) => {
                                if(!icon) {
                                    return (
                                        <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem"}}>
                                            {text}
                                        </Typography>
                                    );
                                }
                                const lcText = text.toLowerCase();

                                return (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`${lcText}`);
                                                setActive(lcText);
                                              }}
                                              sx={{
                                                backgroundColor:
                                                  active === lcText
                                                    ? theme.palette.secondary[300]
                                                    : "transparent",
                                                color:
                                                  active === lcText
                                                    ? theme.palette.primary[600]
                                                    : theme.palette.secondary[100],
                                              }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    ml: "2rem",
                                                    color:
                                                      active === lcText
                                                        ? theme.palette.primary[600]
                                                        : theme.palette.secondary[200],
                                                  }}
                                            >
                                                {icon}
                                            </ListItemIcon>
                                            <ListItemText primary={text}/>
                                            {active === lcText && (
                                                <ChevronRightOutlined sx={{ ml: "auto" }} />
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>

                    <Box marginBottom="10px">
                        <Divider/>
                        <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
                            <Avatar
                                alt="profile"
                                src={''}
                                height="40px"
                                width="40px"
                                sx={{ objectFit: "cover" }}
                            />
                            <Box textAlign="left">
                                <Typography
                                    fontWeight="bold"
                                    fontSize="0.9rem"
                                    sx={{ color: theme.palette.secondary[100]}}
                                >
                                    {user.name}
                                </Typography>
                                <Typography
                                    fontSize="0.8rem"
                                    sx={{ color: theme.palette.secondary[200] }}
                                >
                                    {user.occupation}
                                </Typography>
                            </Box>
                            <SettingsOutlined
                                sx={{
                                    color: theme.palette.secondary[300],
                                    fontSize: "25px"
                                }}
                            />
                        </FlexBetween>
                        <Box m="60px 10px 0px 10px">
                            <Typography
                                variant="body2"
                                align="center"
                            >
                                Copyright © {new Date().getFullYear()} Dataran Teknologi Sdn. Bhd. All Rights Reserved.
                            </Typography>
                        </Box>
                    </Box>
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;