import { network } from "../network";

const url_distributions = `${network.domain_api}/distribution`;

/**
 * Get distributions data
 * 
 * @returns distributons data
 */
export const getDistributions = async () => {
    let response
    try {
        response = await fetch(`${url_distributions}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get distributions state
 * 
 * @param {*} param0 distributions state id
 * @returns distributions state data
 */
export const getDistributionsState = async ({sid}) => {
    let response
    try {
        response = await fetch(`${url_distributions}/states/${sid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get distributions district data
 * 
 * @param {*} param0 distributions district id
 * @returns distributions district data
 */
export const getDistributionsDistrict = async ({sid, did}) => {
    let response
    try {
        response = await fetch(`${url_distributions}/states/district/${sid}/${did}`, {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get distribution network
 * 
 * @param {*} param0 distribution network
 * @returns distributions network data
 */
export const getDistributionsNetwork = async () => {
    let response
    try {
        response = await fetch(`${url_distributions}/networks`, {
            method: 'GET',
            headers: {
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}