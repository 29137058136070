import { network } from "state/fetch/network";

const url_producer_company = `${network.domain_api}/alarm/companies/producer`;

/* View API */

/**
 * Get alarm producer company data
 * 
 * @returns alarm group producer company data
 */
export const getAlarmProducerCompany = async () => {
    let response
    try {
        response = await fetch(`${url_producer_company}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Get alarm producer company by id
 * 
 * @param {*} param0 alarm producer company id
 * @returns alarm producer company by id
 */
export const getAlarmProducerCompanyID = async ({aid}) => {
    let response
    try {
        response = await fetch(`${url_producer_company}/${aid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/* Controller API */

/**
 * Add alarm producer company
 * 
 * @param {*} data data alarm producer company data
 * @returns alarm producer company
 */
export const addAlarmProducerCompany = async (data) => {
    let response
    try {
        response = await fetch(`${url_producer_company}/new`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update producer alarm
 * 
 * @param {*} param0 producer category id and data
 * @returns updated producer alarm
 */
export const updateProducerAlarm = async ({data}) => {
    let response
    try {
        response = await fetch(`${url_producer_company}/update`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update alarm producer company data
 * 
 * @param {*} param0 alarm producer company id and data
 * @returns updated alarm producer company data
 */
export const updateAlarmProducerCompany = async ({aid, cid, unique_id, data}) => {
    let response
    try {
        response = await fetch(`${url_producer_company}/update/${aid}/${cid}/${unique_id}`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
    } catch(error) {
        response = error
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Delete alarm producer company data
 * 
 * @param {*} param0 alarm producer company id
 * @returns deleted alarm producer company data
 */
export const deleteAlarmProducerCompany = async ({aid, cid, unique_id}) => {
    let response
    try {
        response = await fetch(`${url_producer_company}/delete/${aid}/${cid}/${unique_id}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}