import { useTheme, Card, CardContent, Typography, TextField, CardActions, Button, Collapse } from "@mui/material";
import { useState } from "react";
import FlexBetween from "modules/FlexBetween";

const StockProducerItem = ({
    wid,
    item_name,
    total_stock_in,
    total_stock_out,
    total_stock
}) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem"
            }}
        >
            <CardContent>
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold"
                    }}
                    color={theme.palette.secondary[400]}
                    glutterBottom
                >
                    {item_name}
                </Typography>
                <TextField
                    size="small"
                    label="Warehouse ID"
                    value={wid}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    margin="dense"
                />
                <FlexBetween>
                    <TextField
                        size="small"
                        label="Total Stock In"
                        value={total_stock_in}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                    />
                    <TextField
                        size="small"
                        label="Total Stock Out"
                        value={total_stock_out}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                    />
                </FlexBetween>
                <TextField
                    label="Total Stock"
                    value={total_stock}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    margin="dense"
                    size="small"
                />
            </CardContent>
        </Card>
    )
}

export default StockProducerItem;