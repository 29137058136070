import { Box, Typography } from "@mui/material";

const { useTheme } = require("@emotion/react")

const SubHeader = ({title}) => {
    const theme = useTheme();
    return (
        <Box>
            <Typography variant="h5" color={theme.palette.secondary[300]}>
                {title}
            </Typography>
        </Box>
    )
}

export default SubHeader;