import React from "react";
import {
    Typography,
    useMediaQuery,
    Box,
    useTheme,
    Container,
    Button
} from "@mui/material"

import io from 'socket.io-client';

import {
    google_map_api
} from 'state/constants'

import { network } from "state/fetch/network";
import { GoogleMap, Marker, useLoadScript, InfoWindow } from "@react-google-maps/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const socket = io(network.domain)

const MapWholesalerDistribution = ({ position }) => {
    const theme = useTheme();
    const matches = useMediaQuery('(min-width: 600px)');
    const navigate = useNavigate();

    const [activeMarker, setActiveMarker] = useState(null);
    const [isConnected, setIsConnected] = useState(socket.connected)
    
    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: google_map_api
    })

    if(!isLoaded) {
        return (
            <Typography>Unable to load Google</Typography>
        );
    }

    return (
        <Box height="100%" width="100%">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={position.center}
                zoom={position.zoom}
            >

            </GoogleMap>
        </Box>
    )
}

export default MapWholesalerDistribution;