import { 
    Avatar, 
    Box, 
    Button, 
    Container, 
    Grid, 
    TextField, 
    Typography, 
    useTheme,
    Link
} from "@mui/material";

import logoImage from "assets/kpdn-logo.png"
import {  useNavigate } from "react-router-dom";

const Register = () => {

    const theme = useTheme();
    const navigate = useNavigate();

    const Copyright = (props) => {
        return (
            <Typography variant="body2" align="center" {...props}>
                Copyright © {new Date().getFullYear()} Dataran Teknologi Sdn. Bhd. All Rights Reserved.
            </Typography>
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password') 
        })
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    src={logoImage}
                    sx={{
                        width: "120px",
                        height: "120px",
                        m: 1,
                    }}
                />
                <Typography
                    mt="10px" 
                    component="h1" 
                    align="center"
                    variant="h5"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Kementerian Perdangangan Dalam Negeri & Kos Sara Hidup
                </Typography>
                <Typography 
                    mt="10px"
                    component="h1" 
                    align="center"
                    variant="h6"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Logistic Interface Computive Automation (LICA)
                </Typography>
                <Typography
                    component="h1" 
                    align="center"
                    variant="h6"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Cooking Oil Monitoring System
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="given-name"
                                name="fullName"
                                required
                                fullWidth
                                id="fullName"
                                label="Full Name"
                                autoFocus
                                color="secondary"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            fullWidth
                            id="phoneNo"
                            label="Phone Number"
                            name="phoneNo"
                            autoComplete="phoneNo"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ 
                            mt: 3, 
                            mb: 2,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Link
                                component="button"
                                onClick={() => {
                                    navigate('/')
                                }} 
                                variant="body2" 
                                style={{ color: theme.palette.secondary.light }}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 2 }}/>
        </Container>
    );
}

export default Register;