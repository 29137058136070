export const mockL2SimeDarbyData = {
    cid: 1,
    total_issue: 1,
    total_metric_ton: "124,000",
    total_metric_ton_quota: "100,000",
    metric_tan_quota_exceed: true,
    metric_tan_date: "24/02/2023",
    total_package: "25,000",
    total_package_quota: "300,000",
    package_quota_exceed: false,
    total_distribution: "17,100",
    total_distribution_quota: "50,000",
    distribution_quota_exceed: false,
    total_unit_sold_per_day: "15,120",
    total_unit_sold_per_month: "45,678",
    total_unit_sold_per_year: "125,456",
    total_sales: "RM645,000",
    unique_id: '1427735-A-AB10001506',
    sid: 'MY-10',
    company_level: "1 & 2",
    company_category: 'Factory & Packager',
    company_name: 'Sime Darby Oils Langat Refinery Sdn. Bhd.',
    company_address: 'NO. 2, JALAN PERAK, KAWASAN PERUSAHAAN BATU 9, JALAN KLANG-BANTING, 42500 TELOK PANGLIMA GARANG, SELANGOR',
    state_name: 'Selangor',
    state_category: 'State',
    district_name: 'Klang',
    company_ssm: '1427735-A',
    company_license: 'AB10001506',
    company_business: 'Private Limited Company',
    company_business_operation: 'Production',
    company_start_csa_license: '14/4/2022',
    company_end_csa_license: '13/2/2022',
    owners: [
        {
            oid: 1,
            name: "Shahrizan Aini binti Shamsul Khalil",
            email: "xxx@gmail.xxxx",
            phone_no: "019-xxxxxx",
        },
        {
            oid: 2,
            name: "Mohd Haris bin Mohd Arshad",
            email: "xxx@gmail.xxxx",
            phone_no: "019-xxxxxx",
        },
        {
            oid: 3,
            name: "Renaka A/P Ramachandran",
            email: "xxx@gmail.xxxx",
            phone_no: "019-xxxxxx",
        }
    ],
    position: {
        center: {
            lat: 2.9216833,
            lng: 101.4740182
        },
        zoom: 10,
    },
    distribution: [
        {
            cid: 1,
            company_name: 'Sime Darby Oils Langat Refinery Sdn. Bhd.',
            latitude: 2.9216833,
            longitude: 101.4740182,
        },
        {
            cid: 2,
            company_name: 'Asri Vegetable Oil Products Sdn. Bhd.',
            latitude: 2.991521,
            longitude: 101.410645,
        },
        {
            cid: 3,
            company_name: 'Ikira Sdn. Bhd.',
            latitude: 2.977167,
            longitude: 101.7579021,
        }
    ]
}