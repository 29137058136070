import { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";

import {
    Box, 
    Button, 
    Tab, 
    useMediaQuery, 
    useTheme
} from "@mui/material";



import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab";

import {
    DownloadOutlined,
    BusinessOutlined,
    ShoppingCartOutlined,
    WarningOutlined,
    SettingsOutlined,
    LocalShippingOutlined,
    MonetizationOnOutlined,
    RequestPageOutlined
} from "@mui/icons-material";

import { 
    consistIssue
} from "data/companyData";

import Company from "scenes/govs/companies/company";
import Products from "scenes/govs/products/product";
import Issues from "scenes/govs/issues/issue";
import Settings from "scenes/govs/settings";
import Distribution from "scenes/govs/distributions/distribution";
import Order from "scenes/govs/orders/order";
import Sale from "scenes/govs/sales/sale";
import { toDataURL } from "utils/encoders";

const Dashboard = () => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    

    const [value, setValue] = useState('1')
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Kementerian Perdagangan Dalam Negeri & Kos Sara Hidup" subtitle="Welcome to Logistic Interface Computive Automation (LICA) Cooking Oil Dashboard"/>
                <Box>
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        onClick={() => {
                            
                        }}
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        <DownloadOutlined sx={{ mr: "10px" }}/>
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                variant="fullWidth"
                                onChange={handleChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                            >
                                <Tab icon={<BusinessOutlined/>} iconPosition="start" label="Company" value="1"/>
                                <Tab icon={<ShoppingCartOutlined/>} iconPosition="start" label="Products" value="2"/>
                                <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Distributions" value="3"/>
                                <Tab icon={<MonetizationOnOutlined/>} iconPosition="start" label="Sales" value="4"/>
                                <Tab icon={<RequestPageOutlined/>} iconPosition="start" label="Orders" value="5"/>
                                <Tab icon={<WarningOutlined/>} iconPosition="start" label="Issues" value="6" sx={{ backgroundColor:  consistIssue.consist_issue ? "red" : "" }}/>
                                <Tab icon={<SettingsOutlined/>} iconPosition="start" label="Settings" value="7"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Company isDashboard/>
                        </TabPanel>
                        <TabPanel value="2">
                            <Products isDashboard/>
                        </TabPanel>
                        <TabPanel value="3">
                            <Distribution isDashboard/>
                        </TabPanel>
                        <TabPanel value="4">
                            <Sale isDashboard/>
                        </TabPanel>
                        <TabPanel value="5">
                            <Order isDashboard/>
                        </TabPanel>
                        <TabPanel value="6">
                            <Issues isDashboard/>
                        </TabPanel>
                        <TabPanel value="7">
                            <Settings isDashboard/>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
        </Box>
    )
 }

 export default Dashboard;