import { network } from "../network";

const url_states = `${network.domain_api}/state`;
const url_states_name = `${network.domain_api}/name/state`

/* View API */

/**
 * Get list of states
 * 
 * @returns list of states
 */
export const getStates = async () => {
    let response
    try {
        response = await fetch(`${url_states}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get list of state names
 * 
 * @returns list of state names
 */
export const getStateNames = async () => {
    let response
    try {
        response = await fetch(`${url_states_name}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get list of state districts
 * 
 * @param {*} param0 state id
 * @returns list of state districts
 */
export const getStateDistricts = async ({sid}) => {
    let response
    try {
        response = await fetch(`${url_states}/district/${sid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get list of state district names
 * 
 * @param {*} param0    state id
 * @returns list of state district names
 */
export const getStateDistrictNames = async ({sid}) => {
    let response
    try {
        response = await fetch(`${url_states_name}/district/${sid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}