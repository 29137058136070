import { network } from "../network";

const url_products = `${network.domain_api}/products/cooking-oil`;
const url_product = `${network.domain_api}/product/cooking-oil`;

/* View API */

/**
 * Get list of products
 * 
 * @returns list of products
 */
export const getProducts = async () => {
    let response
    try {
        response = await fetch(`${url_products}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error;
    }
    return await response.json();
}

/**
 * Get products state
 * 
 * @param {*} param0 products state id
 * @returns products state data
 */
export const getProductsState = async ({sid}) => {
    let response
    try {
        response = await fetch(`${url_products}/states/${sid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get products district data
 * 
 * @param {*} param0 product district id
 * @returns     products district data
 */
export const getProductsDistrict = async ({sid, did}) => {
    let response
    try {
        response = await fetch(`${url_products}/states/district/${sid}/${did}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get product data by product id
 * 
 * @param {*} param0 product id
 * @returns product data by product id
 */
export const getProduct = async ({pid, bid, unique_id}) => {
    let response
    try {
        response = await fetch(`${url_product}/${pid}/${bid}/${unique_id}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/* Controller API */

/**
 * Add product data
 * 
 * @param {*} data product data
 * @returns added product data
 */
export const addProduct = async (data) => {
    let response
    try {
        response = await fetch(`${url_product}/new`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error;
    }
    let json = await response.json();
    return json;
}

/**
 * Update product
 * 
 * @param {*} param0    product id and data 
 * @returns updated product data
 */
export const updateProduct = async ({pid, bid, data}) => {
    let response
    try {
        response = await fetch(`${url_product}/update/${pid}/${bid}`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error;
    }
    let json = await response.json();
    return json;
}

/**
 * Delete product
 * 
 * @param {*} param0    product id 
 * @returns deleted product data
 */
export const deleteProduct = async ({product_category, product_package, pid, bid, unique_id}) => {
    let response
    try {
        response = await fetch(`${url_product}/delete/${product_category}/${product_package}/${pid}/${bid}/${unique_id}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error;
    }
    let json = await response.json();
    return json;
}