export const mockSimeDarbyDistribute = [
    {
        stock_order_id: "SO-001",
        company_name: "Asri Vegetable Oil Products Sdn. Bhd.",
        state: "Selangor",
        total_distribution: "1000",
        unit_type: "Package",
        brand_name: "Alif",
        total_sales: "RM34,00",
        delivery_status: "Critical",
        delivery_date: "23/02/2023",
        delivery_time: "14:13:45",
        receive_date: "24/02/2023",
        receive_time: "18:09:45",
    },
    {
        stock_order_id: "SO-002",
        company_name: "Ikira Sdn. Bhd.",
        state: "Selangor",
        total_distribution: "1700",
        unit_type: "Package",
        brand_name: "Alif",
        total_sales: "RM45,250",
        delivery_status: "Good",
        delivery_date: "23/02/2023",
        delivery_time: "10:12:34",
        receive_date: "23/02/2023",
        receive_time: "14:45:23",
    }
];

export const mockGovDistribution = [
    {
        qr_id: 'QR1234134121',
        delivery_order_id: 'DO124441CB',
        supplier_company_id: 'PR-20241215',
        supplier_company_name: 'Company A',
        requester_company_id: 'PKG-4511245',
        requester_company_name: 'Company Packager B',
        states: 'Kelantan',
        districts: 'Kota Baharu',
        total_stock_distribute: 140,
        total_stock_receive: 0,
        delivery_status: 'Delivery',
        delivery_date: '13/04/2023',
        delivery_time: '09:10:14',
        delivery_duration: 14,
        receive_date: '',
        receive_time: ''
    },
    {
        qr_id: 'QR1234134121',
        delivery_order_id: 'DO124441CB',
        supplier_company_id: 'PR-202434215',
        supplier_company_name: 'Company C',
        requester_company_id: 'PKG-45112345',
        requester_company_name: 'Company Packaget A12',
        states: 'Terengganu',
        districts: 'Kuala Terengganu',
        total_stock_distribute: 1500,
        total_stock_receive: 0,
        delivery_status: 'Delivery',
        delivery_date: '09/10/2023',
        delivery_time: '14:20:14',
        delivery_duration: 5,
        receive_date: '',
        receive_time: ''
    },
    {
        qr_id: 'QR1234134456',
        delivery_order_id: 'DO1245241CB',
        supplier_company_id: 'PR-202454215',
        supplier_company_name: 'Company CXC',
        requester_company_id: 'PKG-12112345',
        requester_company_name: 'Company Packaget C13',
        states: 'Terengganu',
        districts: 'Kuala Terengganu',
        total_stock_distribute: 45000,
        total_stock_receive: 0,
        delivery_status: 'Complete',
        delivery_date: '09/03/2023',
        delivery_time: '08:10:14',
        delivery_duration: 3,
        receive_date: '09/03/2023',
        receive_time: '14:45:00'
    }
]