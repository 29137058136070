import { 
    Box, 
    Button,
    Dialog, 
    DialogTitle, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery,
    useTheme,
    CardContent,
    Card} from "@mui/material";

import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab"

import {
    DownloadOutlined,
    FactoryOutlined,
    LocalShippingOutlined,
    CloseOutlined,
    ReportProblemOutlined,
    RequestPageOutlined,
    GarageOutlined,
    DirectionsCarOutlined,
    CallReceivedOutlined
} from "@mui/icons-material"

import PropTypes from "prop-types";
import { styled } from "@mui/system";

import MapUserDistribution from "modules/maps/users/MapUserDistribution";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { mockForceUser } from "data/mockForce";
import SingleDistributeSupplyForce from "modules/forces/SingleDistributeSupplyForce";
import DistributeSupplyTree from "modules/trees/DistributeSupplyTree";
import { mockTreeOrg } from "data/mockTree";
import ReceiveSupplyTree from "modules/trees/ReceiveSupplyTree";
import { genenerateDummyRawDO } from "documents/dummyDocuments";
import { toDataURL } from "utils/encoders";

const BootstrapDialog = styled(Dialog) (({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const UserDistribution = ({isDashboard = false}) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const [valueDelivery, setValueDelivery] = useState('1')
    const [valueInternalDistribute, setValueInternalDistribute] = useState('1')
    const [valueExternalDistribute, setValueExternalDistribute] = useState('1')

    const navigate = useNavigate();

    const [totalIssue, setTotalIssue] = useState(0)
    const [totalStockShipped, setTotalStockShipped] = useState(0)
    const [totalStockDelivered, setTotalStockDelivered] = useState(0)

    // region data
    const [region, setRegion] = useState({});
    
    useEffect(() => {
        setTotalIssue(1)

        setRegion({
            center: {
                lat: 4.210484,
                lng: 101.975766
            },
            zoom: 5,
        })
    }, [])

    const handleValueDeliveryChange = (event, newValue) => {
        setValueDelivery(newValue);
    }

    const handleValueInternalDistributeChange = (event, newValue) => {
        setValueInternalDistribute(newValue)
    }

    const handleValueExternalDistributeChange = (event, newValue) => {
        setValueExternalDistribute(newValue)
    }

    const emptyData = [];

    const internalDeliveryColumn = () => {
        const columns = [
            {
                field: "qr_id",
                headerName: "QRID",
                headerAlign: 'center',
                align: 'center',
                width: 100
            },
            {
                field: "delivery_order_id",
                headerName: "Delivery Order ID",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "courier_id",
                headerName: "Courier ID",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "courier_name",
                headerName: "Courier Name",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "company_name",
                headerName: "Company Name",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "state",
                headerName: "State",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "district",
                headerName: "District",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_stock_distributie",
                headerName: "Total Distribute",
                fheaderAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_stock_request",
                headerName: "Total Request",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "unit_type",
                headerName: "Unit Type",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "brand_name",
                headerName: "Brand",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_unit",
                heaaderName: "Total Unit",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_payment",
                headerName: "Total Payment",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "delivery_status",
                headerName: "Delivery Status",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "delivery_date",
                headerName: "Date Delivery",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "delivery_time",
                headerName: "Time Delivery",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "receive_date",
                headerName: "Date Receive",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "receive_time",
                headerName: "Time Receive",
                headerAlign: 'center',
                align: 'center',
                width: 180
            }
        ]
        return columns
    }

    const externalDeliveryColumn = () => {
        const columns = [
            {
                field: "qr_id",
                headerName: "QRID",
                headerAlign: 'center',
                align: 'center',
                width: 100
            },
            {
                field: "delivery_order_id",
                headerName: "Delivery Order ID",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "delivery_id",
                headerName: "Delivery ID",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "company_name",
                headerName: "Company Name",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "state",
                headerName: "State",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "district",
                headerName: "District",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_stock_distributie",
                headerName: "Total Distribute",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_stock_request",
                headerName: "Total Request",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "unit_type",
                headerName: "Unit Type",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "brand_name",
                headerName: "Brand",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_unit",
                heaaderName: "Total Unit",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "total_payment",
                headerName: "Total Payment",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "delivery_status",
                headerName: "Delivery Status",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "delivery_date",
                headerName: "Date Delivery",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "delivery_time",
                headerName: "Time Delivery",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "receive_date",
                headerName: "Date Receive",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: "receive_time",
                headerName: "Time Receive",
                headerAlign: 'center',
                align: 'center',
                width: 180
            }
        ]
        return columns;
    }

    const manageColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: '100%'
                        }}
                        onClick={() => {
                            navigate(``)
                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        }
    ];

     /**
     * ==============================
     *  Dummy purpose only
     * ==============================
     */
     const [base64QRData, setBase64QRData] = useState('')
     const qrURL = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example`
 
     useEffect(() => {
         // Generate and covert QR code into base64 image
         toDataURL(qrURL).then(dataUrl => {
             setBase64QRData(dataUrl)
         })
     }, [])
     /**
      * ==============================
      *  Dummy purpose only
      * ==============================
      */

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Distribution Activity" subtitle="Distirbution Report Activity"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                onClick={() => {
                                    genenerateDummyRawDO({base64QR: base64QRData})
                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                {/* ROW 1 */}
                <StatBox
                    title="Total Issue"
                    value={totalIssue}
                    description="Issue(s)"
                    icon={
                        <ReportProblemOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Shipped Item"
                    value={totalStockShipped}
                    description="Shipped Item"
                    icon={
                        <LocalShippingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Delivered Item"
                    value={totalStockDelivered}
                    description="Delivered Item"
                    icon={
                        <CallReceivedOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
            </Box>
            <Box
                mt="20px"
                gridColumn="span 12"
                gridRow="span 3"
            >
                <TabContext value={valueDelivery}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            variant="fullWidth"
                            onChange={handleValueDeliveryChange}
                            textColor="inherit"
                            indicatorColor="secondary"
                        >
                            <Tab icon={<GarageOutlined/>} iconPosition="start" label="Internal Distribution" value="1"/>
                            <Tab icon={<DirectionsCarOutlined/>} iconPosition="start" label="External Distribution" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box height="100%" width="100%">
                            <MapUserDistribution position={region}/>
                        </Box>
                        <Box
                            mt="20px"
                            display="grid"
                            gridTemplateColumns="repeat(10, 1fr)"
                            gridAutoRows="160px"
                            gap="20px"
                            sx={{
                                "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                            }}
                        >
                            <StatBox
                                title="Total Links"
                                value={0}
                                description="Number of distribution network link"
                                icon={
                                    <FactoryOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatBox
                                title="Total Active Links"
                                value={0}
                                description="Number of active distribution network link"
                                icon={
                                    <FactoryOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatBox
                                title="Total Complete Links"
                                value={0}
                                description="Number of complete distribution network link"
                                icon={
                                    <CallReceivedOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                    />
                                }
                            />
                        </Box>
                        <Box mt="20px">
                            <Card
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.alt,
                                    borderRadius: "0.55rem"
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold"
                                        }}
                                        color={theme.palette.secondary[300]}
                                        gutterBottom
                                    >
                                        SS Distribution Network Flow
                                    </Typography>
                                    <SingleDistributeSupplyForce forceData={mockForceUser}/>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.qr_id}
                                rows={emptyData}
                                columns={internalDeliveryColumn().concat(manageColumn)}
                                components={{ Toolbar: QuickSearchToolbar }}
                            />
                        </Box>
                        <Box
                            mt="40px"
                        >
                            <TabContext value={valueInternalDistribute}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleValueInternalDistributeChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Distributions" value="1"/>
                                        <Tab icon={<CallReceivedOutlined/>} iconPosition="start" label="Receives" value="2"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Card
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.alt,
                                            borderRadius: "0.55rem"
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold"
                                                }}
                                                color={theme.palette.secondary[300]}
                                                glutterBottom
                                            >
                                                Distribution Network Flow
                                            </Typography>
                                            <Box mt="20px">
                                                <DistributeSupplyTree data={mockTreeOrg}/>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Card
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.alt,
                                            borderRadius: "0.55rem"
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                               sx={{
                                                fontSize: 14,
                                                fontWeight: "bold"
                                            }}
                                            color={theme.palette.secondary[300]}
                                            gutterBottom 
                                            >
                                                Receives Network Flow
                                            </Typography>
                                        </CardContent>
                                        <Box mt="20px">
                                            <ReceiveSupplyTree data={mockTreeOrg}/>
                                        </Box>
                                    </Card>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box height="100%" width="100%">
                            <MapUserDistribution position={region}/>
                        </Box>
                        <Box
                            mt="20px"
                            display="grid"
                            gridTemplateColumns="repeat(10, 1fr)"
                            gridAutoRows="160px"
                            gap="20px"
                            sx={{
                                "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                            }}
                        >
                            <StatBox
                                title="Total Links"
                                value={0}
                                description="Number of distribution network link"
                                icon={
                                    <FactoryOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatBox
                                title="Total Active Links"
                                value={0}
                                description="Number of active distribution network link"
                                icon={
                                    <FactoryOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatBox
                                title="Total Complete Links"
                                value={0}
                                description="Number of complete distribution network link"
                                icon={
                                    <CallReceivedOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                    />
                                }
                            />
                        </Box>
                        <Box mt="20px">
                            <Card
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.alt,
                                    borderRadius: "0.55rem"
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold"
                                        }}
                                        color={theme.palette.secondary[300]}
                                        glutterBottom
                                    >
                                        SS Distribution Network Flow
                                    </Typography>
                                    <SingleDistributeSupplyForce forceData={mockForceUser}/>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.qr_id}
                                rows={emptyData}
                                columns={externalDeliveryColumn().concat(manageColumn)}
                                components={{ Toolbar: QuickSearchToolbar }}
                            />
                        </Box>
                        <Box
                            mt="40px"
                        >
                            <TabContext value={valueExternalDistribute}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleValueExternalDistributeChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Distributions" value="1"/>
                                        <Tab icon={<CallReceivedOutlined/>} iconPosition="start" label="Receives" value="2"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Card
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.alt,
                                            borderRadius: "0.55rem"
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold"
                                                }}
                                                color={theme.palette.secondary[300]}
                                                glutterBottom
                                            >
                                                Distribution Network Flow
                                            </Typography>
                                            <Box mt="20px">
                                                <DistributeSupplyTree data={mockTreeOrg}/>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Card
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.alt,
                                            borderRadius: "0.55rem"
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                               sx={{
                                                fontSize: 14,
                                                fontWeight: "bold"
                                            }}
                                            color={theme.palette.secondary[300]}
                                            gutterBottom 
                                            >
                                                Receives Network Flow
                                            </Typography>
                                        </CardContent>
                                        <Box mt="20px">
                                            <ReceiveSupplyTree data={mockTreeOrg}/>
                                        </Box>
                                    </Card>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    )
    
}

export default UserDistribution;