function zeroResult(partialValue, totalValue) {
    if(partialValue === 0 && totalValue === 0) {
        return true
    }
}

export function percentage(partialValue, totalValue) {
    return zeroResult(partialValue, totalValue) ? 0 : (100 * partialValue / totalValue);
}

export function progressRate(partialValue, totalValue) {
    return zeroResult(partialValue, totalValue) ? 0 : (partialValue / totalValue);
}