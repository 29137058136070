import {
    CallOutlined, 
    CallReceivedOutlined, 
    CheckCircleOutline, 
    CorporateFareOutlined, 
    DownloadOutlined, 
    EmailOutlined, 
    ErrorOutlineOutlined, 
    LocalShippingOutlined,
    ReportProblemOutlined 
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    Tab,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";
import { mockL2SimeDarbyData } from "data/mockDataProfile";
import { mockSimeDarbyDistribute } from "data/mockDistributeData";
import { Formik } from "formik";
import MapCompany from "modules/maps/companies/MapCompany";
import StatBoxCompany from "modules/StatBoxCompany";
import DistributeSupplyTree from "modules/trees/DistributeSupplyTree";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ReceiveSupplyTree from "modules/trees/ReceiveSupplyTree";
import { mockTreeOrg } from "data/mockTree";

const CompanyProfile = () => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min_width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const [valueProfile, setValueProfile] = useState('1');
    const [networkValueProfile, setNetworkValueProfile] = useState('1');

    const url = useLocation();
    const split = url.pathname.split('/')
    const id = split[split.length - 1]

    const handleProfileChange = (event, newValue) => {
        setValueProfile(newValue);
    }

    const handleNetworkValueProfileChange = (event, newValue) => {
        setNetworkValueProfile(newValue);
    }

    const emptyData = [];
    const columns = [
        {
            field: "stock_order_id",
            headerName: "ID",
            flex: 1,
        },
        {
            field: "company_name",
            headerName: "Company Name",
            flex: 1,
        },
        {
            field: "state",
            headerName: "State",
            flex: 1,
        },
        {
            field: "total_distribution",
            headerName: "Total Distribution",
            flex: 1,
        },
        {
            field: "unit_type",
            headerName: "Unit Type",
            flex: 1,
        },
        {
            field: "brand_name",
            headerName: "Brand",
            flex: 1,
        },
        {
            field: "total_sales",
            headerName: "Total Sales",
            flex: 1,
        },
        {
            field: "delivery_status",
            headerName: "Delivery Status",
            flex: 1,
        },
        {
            field: "delivery_date",
            headerName: "Date Delivery",
            flex: 1,
        },
        {
            field: "delivery_time",
            headerName: "Time Delivery",
            flex: 1,
        },
        {
            field: "receive_date",
            headerName: "Date Receive",
            flex: 1,
        },
        {
            field: "receive_time",
            headerName: "Time Receive",
            flex: 1,
        }
    ]

    const CompanyProfileDetails = () => { 
        return (
            <Formik
                initialValues={mockL2SimeDarbyData}
            >
                {({
                    values
                }) => (
                    <form>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem",
                            }}
                        >
                            <CardHeader
                                title={mockL2SimeDarbyData.company_name}
                                subheader="Identification Information"
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="ID"
                                            name="unique_id"
                                            value={values.unique_id}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Level"
                                            name="company_level"
                                            value={values.company_level}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Category"
                                            name="company_category"
                                            value={values.company_category}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Business"
                                            name="company_business"
                                            value={values.company_business}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Business Operation"
                                            name="company_business_operation"
                                            value={values.company_business_operation}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardHeader subheader="Company Details"/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="SSM"
                                            name="company_ssm"
                                            value={values.company_ssm}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="CSA License"
                                            name="company_license"
                                            value={values.company_license}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Start CSA License"
                                            name="company_start_csa_license"
                                            value={values.company_start_csa_license}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="End CSA License"
                                            name="company_end_csa_license"
                                            value={values.company_end_csa_license}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            multiline
                                            label="Address"
                                            name="company_address"
                                            value={values.company_address}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            multiline
                                            label="State"
                                            name="state_name"
                                            value={values.state_name}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            multiline
                                            label="District"
                                            name="district_name"
                                            value={values.district_name}
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    const CompanyOwnerDetails = () => {
        return (
            <Formik
                initialValues={mockL2SimeDarbyData.owners}
            >
                {({
                    values
                }) => (
                    <form>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem",
                            }}
                        >
                            <CardHeader
                                title="Company Owner"
                                subheader="List of Owners"
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    mt="10px"
                                >
                                    {values.map(
                                        ({
                                            oid,
                                            name,
                                            email,
                                            phone_no,
                                        }) => (
                                            <FlexBetween mt="10px" ml="20px" key={oid}>
                                                <Avatar
                                                    src=""
                                                    sx={{
                                                        width: 60,
                                                        height: 60
                                                    }}
                                                />
                                                <Box ml="20px" width="100%">
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Name"
                                                        name="name"
                                                        value={name}
                                                        type="text"
                                                        color="secondary"
                                                        inputProps={
                                                            {readOnly: true}
                                                        }
                                                        margin='dense'
                                                    />

                                                    <Box>
                                                        <FlexBetween>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                label="Email"
                                                                name="email"
                                                                value={email}
                                                                type="text"
                                                                color="secondary"
                                                                inputProps={
                                                                    {readOnly: true}
                                                                }
                                                                margin='dense'
                                                            />

                                                            <Button
                                                                sx={{
                                                                    backgroundColor: theme.palette.secondary.light,
                                                                    color: theme.palette.background.alt,
                                                                    fontSize: "12px",
                                                                    fontWeight: "bold",
                                                                    margin: "10px 5px 5px",
                                                                    padding: "5px 20px"
                                                                }}
                                                            >
                                                                <EmailOutlined sx={{ mr: "10px" }}/>
                                                                Email
                                                            </Button>
                                                        </FlexBetween>
                                                    </Box>

                                                    <Box>
                                                        <FlexBetween>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                label="Phone Number"
                                                                name="phone_no"
                                                                value={phone_no}
                                                                type="text"
                                                                color="secondary"
                                                                inputProps={
                                                                    {readOnly: true}
                                                                }
                                                                margin='dense'
                                                            />
                                                            <Button
                                                                sx={{
                                                                    backgroundColor: theme.palette.secondary.light,
                                                                    color: theme.palette.background.alt,
                                                                    fontSize: "12px",
                                                                    fontWeight: "bold",
                                                                    margin: "10px 5px 5px",
                                                                    padding: "5px 20px"
                                                                }}
                                                            >
                                                                <CallOutlined sx={{ mr: "10px" }}/>
                                                                Call
                                                            </Button>
                                                        </FlexBetween>
                                                    </Box>
                                                </Box>
                                            </FlexBetween>
                                        )
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title={mockL2SimeDarbyData.company_name} subtitle={mockL2SimeDarbyData.company_category}/>
                <Box>
                    <Button
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        <DownloadOutlined sx={{ mr: "10px" }}/>
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                {/* ROW 1 */}
                <StatBox
                    title="Total Issue Occur"
                    value={mockL2SimeDarbyData.total_issue}
                    description="Daily issue occurence"
                    icon={
                        <ReportProblemOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBoxCompany
                    title="Total Metric Tons"
                    value={mockL2SimeDarbyData.total_metric_ton}
                    description1="Quota"
                    quota={mockL2SimeDarbyData.total_metric_ton_quota}
                    description2="Production"
                    icon={
                        mockL2SimeDarbyData.metric_tan_quota_exceed ? (
                            <ErrorOutlineOutlined sx={{ color: "red", fontSize: "26px" }}/>
                        ) : (
                            <CheckCircleOutline sx={{ color: "green", fontSize: "26px" }}/>
                        )
                        
                    }
                />
                <StatBoxCompany
                    title="Total Packaging"
                    value={mockL2SimeDarbyData.total_package}
                    description1="Quota"
                    quota={mockL2SimeDarbyData.total_package_quota}
                    description2="Production"
                    icon={
                        mockL2SimeDarbyData.package_quota_exceed ? (
                            <ErrorOutlineOutlined sx={{ color: "red", fontSize: "26px" }}/>
                        ) : (
                            <CheckCircleOutline sx={{ color: "green", fontSize: "26px" }}/>
                        )
                        
                    }
                />
                <StatBoxCompany
                    title="Total Distribution"
                    value={mockL2SimeDarbyData.total_distribution}
                    description1="Quota"
                    quota={mockL2SimeDarbyData.total_distribution_quota}
                    description2="Production"
                    icon={
                        mockL2SimeDarbyData.distribution_quota_exceed ? (
                            <ErrorOutlineOutlined sx={{ color: "red", fontSize: "26px" }}/>
                        ) : (
                            <CheckCircleOutline sx={{ color: "green", fontSize: "26px" }}/>
                        )
                        
                    }
                />
            </Box>

            {/* ROW 2 */}
            <Box
                gridColumn="span 12"
                gridRow="span 3"
            >
                <TabContext value={valueProfile}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList 
                            variant="fullWidth" 
                            onChange={handleProfileChange} 
                            textColor="inherit" 
                            indicatorColor="secondary"
                        >
                            <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Distribution" value="1"/>
                            {/* <Tab icon={<AttachMoneyOutlined/>} iconPosition="start" label="Transaction" value="2"/> */}
                            {/*<Tab icon={<ReportProblemOutlined/>} iconPosition="start" label="Issues" value="3"/> */}
                            <Tab icon={<CorporateFareOutlined/>} iconPosition="start" label="Profile" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box height="100%" width="100%">
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold"
                                }}
                                color={theme.palette.secondary[300]}
                                gutterBottom
                            >
                                Distribution Geo Location
                            </Typography>
                            <MapCompany items={mockL2SimeDarbyData.distribution} position={mockL2SimeDarbyData.position}/>
                        </Box>
                        <Box
                            mt="60px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold"
                                }}
                                color={theme.palette.secondary[300]}
                                gutterBottom
                            >
                                Distribution Activity
                            </Typography>
                            <DataGrid
                                getRowId={(row) => row.stock_order_id}
                                rows={mockSimeDarbyDistribute?.length ? mockSimeDarbyDistribute : []}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </Box>
                        <Box
                            mt="40px"
                        >
                            <TabContext value={networkValueProfile}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleNetworkValueProfileChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Distributions" value="1"/>
                                        <Tab icon={<CallReceivedOutlined/>} iconPosition="start" label="Receives" value="2"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Card
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.alt,
                                            borderRadius: "0.55rem",
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold"
                                                }}
                                                color={theme.palette.secondary[300]}
                                                gutterBottom
                                            >
                                                Distribution Network Flow
                                            </Typography>
                                            <Box mt="20px">
                                                <DistributeSupplyTree data={mockTreeOrg}/>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Card
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.alt,
                                            borderRadius: "0.55rem"
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold"
                                                }}
                                                color={theme.palette.secondary[300]}
                                                gutterBottom
                                            >
                                                Receives Network Flow
                                            </Typography>
                                            <Box mt="20px">
                                                <ReceiveSupplyTree data={mockTreeOrg}/>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <Container maxWidth="lg">
                                <Grid
                                    item
                                    lg={5}
                                    md={6}
                                    xs={12}
                                    mb={5}
                                >
                                    <CompanyProfileDetails/>
                                </Grid>
                                <Grid
                                    item
                                    lg={5}
                                    md={6}
                                    xs={12}
                                    mt={5}
                                >
                                    <CompanyOwnerDetails/>
                                </Grid>
                            </Container>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    );
}

export default CompanyProfile;