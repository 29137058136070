export const mockTreeOrg = {
  "id": 1,
  "source": "parent",
  "name": "Sime Darby Oils Langat Refinery Sdn. Bhd.",
  "description": "Sime Darby Oils Langat Refinery company details",
  "csa_license": "AB10001506",
  "state": "Selangor",
  "status": "Critical",
  "color": "red",
  "attributes": {
    "total_stock_in": "100,000",
    "total_stock_out": "25,000",
    "total_stock": "21,000",
    "total_stock_receive": "14,670",
    "total_stock_distribute": "15,000",
    "stock_description": "Sime Darby Oils Langat Refinery stock details",
    "receive_description": "Sime Darby Oils Langat Refinery receive details",
    "distribution_description": "Sime Darby Oils Langat Refinery distribution details",
  },
  "children": [
      {
        "id": 2,
        "source": "subParent",
        "name": "Asri Vegetable Oil Products Sdn. Bhd.",
        "description": "Asri Vegetable Oil Product Sdn. Bhd. company details",
        "csa_license": "AB12746D",
        "state": "Selangor",
        "status": "Complain",
        "color": "Yellow",
        "attributes": {
            "total_stock_in": "0",
            "total_stock_out": "215,00",
            "total_stock": "0",
            "total_stock_request": "1000",
            "total_stock_receive": "0",
            "total_stock_distribute": "4000",
            "request_description": "Asri Vegetable Oil Product Sdn. Bhd. request details",
            "distribution_description": "Asri Vegetable Oil Product Sdn. Bhd. distribution details",
            "stock_description": "Asri Vegetable Oil Product Sdn. Bhd. stock details",
        },
        "children": [
          {
            "id": 4,
            "source": "children",
            "name": "Sierra Makmur Sdn. Bhd.",
            "description": "Sierra Makmur Sdn. Bhd. company details",
            "csa_license": "AB91445",
            "state": "Selangor",
            "status": "Good",
            "color": "green",
            "attributes": {
              "total_stock_in": "0",
              "total_stock_out": "345,100",
              "total_stock": "4000",
              "total_stock_request": "4000",
              "total_stock_receive": "0",
              "request_description": "Sierra Makmur Sdn. Bhd. request details",
              "stock_description": "Sierra Makmur Sdn. Bhd. stock details"
            },
            "children": []
          }
        ]
      },
      {
        "id": 3,
        "source": "children",
        "name": "Ikira Sdn. Bhd.",
        "description": "Ikira Sdn. Bhd.",
        "csa_license": "AB91582",
        "state": "Selangor",
        "status": "Good",
        "color": "green",
        "attributes": {
          "total_stock_in": "0",
          "total_stock_out": "14,100",
          "total_stock": "0",
          "total_stock_receive": "1700",
          "total_stock_request": "1700",
          "request_description": "Ikira Sdn. Bhd. request activities",
          "stock_description": "Ikira Sdn. Bhd. stock activities"
        },
        "children": []
      }
  ]
}