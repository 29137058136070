import { network } from "../network";

const url_order = `${network.domain_api}/order`;

/**
 * Get order data
 * 
 * Get @returns order data
 */
export const getOrder = async() => {
    let response
    try {
        response = await fetch(`${url_order}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get order state
 * 
 * @param {*} param0 order state id
 * @returns order state data
 */
export const getOrderState = async ({sid}) => {
    let response
    try {
        response = await fetch(`${url_order}/states/${sid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get order district data
 * 
 * @param {*} param0 order district id
 * @returns order district data
 */
export const getOrderDistrict = async ({sid, did}) => {
    let response
    try {
        response = await fetch(`${url_order}/states/district/${sid}/${did}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}