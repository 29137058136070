import { useEffect, useState} from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    Box, 
    Button,
    Dialog, 
    DialogTitle, 
    DialogContent, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery,
    useTheme,
    CardHeader,
    CardContent,
    Grid,
    TextField,
    Card,
    Divider,
    InputLabel,
    Select,
    CardActions
} from "@mui/material";

import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab"

import {
    DownloadOutlined,
    OilBarrelOutlined,
    FactoryOutlined,
    BusinessCenterOutlined,
    LocalShippingOutlined,
    AddOutlined,
    CloseOutlined,
    Inventory2Outlined,
    CheckOutlined,
} from "@mui/icons-material"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

import PropTypes from "prop-types";
import { Container, styled } from "@mui/system";
import * as yup from "yup";

import {
    getCompany, 
    getCompanies,
    addCompany, 
    deleteCompany, 
    updateCompany, 
    getCompanyDoc
} from "state/fetch/companies/companyApi"

import MapCompany from "modules/maps/companies/MapCompany";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";

import StatesCompanyStat from "modules/statesDistricts/companies/StatesCompanyStat";

import { Formik,  } from "formik";
import dayjs from 'dayjs';
import { getStateDistrictNames } from "state/fetch/states/statesApi";
import { getSingleGeocoding } from "state/fetch/geos/geocodeApi";
import { getData, storeData } from "state/stores/useAsyncStorage";

const BootstrapDialog = styled(Dialog) (({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const Company = ({isDashboard = false}) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min_width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    // open close dialog box
    const [openAddCompany, setOpenAddCompany] = useState(false);
    const [openUpdateCompany, setOpenUpdateCompany] = useState(false);

    // set status
    const [status, setStatus] = useState(100)
    const [statusMessage, setStatusMessage] = useState('Continue')
    const [message, setMessage] = useState('')

    // total companies
    const [totalProducer, setTotalProducer] = useState(0);
    const [totalPackager, setTotalPackager] = useState(0);
    const [totalWholesaler, setTotalWholesaler] = useState(0);
    const [totalRetailer, setTotalRetailer] = useState(0);

    // company data
    const [producerCompany, setProducerCompany] = useState([]);
    const [packagerCompany, setPackagerCompany] = useState([]);
    const [wholesalerCompany, setWholesalerCompany] = useState([]);
    const [retailerCompany, setRetailerCompany] = useState([]);

    // company state data
    const [companyStates, setCompanyStates] = useState([]);

    // region data
    const [region, setRegion] = useState({})

    // states and districts data
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])

    // company data
    const [companyData, setCompanyData] = useState({});

    // input value
    const [inputCompanyName, setInputCompanyName] = useState("")
    const [inputCompanyAddress, setInputCompanyAddress] = useState("")
    const [inputCompanySSM, setInputCompanySSM] = useState("")
    const [inputCompanyLicense, setInputCompanyLicense] = useState("")
    const [inputCompanyBusiness, setInputCompanyBusiness] = useState("")
    const [inputCompanyLatitude, setCompanyLatitude] = useState(0.0)
    const [inputCompanyLongitude, setCompanyLongitude] = useState(0.0)

    // state input value
    const [inputStateName, setInputStateName] = useState("");

    // dialog box handle open add company
    const handleOpenAddCompany = () => {
        setOpenAddCompany(true);
    }

    const handleCloseAddCompany = () => {
        setOpenAddCompany(false);
        clearStorage()
    }

    const handleOpenUpdateCompany = () => {
        setOpenUpdateCompany(true);
    }

    const handleCloseUpdateCompany = () => {
        setOpenUpdateCompany(false);
    }

    // Tabbing handle change
    const [value, setValue] = useState('1')    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const clearStorage = () => {
        storeData("company_name", "")
        storeData("company_address", "")
        storeData("company_ssm", "")
        storeData("company_license", "")
        storeData("company_business", "")
        storeData("company_latitude", 0.0)
        storeData("company_longitude", 0.0)
    }

    useEffect(() => {
        getCompanies().then((result) => {

            // initialize data
            setTotalProducer(result.total_producer);
            setTotalPackager(result.total_packager);
            setTotalWholesaler(result.total_wholesaler);
            setTotalRetailer(result.total_retailer);

            setProducerCompany(result.producers);
            setPackagerCompany(result.packagers);
            setWholesalerCompany(result.wholesalers);
            setRetailerCompany(result.retailers);

            setCompanyStates(result.company_states);

            setRegion({
                center: result.center,
                zoom: result.zoom
            })

            setStates(result.states)
            setDistricts(result.districts)
        })

        clearStorage()
    }, [])

    // Get list of districts
    const getDistricts = (state) => {
        switch(state) {
            case'Johor':
                getStateDistrictNames({sid: 'MY-01'}).then((result) => {
                    setDistricts(result);
                })
            break;
            case'Kedah':
                getStateDistrictNames({sid: 'MY-02'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case'Kelantan':
                getStateDistrictNames({sid: 'MY-03'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Melaka':
                getStateDistrictNames({sid: 'MY-04'}).then((result) => {
                    setDistricts(result);
                })
                break
            case 'Negeri Sembilan':
                getStateDistrictNames({sid: 'MY-05'}).then((result) => {
                    setDistricts(result);
                })
                break
            case'Pahang':
                getStateDistrictNames({sid: 'MY-06'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case'Perak':
                getStateDistrictNames({sid: 'MY-08'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case'Perlis':
                getStateDistrictNames({sid: 'MY-09'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Pulau Pinang':
                getStateDistrictNames({sid: 'MY-07'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case'Sabah':
                getStateDistrictNames({sid: 'MY-12'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Sarawak':
                getStateDistrictNames({sid: 'MY-13'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Selangor':
                getStateDistrictNames({sid: 'MY-10'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Terengganu':
                getStateDistrictNames({sid: 'MY-11'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Wilayah Persekutuan Kuala Lumpur':
                getStateDistrictNames({sid: 'MY-14'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Wilayah Persekutuan Labuan': 
                getStateDistrictNames({sid: 'MY-15'}).then((result) => {
                    setDistricts(result);
                })
                break;
            case 'Wilayah Persekutuan Putrajaya':
                getStateDistrictNames({sid: 'MY-16'}).then((result) => {
                    setDistricts(result);
                })
                break;
        }
    }

    // Datagrid columns
    const emptyData = [];

    const columns = [
        {
            field: "unique_id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 180
        },
        {
            field: "company_name",
            headerName: "Company Name",
            headerAlign: "center",
            align: "center",
            width: 300
        },
        {
            field: "company_ssm",
            headerName: "SSM",
            headerAlign: "center",
            align: "center",
            width: 180
        },
        {
            field: "company_license",
            headerName: "CSA License",
            headerAlign: "center",
            align: "center",
            width: 180
        },
        {
            field: 'state_name',
            headerName: 'State',
            headerAlign: "center",
            align: "center",
            width: 120
        },
        {
            field: 'district_name',
            headerName: 'District',
            headerAlign: "center",
            align: "center",
            width: 120
        },
        {
            field: 'company_category',
            headerName: 'Category',
            headerAlign: "center",
            align: "center",
            width: 140
        }
    ];

    const manageColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{ 
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: '100%'
                        }}
                        onClick={() => {
                            navigate(`${params.row.unique_id}`)
                        }}
                    >
                        <Typography sx={{ fontSize: "12px",fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                // get company 
                                getCompany({unique_id: params.row.unique_id}).then((result) => {
                                    // initialize data
                                    setCompanyData(result);
                                })

                                // handle update company
                                handleOpenUpdateCompany()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                    </Box>
                );
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: '100%'
                        }}
                        onClick={() => {
                            // delete company
                            deleteCompany({unique_id: params.row.unique_id, cid: params.row.cid})
        
                            // handle refresh page
                            handleRefreshPage()
                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            Delete
                        </Typography>
                    </Button>
                )
            }
        }
    ];

    const [anchorElL1, setAnchorElL1] = useState(null);
    const [anchorElL2, setAnchorElL2] = useState(null);
    const [anchorElL3, setAnchorElL3] = useState(null);
    const [anchorElL4, setAnchorElL4] = useState(null);

    const [valueL1, setValueL1] = useState('');
    const [valueL2, setValueL2] = useState('');
    const [valueL3, setValueL3] = useState('');
    const [valueL4, setValueL4] = useState('');

    const openL1 = Boolean(anchorElL1);
    const openL2 = Boolean(anchorElL2);
    const openL3 = Boolean(anchorElL3);
    const openL4 = Boolean(anchorElL4);

    // producer popper
    const handlePopperOpenL1 = (event) => {
        const id = event.currentTarget.dataset.id;
        const row = producerCompany.find((r) => r.id === id);
        setValueL1(row);
        setAnchorElL1(event.currentTarget);
    }

    const handlePopperCloseL1 = () => {
        setAnchorElL1(null);
    }

    // packager popper
    const handlePopperOpenL2 = (event) => {
        const id = event.currentTarget.dataset.id;
        const row = packagerCompany.find((r) => r.id === id);
        setValueL2(row);
        setAnchorElL2(event.currentTarget);
    }

    const handlePopperCloseL2 = () => {
        setAnchorElL2(null);
    }

    // wholesaler popper
    const handlePopperOpenL3 = (event) => {
        const id = event.currentTarget.dataset.id;
        const row = wholesalerCompany.find((r) => r.id === id);
        setValueL3(row);
        setAnchorElL3(event.currentTarget);
    }

    const handlePopperCloseL3 = () => {
        setAnchorElL3(null);
    }

    // retailer popper
    const handlePopperOpenL4 = (event) => {
        const id = event.currentTarget.dataset.id;
        const row = retailerCompany.find((r) => r.id === id);
        setValueL4(row);
        setAnchorElL4(event.currentTarget);
    }

    const handlePopperCloseL4 = () => {
        setAnchorElL4(null);
    }

    // refresh page
    const handleRefreshPage = () => {
        window.location.reload(false);
    }

    // add company dialog box
    const AddCompany = ({level}) => {
        const current_date = new Date();

        let operation
        switch(level) {
            case 1:
                operation = 'Manufacturing'
                break
            case 2:
                operation = 'Packaging'
                break
            case 3:
                operation = 'Distribution'
                break
            case 4:
                operation = 'Retailing'
                break
        }

        const companyBusiness = [
            "Sole Proprietorship",
            "Partnership",
            "Private Limited Company",
            "Public Limited Company",
            "Unlimited Companies",
            "Foreign Company",
            "Limited Liability Parnership"
        ]

        // Get stored data (to prevent form reinitialize)
        getData("company_name").then((result) => {setInputCompanyName(result)})
        getData("company_address").then((result) => {setInputCompanyAddress(result)})
        getData("company_ssm").then((result) => {setInputCompanySSM(result)})
        getData("company_license").then((result) => {setInputCompanyLicense(result)})
        getData("company_business").then((result) => {setInputCompanyBusiness(result)})
        getData("company_latitude").then((result) => {setCompanyLatitude(result)})
        getData("company_longitude").then((result) => {setCompanyLongitude(result)})

        const initialCompany = {
            company_name: inputCompanyName,
            company_address: inputCompanyAddress,
            state_name: inputStateName,
            district_name: districts[0],
            company_ssm: inputCompanySSM,
            company_license: inputCompanyLicense,
            company_business: companyBusiness[0],
            company_business_operation: operation,
            company_start_csa_license: dayjs(`${current_date.getFullYear()}-${current_date.getMonth() + 1}-${current_date.getDate()}`),
            company_end_csa_license: dayjs(`${current_date.getFullYear()}-${current_date.getMonth() + 1}-${current_date.getDate()}`),
            company_latitude: inputCompanyLatitude,
            company_longitude: inputCompanyLongitude
        }

        const checkoutSchema = yup.object().shape({
            company_name: yup.string().required("required"),
            company_address: yup.string().required("required"),
            company_ssm: yup.string().required("required"),
            company_license: yup.string().required("required"),
            company_business_operation: yup.string().required("required"),
            company_start_csa_license: yup.string().required("required"),
            company_end_csa_license: yup.string().required("required"),
            company_latitude: yup.number().required("required"),
            company_longitude: yup.number().required("required")
        })

        const handleSubmit = (values) => {
            // reconstruct json data
            let data = {}
            switch(level) {
                case 1:
                    data = {
                        unique_id: '',
                        company_level: level,
                        company_category: 'Producer',
                        company_name: values.company_name,
                        company_address: values.company_address,
                        state_name: inputStateName,
                        state_category: '',
                        district_name: values.district_name,
                        company_ssm: values.company_ssm,
                        company_license: values.company_license,
                        company_business: values.company_business,
                        company_business_operation: values.company_business_operation,
                        company_start_csa_license: values.company_start_csa_license,
                        company_end_csa_license: values.company_end_csa_license,
                        company_latitude: values.company_latitude,
                        company_longitude: values.company_longitude,
                        company_date_register: '',
                        company_time_register: ''
                    }

                    // add company
                    addCompany(data).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    });
                    break;
                case 2:
                    data = {
                        unique_id: '',
                        company_level: level,
                        company_category: 'Packager',
                        company_name: values.company_name,
                        company_address: values.company_address,
                        state_name: inputStateName,
                        state_category: '',
                        district_name: values.district_name,
                        company_ssm: values.company_ssm,
                        company_license: values.company_license,
                        company_business: values.company_business,
                        company_business_operation: values.company_business_operation,
                        company_start_csa_license: values.company_start_csa_license,
                        company_end_csa_license: values.company_end_csa_license,
                        company_latitude: values.company_latitude,
                        company_longitude: values.company_longitude,
                        company_date_register: '',
                        company_time_register: ''
                    }

                    // add company
                    addCompany(data).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    });
                    break;
                case 3:
                    data = {
                        unique_id: '',
                        company_level: level,
                        company_category: 'Wholesaler',
                        company_name: values.company_name,
                        company_address: values.company_address,
                        state_name: inputStateName,
                        state_category: '',
                        district_name: values.district_name,
                        company_ssm: values.company_ssm,
                        company_license: values.company_license,
                        company_business: values.company_business,
                        company_business_operation: values.company_business_operation,
                        company_start_csa_license: values.company_start_csa_license,
                        company_end_csa_license: values.company_end_csa_license,
                        company_latitude: values.company_latitude,
                        company_longitude: values.company_longitude,
                        company_date_register: '',
                        company_time_register: ''
                    }

                    // add company
                    addCompany(data).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    });
                    break;
                case 4:
                    data = {
                        unique_id: '',
                        company_level: level,
                        company_category: 'Retailer',
                        company_name: values.company_name,
                        company_address: values.company_address,
                        state_name: inputStateName,
                        state_category: '',
                        district_name: values.district_name,
                        company_ssm: values.company_ssm,
                        company_license: values.company_license,
                        company_business: values.company_business,
                        company_business_operation: values.company_business_operation,
                        company_start_csa_license: values.company_start_csa_license,
                        company_end_csa_license: values.company_end_csa_license,
                        company_latitude: values.company_latitude,
                        company_longitude: values.company_longitude,
                        company_date_register: '',
                        company_time_register: ''
                    }

                    // add company
                    addCompany(data).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    });
                    break;
            }
            console.log(data)

            // handle close add company
            handleCloseAddCompany()

            // refresh page
            handleRefreshPage()
        }

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialCompany}
                validationSchema={checkoutSchema}
                enableReinitialize={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title="Register Company "
                                subheader="Register Company Details"
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_name"
                                            label="Company Name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("company_name", e.target.value)
                                                storeData("company_name", e.target.value)
                                            }}
                                            value={values.company_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_name && !!errors.company_name}
                                            helperText={touched.company_name && errors.company_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_address"
                                            label="Address"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("company_address", e.target.value)
                                                storeData("company_address", e.target.value)
                                            }}
                                            value={values.company_address}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_address && !!errors.company_address}
                                            helperText={touched.company_address && errors.company_address}
                                            color="secondary"
                                            required
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <InputLabel>State</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={inputStateName}
                                            name="state_name"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                getDistricts(e.target.value)
                                                setInputStateName(e.target.value)
                                            }}
                                            color="secondary"
                                            inputProps={{
                                                name: "state_name",
                                                id: "filled-roles-native"
                                            }}
                                            required
                                        >
                                            {states.map((state) => (
                                                <option key={state} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <InputLabel>District</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={values.district_name}
                                            name="district_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            color="secondary"
                                            inputProps={{
                                                name: "district_name",
                                                id: "filled-roles-native"
                                            }}
                                            required
                                        >
                                            {districts.map((district) => (
                                                <option key={district} value={district}>
                                                    {district}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_ssm"
                                            label="Company SSM"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("company_ssm", e.target.value)
                                                storeData("company_ssm", e.target.value)
                                            }}
                                            value={values.company_ssm}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_ssm && !!errors.company_ssm}
                                            helperText={touched.company_ssm && errors.company_ssm}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_license"
                                            label="Company License"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("company_license", e.target.value)
                                                storeData("company_license", e.target.value)
                                            }}
                                            value={values.company_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_license && !!errors.company_license}
                                            helperText={touched.company_license && errors.company_license}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <InputLabel>Business</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={values.company_business}
                                            name="company_business"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("company_business", e.target.value)
                                                storeData("company_business", e.target.value)
                                            }}
                                            color="secondary"
                                            inputProps={{
                                                name: "company_business",
                                                id: "filled-roles-native"
                                            }}
                                            required
                                        >
                                            {companyBusiness.map((business) => (
                                                <option key={business} value={business}>
                                                    {business}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        mt={2.5}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_business_operation"
                                            label="Business Operation"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_business_operation}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_business_operation && !!errors.company_business_operation}
                                            helperText={touched.company_business_operation && errors.company_business_operation}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <DatePicker
                                                label="Start CSA License"
                                                value={values.company_start_csa_license}
                                                onChange={(value) => setFieldValue("company_start_csa_license", value, true)}
                                                format="DD-MM-YYYY"
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        name="company_start_csa_license"
                                                        error={!!touched.company_start_csa_license && !!errors.company_start_csa_license}
                                                        helperText={touched.company_start_csa_license && errors.company_start_csa_license}
                                                        variant="outlined"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <DatePicker
                                                label="End CSA License"
                                                value={values.company_end_csa_license}
                                                onChange={(value) => setFieldValue("company_end_csa_license", value, true)}
                                                format="DD-MM-YYYY"
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        name="company_end_csa_license"
                                                        error={!!touched.company_end_csa_license && !!errors.company_end_csa_license}
                                                        helperText={touched.company_end_csa_license && errors.company_end_csa_license}
                                                        variant="outlined"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_latitude"
                                            label="Latitude"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("company_latitude", e.target.value)
                                                storeData("company_latitude", e.target.value)
                                            }}
                                            value={values.company_latitude}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.company_latitude && !!errors.company_latitude}
                                            helperText={touched.company_latitude && errors.company_latitude}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_longitude"
                                            label="Longitude"
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue("company_longitude", e.target.value)
                                                storeData("company_longitude", e.target.value)
                                            }}
                                            value={values.company_longitude}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.company_longitude && !!errors.company_longitude}
                                            helperText={touched.company_longitude && errors.company_longitude}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    onClick={() => {
                                        getSingleGeocoding({ query_company_name: values.company_name, query_address: values.company_address }).then((result) => {
                                            setFieldValue("company_latitude", result.latitude)
                                            storeData("company_latitude", result.latitude)

                                            setFieldValue("company_longitude", result.longitude)
                                            storeData("company_longitude", result.longitude)
                                        })
                                    }}
                                    sx={{ 
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        width: "100%" 
                                    }}
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        Geolocate
                                    </Typography>
                                </Button>
                            </CardActions>
                            <Divider/>
                            <CardContent>
                                <Box mt="10px" display="flex" flexDirection="row-reverse" gap={2}>
                                    <Button
                                        onClick={() => {
                                            handleCloseAddCompany();
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CloseOutlined sx={{ mr: "10px" }}/>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CheckOutlined sx={{ mr: "10px" }}/>
                                        Confirm
                                    </Button>
                                    <BootstrapDialog

                                    >

                                    </BootstrapDialog>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    // edit company dialog box
    const EditCompany = ({data}) => {

        const companyBusiness = [
            "Sole Proprietorship",
            "Partnership",
            "Private Limited Company",
            "Public Limited Company",
            "Unlimited Companies",
            "Foreign Company",
            "Limited Liability Parnership"
        ]

        const initialCompany = {
            company_id: data.cid,
            company_unique_id: data.unique_id,
            company_name: data.company_name,
            company_address: data.company_address,
            state_name: data.state_name,
            district_name: data.district_name,
            company_ssm: data.company_ssm,
            company_license: data.company_license,
            company_business: data.company_business,
            company_business_operation: data.company_business_operation,
            company_start_csa_license: dayjs(data.company_start_csa_license),
            company_end_csa_license: dayjs(data.company_end_csa_license),
            company_latitude: data.company_latitude,
            company_longitude: data.company_longitude
        }

        const checkoutSchema = yup.object().shape({
            company_name: yup.string().required("required"),
            company_address: yup.string().required("required"),
            state_name: yup.string().required("required"),
            district_name: yup.string().required("required"),
            company_ssm: yup.string().required("required"),
            company_license: yup.string().required("required"),
            company_business: yup.string().required("required"),
            company_business_operation: yup.string().required("required"),
            company_start_csa_license: yup.string().required("required"),
            company_end_csa_license: yup.string().required("required"),
            company_latitude: yup.number().required("required"),
            company_longitude: yup.number().required("required") 
        })

        const handleSubmit = (values) => {
            // reconstruct json data
            let result = {
                company_level: data.company_level,
                company_category: data.company_category,
                company_name: values.company_name,
                company_address: values.company_address,
                state_name: values.state_name,
                state_category: data.state_category,
                district_name: values.district_name,
                company_ssm: values.company_ssm,
                company_license: values.company_license,
                company_business: values.company_business,
                company_business_operation: values.company_business_operation,
                company_start_csa_license: values.company_start_csa_license,
                company_end_csa_license: values.company_end_csa_license,
                company_latitude: values.company_latitude,
                company_longitude: values.company_longitude,
                company_date_register: data.company_date_register,
                company_time_register: data.company_time_register
            }

            // update company
            updateCompany({
                unique_id: data.unique_id, 
                cid: data.cid, 
                state_name: values.state_name, 
                district_name: values.district_name, 
                data: result}).then((result) => {
                    setStatus(result.status_code)
                    setStatusMessage(result.status_message)
                    setMessage(result.message)
                });

            // handle close update company
            handleCloseUpdateCompany()

            // refresh page
            handleRefreshPage()
        }

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialCompany}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title="Update Company"
                                subheader={data.unique_id}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_name"
                                            label="Company Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_name && !!errors.company_name}
                                            helperText={touched.company_name && errors.company_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_address"
                                            label="Address"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_address}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_address && !!errors.company_address}
                                            helperText={touched.company_address && errors.company_address}
                                            color="secondary"
                                            required
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="state_name"
                                            label="State"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.state_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.state_name && !!errors.state_name}
                                            helperText={touched.state_name && errors.state_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="district_name"
                                            label="District"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.district_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.district_name && !!errors.district_name}
                                            helperText={touched.district_name && errors.district_name}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_ssm"
                                            label="Company SSM"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_ssm}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_ssm && !!errors.company_ssm}
                                            helperText={touched.company_ssm && errors.company_ssm}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_license"
                                            label="Company License"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_license && !!errors.company_license}
                                            helperText={touched.company_license && errors.company_license}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <InputLabel>Business</InputLabel>
                                        <Select
                                            fullWidth
                                            native
                                            value={values.company_business}
                                            name="company_business"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            color="secondary"
                                            inputProps={{
                                                name: "company_business",
                                                id: "filled-roles-native"
                                            }}
                                            required
                                        >
                                            {companyBusiness.map((business) => (
                                                <option key={business} value={business}>
                                                    {business}
                                                </option>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                        mt={2.5}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_business_operation"
                                            label="Business Operation"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_business_operation}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_business_operation && !!errors.company_business_operation}
                                            helperText={touched.company_business_operation && errors.company_business_operation}
                                            color="secondary"
                                            required
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <DatePicker
                                                label="Start CSA License"
                                                value={values.company_start_csa_license}
                                                onChange={(value) => setFieldValue("company_start_csa_license", value, true)}
                                                format="DD-MM-YYYY"
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        name="company_start_csa_license"
                                                        error={!!touched.company_start_csa_license && !!errors.company_start_csa_license}
                                                        helperText={touched.company_start_csa_license && errors.company_start_csa_license}
                                                        variant="outlined"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={6}
                                            xs={12}
                                        >
                                            <DatePicker
                                                label="End CSA License"
                                                value={values.company_end_csa_license}
                                                onChange={(value) => setFieldValue("company_end_csa_license", value, true)}
                                                format="DD-MM-YYYY"
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        name="company_end_csa_license"
                                                        error={!!touched.company_end_csa_license && !!errors.company_end_csa_license}
                                                        helperText={touched.company_end_csa_license && errors.company_end_csa_license}
                                                        variant="outlined"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_latitude"
                                            label="Latitude"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_latitude}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.company_latitude && !!errors.company_latitude}
                                            helperText={touched.company_latitude && errors.company_latitude}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_longitude"
                                            label="Longitude"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_longitude}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.company_longitude && !!errors.company_longitude}
                                            helperText={touched.company_longitude && errors.company_longitude}
                                            color="secondary"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    onClick={() => {
                                        getSingleGeocoding({ query_company_name: values.company_name, query_address: values.company_address }).then((result) => {
                                            setFieldValue("company_latitude", result.latitude)
                                            setFieldValue("company_longitude", result.longitude)
                                        })
                                    }}
                                    sx={{ 
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        width: "100%" 
                                    }}
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        Geolocate
                                    </Typography>
                                </Button>
                            </CardActions>
                            <Divider/>
                            <CardContent>
                                <Box mt="10px" display="flex" flexDirection="row-reverse" gap={2}>
                                    <Button
                                        onClick={() => {
                                            handleCloseUpdateCompany();
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CloseOutlined sx={{ mr: "10px" }}/>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CheckOutlined sx={{ mr: "10px" }}/>
                                        Confirm
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Company Dashboard" subtitle="List of Registered Company"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                onClick={() => {
                                    getCompanyDoc()
                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                {/* ROW 1 */}
                <StatBox
                    title="Producer"
                    value={totalProducer}
                    description="Total Company"
                    icon={
                        <OilBarrelOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                        />
                    }
                />
                <StatBox
                    title="Packager"
                    value={totalPackager}
                    description="Total Company"
                    icon={
                        <FactoryOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Wholesaler"
                    value={totalWholesaler}
                    description="Total Company"
                    icon={
                        <LocalShippingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Retailer"
                    value={totalRetailer}
                    description="Total Company"
                    icon={
                        <BusinessCenterOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                        />
                    }
                />

                {/* ROW 2 */}
                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                variant="fullWidth"
                                onChange={handleChange}
                                textColor="inherit"
                                indicatorColor="secondary">
                                    <Tab icon={<FactoryOutlined/>} iconPosition="start" label="Producer (Level 1)" value="1"/>
                                    <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Packager (Level 2)" value="2"/>
                                    <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Wholesaler (Level 3)" value="3"/>
                                    <Tab icon={<BusinessCenterOutlined/>} iconPosition="start" label="Retailer (Level 4)" value="4"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box height="100%" width="100%">
                                <MapCompany items={producerCompany} position={region}/>
                            </Box>
                            <Box mt="40px"
                                display="flex"
                                flexDirection="row-reverse"
                            >
                                <Button 
                                    onClick={() => {
                                        handleOpenAddCompany()
                                    }}
                                    size="small"
                                    variant="contained"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px"
                                    }}
                                >
                                    <AddOutlined sx={{ mr: "10px" }}/>
                                    Add Company
                                </Button>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openAddCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseAddCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Add Company Producer
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <AddCompany level={1}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openUpdateCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseUpdateCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Edit Company
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <EditCompany data={companyData}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.unique_id}
                                    rows={producerCompany?.length ? producerCompany : emptyData}
                                    columns={columns.concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    componentsProps={{
                                        row: {
                                            onMouseEnter: handlePopperOpenL1,
                                            onMouseLeave: handlePopperCloseL1
                                        }
                                    }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box height="100%" width="100%">
                                <MapCompany items={packagerCompany} position={region}/>
                            </Box>
                            <Box mt="40px"
                                display="flex"
                                flexDirection="row-reverse"
                            >
                                <Button 
                                    onClick={() => {
                                        handleOpenAddCompany()
                                    }}
                                    size="small"
                                    variant="contained"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px"
                                    }}
                                >
                                    <AddOutlined sx={{ mr: "10px" }}/>
                                    Add Company
                                </Button>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openAddCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseAddCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Add Company Packager
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <AddCompany level={2}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openUpdateCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseUpdateCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Edit Company
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <EditCompany data={companyData}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.unique_id}
                                    rows={packagerCompany?.length ? packagerCompany : emptyData}
                                    columns={columns.concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    componentsProps={{
                                        row: {
                                            onMouseEnter: handlePopperOpenL2,
                                            onMouseLeave: handlePopperCloseL2
                                        }
                                    }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Box height="100%" width="100%">
                                <MapCompany items={wholesalerCompany} position={region}/>
                            </Box>
                            <Box mt="40px"
                                display="flex"
                                flexDirection="row-reverse"
                            >
                                <Button 
                                    onClick={() => {
                                        handleOpenAddCompany()
                                    }}
                                    size="small"
                                    variant="contained"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px"
                                    }}
                                >
                                    <AddOutlined sx={{ mr: "10px" }}/>
                                    Add Company
                                </Button>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openAddCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseAddCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Add Company Wholesaler
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <AddCompany level={3}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openUpdateCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseUpdateCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Edit Company
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <EditCompany data={companyData}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.unique_id}
                                    rows={wholesalerCompany?.length ? wholesalerCompany : emptyData}
                                    columns={columns.concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    componentsProps={{
                                        row: {
                                            onMouseEnter: handlePopperOpenL3,
                                            onMouseLeave: handlePopperCloseL3
                                        }
                                    }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="4">
                            <Box height="100%" width="100%">
                                <MapCompany items={retailerCompany} position={region}/>
                            </Box>
                            <Box mt="40px"
                                display="flex"
                                flexDirection="row-reverse"
                            >
                                <Button 
                                    onClick={() => {
                                        handleOpenAddCompany()
                                    }}
                                    size="small"
                                    variant="contained"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px"
                                    }}
                                >
                                    <AddOutlined sx={{ mr: "10px" }}/>
                                    Add Company
                                </Button>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openAddCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseAddCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Add Company Retailer
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <AddCompany level={4}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                                <BootstrapDialog
                                    aria-labelledby="view-dialog-title"
                                    open={openUpdateCompany}
                                >
                                    <BootstrapDialogTitle
                                        id="view-dialog-title"
                                        onClose={handleCloseUpdateCompany}
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                            Edit Company
                                        </Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent
                                        dividers
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                    >
                                        <Box
                                            component="main"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            <Container maxWidth="lg">
                                                <EditCompany data={companyData}/>
                                            </Container>
                                        </Box>
                                    </DialogContent>
                                </BootstrapDialog>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.unique_id}
                                    rows={retailerCompany?.length ? retailerCompany : emptyData}
                                    columns={columns.concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                    componentsProps={{
                                        row: {
                                            onMouseEnter: handlePopperOpenL4,
                                            onMouseLeave: handlePopperCloseL4
                                        }
                                    }}
                                />
                            </Box>
                        </TabPanel>
                    </TabContext>
                    <Box
                        mt="20px"
                        display="grid"
                        gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                        justifyContent="space-between"
                        rowGap="20px"
                        columnGap="1.33%"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        {companyStates.map(
                            ({
                                sid,
                                state_name,
                                state_category,
                                total_company,
                                description,
                                total_issues,
                                consist_issue,
                                total_producer_company,
                                total_packager_company,
                                total_wholesaler_company,
                                total_retailer_company
                            }) => (
                                <StatesCompanyStat
                                    key={sid}
                                    sid={sid}
                                    state_category={state_category}
                                    state_name={state_name}
                                    total_company={total_company}
                                    description={description}
                                    total_issues={total_issues}
                                    consist_issue={consist_issue}
                                    total_producer_company={total_producer_company}
                                    total_packager_company={total_packager_company}
                                    total_wholesaler_company={total_wholesaler_company}
                                    total_retailer_company={total_retailer_company}
                                />
                            )
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Company;