import { ErrorOutlineOutlined }  from "@mui/icons-material";
import { useTheme, Card, CardContent, Typography, TextField, CardActions, Button, Collapse, CardHeader, Divider } from "@mui/material";
import { useState } from "react";
import FlexBetween from "modules/FlexBetween";
import { useNavigate } from "react-router-dom";

const DistrictsOrderStat = ({
    sid,
    did,
    district_name,
    total_company,
    description,
    total_issues,
    consist_issue,
    total_receive_daily_order,
    total_receive_daily_pending_order,
    total_receive_daily_shipped_order,
    total_receive_daily_delivered_order,
    total_receive_daily_paid_order,
    total_receive_daily_reject_order,
    total_receive_monthly_order,
    total_receive_monthly_pending_order,
    total_receive_monthly_shipped_order,
    total_receive_monthly_delivered_order,
    total_receive_monthly_paid_order,
    total_receive_monthly_reject_order,
    total_receive_yearly_order,
    total_receive_yearly_pending_order,
    total_receive_yearly_shipped_order,
    total_receive_yearly_delivered_order,
    total_receive_yearly_paid_order,
    total_receive_yearly_reject_order
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem"
            }}
        >
            <CardContent>
                <FlexBetween>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold"
                        }}
                        color={theme.palette.secondary[400]}
                        glutterBottom
                    >
                        {district_name} ({did})
                    </Typography>
                    {
                        (consist_issue ? (
                            <ErrorOutlineOutlined sx={{ color: "red", fontSize: "26px" }}/>
                        ) : (
                            <></>
                        ))
                    }
                </FlexBetween>
                <Typography sx={{mb: "1.5rem"}} color={theme.palette.secondary[400]}>
                    Total Company : {total_company}
                </Typography>
                <TextField
                    size="small"
                    label="Total Issues Occurs"
                    value={total_issues}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    sx={{
                        mb: "20px"
                    }}
                />
                <Typography variant="body2">{description}</Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant="primary"
                    size="small"
                    sx={{
                        width: "100%"
                    }}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See More
                </Button>
            </CardActions>
            <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300]
                }}
            >
                <CardContent>
                    <Typography
                        variant="body2"
                        color={theme.palette.secondary[400]}
                        sx={{mb: "0.5rem"}}
                    >
                        Daily Receive Order Report
                    </Typography>
                    <Divider sx={{ mb: "0.5rem" }}/>
                    <TextField
                        label= "Total Order"
                        value={total_receive_daily_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Pending Order"
                        value={total_receive_daily_pending_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Shipped Order"
                        value={total_receive_daily_shipped_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Delivered Order"
                        value={total_receive_daily_delivered_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Paid Order"
                        value={total_receive_daily_paid_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Total Reject Order"
                        value={total_receive_daily_reject_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <Divider sx={{ mb: "0.5rem" }}/>
                    <Typography
                        variant="body2"
                        color={theme.palette.secondary[400]}
                        sx={{mb: "0.5rem"}}
                    >
                        Monthly Receive Order Report
                    </Typography>
                    <TextField
                        label= "Total Order"
                        value={total_receive_monthly_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Pending Order"
                        value={total_receive_monthly_pending_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Shipped Order"
                        value={total_receive_monthly_shipped_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Delivered Order"
                        value={total_receive_monthly_delivered_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Paid Order"
                        value={total_receive_monthly_paid_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Reject Order"
                        value={total_receive_monthly_reject_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <Divider sx={{ mb: "0.5rem" }}/>
                    <Typography
                        variant="body2"
                        color={theme.palette.secondary[400]}
                        sx={{ mb: "0.5rem" }}
                    >
                        Yearly Receive Sales Report
                    </Typography>
                    <Divider sx={{ mb: "0.5rem" }}/>
                    <TextField
                        label= "Total Order"
                        value={total_receive_yearly_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Pending Order"
                        value={total_receive_yearly_pending_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Shipped Order"
                        value={total_receive_yearly_shipped_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Delivered Order"
                        value={total_receive_yearly_delivered_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Paid Order"
                        value={total_receive_yearly_paid_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label= "Total Reject Order"
                        value={total_receive_yearly_reject_order}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                </CardContent>
            </Collapse>
            <CardActions>
                <Button
                    variant="contained"
                    size="small"
                    color="info"
                    onClick={() => {
                        navigate(`/orders/districts/${sid}/${did}`)
                    }}
                    sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "12px",
                        width: "100%"
                    }}
                >
                    <Typography sx={{ fontWeight: "bold" }}>
                        Navigate
                    </Typography>
                </Button>
            </CardActions>
        </Card>
    );
}

export default DistrictsOrderStat;