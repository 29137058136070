export const consistIssue = {
    consist_issue: true
}

export const mockL1States = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: 'MY-10',
        state_name: 'Selangor',
        state_category: "State",
        total_company: 1,
        description: "Overall producer report in states of Selangor",
        total_issues: 1,
        consist_issue: true,
        total_metric_ton_per_day: "109,340",
        total_metric_ton_per_month: "678,900",
        total_metric_ton_per_year: "789,900",
        total_sales: "RM134,500",
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall producer report in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall producer report in federal territory of Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall producer report in federal territory of Labuan",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall producer report in federal territory of Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton_per_day: "0",
        total_metric_ton_per_month: "0",
        total_metric_ton_per_year: "0",
        total_sales: "RM0"
    }
];

export const mockL2States = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_metric_ton: 0,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Pahang",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-10",
        state_name: "Selangor",
        state_category: "State",
        total_company: 10,
        description: "Overall packager report report in states of Selangor",
        total_issues: 1,
        consist_issue: true,
        total_packaging_per_day: "123,010",
        total_packaging_per_month: "465,500",
        total_packaging_per_year: "910,000",
        total_sales: "RM457,890"
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 10,
        description: "Overall packager report report in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"   
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall packager report report in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"   
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan Kuala Lumpur",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall packager report report in states of Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"   
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall packager report report in federal territory of Labuan",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "Federal Territory",
        total_company: 0,
        description: "Overall packager report report in federal territory of Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_packaging_per_day: "0",
        total_packaging_per_month: "0",
        total_packaging_per_year: "0",
        total_sales: "RM0"
    }
];

export const mockL3States = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Kelantan",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_distribution: 0,
        total_sales: "RM0"
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Pahang",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-09",
        state_name: "Perlis",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Perlis",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-10",
        state_name: "Selangor",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Selangor",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan Kuala Lumpur",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Wilayah Persekutuan Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Wilayah Persekutuan Labuan",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "State",
        total_company: 0,
        description: "Overall daily distributor report in states of Wilayah Persekutuan Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_distribution_per_day: "0",
        total_distribution_per_month: "0",
        total_distribution_per_year: "0",
        total_sales: "RM0"
    }
];

export const mockL4States = [
    {
        sid: "MY-01",
        state_name: "Johor",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Johor",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-02",
        state_name: "Kedah",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-03",
        state_name: "Kelantan",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Kedah",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-04",
        state_name: "Melaka",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Melaka",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-05",
        state_name: "Negeri Sembilan",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Negeri Sembilan",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-06",
        state_name: "Pahang",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Pahang",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-07",
        state_name: "Pulau Pinang",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Pulau Pinang",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-08",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-09",
        state_name: "Perak",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Perak",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-10",
        state_name: "Selangor",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Selangor",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-11",
        state_name: "Terengganu",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Terengganu",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold: 0,
        total_sales: "RM0"
    },
    {
        sid: "MY-12",
        state_name: "Sabah",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Sabah",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-13",
        state_name: "Sarawak",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Sarawak",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-14",
        state_name: "Wilayah Persekutuan Kuala Lumpur",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Wilayah Persekutuan Kuala Lumpur",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-15",
        state_name: "Wilayah Persekutuan Labuan",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Wilayah Persekutuan Labuan",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    },
    {
        sid: "MY-16",
        state_name: "Wilayah Persekutuan Putrajaya",
        state_category: "State",
        total_company: 0,
        description: "Overall daily retailer report in states of Wilayah Persekutuan Putrajaya",
        total_issues: 0,
        consist_issue: false,
        total_unit_sold_per_day: "0",
        total_unit_sold_per_month: "0",
        total_unit_sold_per_year: "0",
        total_sales: "RM0"
    }
];

export const producerCompany = [];

export const packagerCompany = [
    {
        cid: 1,
        unique_id: '1427735-A-AB10001506',
        company_name: 'Sime Darby Oils Langat Refinery Sdn. Bhd.',
        company_ssm: '1427735-A',
        company_license: 'AB10001506',
        state: 'Selangor',
        district: 'Klang',
        category: 'Factory & Packager',
        latitude: 2.9216833,
        longitude: 101.4740182,
    },
    {
        cid: 2,
        unique_id: '640575-D-AB12746D',
        company_name: 'Asri Vegetable Oil Products Sdn. Bhd.',
        company_ssm: '640575-D',
        company_license: 'AB12746D',
        state: 'Selangor',
        district: 'Klang',
        category: 'Packager',
        latitude: 2.991521,
        longitude: 101.410645,
    },
    {
        cid: 3,
        unique_id: '881359-P-AB91445',
        company_name: 'Sierra Makmur Sdn. Bhd.',
        company_ssm: '881359-P',
        company_license: 'AB91445',
        state: 'Selangor',
        district: 'Petaling Jaya',
        category: 'Packager',
        latitude: 3.1744438,
        longitude: 101.5301019,
    },
    {
        cid: 4,
        unique_id: '498207-K-AB91582',
        company_name: 'Ikira Sdn. Bhd.',
        company_ssm: '498207-K',
        company_license: 'AB91582',
        state: 'Selangor',
        district: 'Hulu Langat',
        category: 'Packager',
        latitude: 2.977167,
        longitude: 101.7579021,
    },
    {
        cid: 5,
        unique_id: '953565-M-AB94669',
        company_name: 'Sinar Merak Sdn. Bhd.',
        company_ssm: '953565-M',
        company_license: 'AB94669',
        state: 'Selangor',
        district: 'Petaling Jaya',
        category: 'Packager',
        latitude: 3.0421223,
        longitude: 101.6998132,
    },
    {
        cid: 6,
        unique_id: '1064595-P-AB96034',
        company_name: 'Matrix Oil (M) Sdn. Bhd.',
        company_ssm: '1064595-P',
        company_license: 'AB96034',
        state: 'Selangor',
        district: 'Hulu Langat',
        category: 'Packager',
        latitude: 2.9819004,
        longitude: 101.8774546,
    },
    {
        cid: 7,
        unique_id: '971863-K-AB10001057',
        company_name: 'Alfa Consortium Sdn. Bhd.',
        company_ssm: '971863-K',
        company_license: 'AB10001057',
        state: 'Selangor',
        district: 'Kuala Langat',
        category: 'Packager',
        latitude: 2.8506698,
        longitude: 101.5200555,
    },
    {
        cid: 8,
        unique_id: '1029448-W-AB14000012',
        company_name: 'Pocket Trade Sdn. Bhd.',
        company_ssm: '1029448-W',
        company_license: 'AB14000012',
        state: 'Selangor',
        district: 'Hulu Langat',
        category: 'Packager',
        latitude: 2.9892555,
        longitude: 101.5167344,
    },
    {
        cid: 9,
        unique_id: '898624-K-AB10001050',
        company_name: 'Fieldman Sdn. Bhd.',
        company_ssm: '898624-K',
        company_license: 'AB10001050',
        state: 'Selangor',
        district: 'Klang',
        category: 'Packager',
        latitude: 3.0707551,
        longitude: 101.4128785,
    },
    {
        cid: 10,
        unique_id: '832177-M-AB16000018',
        company_name: 'Global Speciality Ingredients (M) Sdn. Bhd.',
        company_ssm: '832177-M',
        company_license: 'AB16000018',
        state: 'Selangor',
        district: 'Klang',
        category: 'Packager',
        latitude: 3.0912998,
        longitude: 101.3940158,
    }
];

export const wholesalerCompany = [

];

export const retailerCompany = [

];