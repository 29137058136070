import { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    Box, 
    Button,
    DialogTitle, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery,
    useTheme} from "@mui/material";

import {
    DownloadOutlined,
    Inventory2Outlined,
    MonetizationOnOutlined,
    ReceiptOutlined,
    CalendarTodayOutlined,
    CalendarMonthOutlined,
    ReportProblemOutlined,
    CheckOutlined,
    ClearOutlined,
    ShowChartOutlined,
    InventoryOutlined,
    AddOutlined
} from "@mui/icons-material"

import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab"

import PropTypes from "prop-types"
import { useNavigate } from "react-router";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import SearchBar from "components/SearchBar";
import SaleRawItem from "modules/items/sales/SaleRawItem";
import SaleItem from "modules/items/sales/SaleItem";
import StatProgressBox from "components/stats/StatProgressBox";
import { percentage, progressRate } from "utils/maths";
import BarChartSalesDaily from "components/barcharts/sales/BarChartSalesDaily";
import BarChartSalesMonthly from "components/barcharts/sales/BarChartSalesMonthly";
import BarChartSalesYearly from "components/barcharts/sales/BarChartSalesYearly";

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const UserSale = ({ isDashboard = false, isProducer = false }) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min_width: 1000px)")

    // tabbing handle change
    const [value, setValue] = useState('1')
    const [valueBarChart, setValueBarChart] = useState('1')

    const navigate = useNavigate();

    // handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleBarChartChange = (event, newValue) => {
        setValueBarChart(newValue);
    }
    
    const [totalIssue, setTotalIssue] = useState(0) 
    const [totalSaleRevenue, setTotalSaleRevenue] = useState(0)

    const [totalRegisteredItemToSale, setTotalRegisteredItemToSale] = useState(0)
    const [totalRegisteredUnitToSale, setTotalRegisteredUnitToSale] = useState(0)
    const [totalRemainingUnitToSale, setTotalRemainingUnitToSale] = useState(0)
    const [totalAvailableItemToSale, setTotalAvailableItemToSale] = useState(0)
    const [totalOutOfStock, setTotalOutOfStock] = useState(0);

    const [saleRawStockOrder, setRawSaleStockOrder] = useState([])
    const [saleRawStockOrderFiltered, setSaleRawStockOrderFiltered] = useState([])
    const [saleStockOrder, setSaleStockOrder] = useState([])
    const [saleStockOrderFiltered, setSaleStockOrderFiltered] = useState([])

    // unit sold and sales
    const [totalDailyUnitSold, setTotalDailyUnitSold] = useState(0);
    const [totalDailySales, setTotalDailySales] = useState(0);
    const [totalMonthlyUnitSold, setTotalMonthlyUnitSold] = useState(0);
    const [totalMonthlySales, setTotalMonthlySales] = useState(0);
    const [totalYearlyUnitSold, setTotalYearlyUnitSold] = useState(0);
    const [totalYearlySales, setTotalYearlySales] = useState(0);

    const [searchSaleItem, setSearchSaleItem] = useState("");

    useEffect(() => {

    }, [])

    const emptyData = [];

    const saleItemColumn = ({ isProducer = false }) => {
        let columns = []
        if(isProducer) {
            columns = [
                {
                    field: "sale_stock_id",
                    headerName: "ID",
                    headerAlign: 'center',
                    width: 100
                },
                {
                    field: "product_stock_id",
                    headerName: "Product ID",
                    headerAlign: 'center',
                    width: 100
                },
                {
                    field: "wid",
                    headerName: "Warehouse ID",
                    headerAlign: 'center',
                    width: 100
                },
                {
                    field: "item_name",
                    headerName: "Item Name",
                    headerAlign: 'center',
                    width: 120
                },
                {
                    field: "price",
                    headerName: "Price (MYR)",
                    headerAlign: 'center',
                    width: 120
                },
                {
                    field: "unit",
                    headerName: "Unit (MT)",
                    headerAlign: 'center',
                    width: 120,
                },
                {
                    field: "sale_date_register",
                    headerName: "Sale Date Register",
                    headerAlign: 'center',
                    width: 120
                },
                {
                    field: 'sale_time_register',
                    headerName: "Sale Time Register",
                    headerAlign: 'center',
                    width: 120
                }
            ]

            return columns;
        } else {
            columns = [
                {
                    field: 'sale_stock_id',
                    headerName: "ID",
                    headerAlign: 'center',
                    width: 100
                },
                {
                    field: 'product_stock_id',
                    headerName: "Product ID",
                    headerAlign: 'center',
                    width: 100
                },
                {
                    field: "product_name",
                    headerName: "Product Name",
                    headerAlign: 'center',
                    width: 140
                },
                {
                    field: "product_brand_name",
                    headerName: "Product Brand Name",
                    headerAlign: 'center',
                    width: 140
                },
                {
                    field: "product_serial_no",
                    headerName: "Serial No",
                    headerAlign: 'center',
                    width: 120
                },
                {
                    field: "product_sku",
                    headerName: "Product SKU",
                    headerAlign: 'center',
                    width: 120
                },
                {
                    field: "product_package",
                    headerName: "Product Package",
                    headerAlign: 'center',
                    width: 120
                },
                {
                    field: "product_category",
                    headerName: "Product Category",
                    headerAlign: 'center',
                    width: 120
                },
                {
                    field: "price",
                    headerName: "Price (MYR)",
                    headerAlign: 'center',
                    wdith: 120
                },
                {
                    field: "unit",
                    headerName: "Unit",
                    headerAlign: 'center',
                    width: 120
                }
            ]

            return columns;
        }
    }

    const saleChartColumn = ({ isProducer = false }) => {
        let columns = []
        if(isProducer) {
            columns = [
                {
                    field: "stock_order_id",
                    headerName: "ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "invoice_id",
                    headerName: "Invoice",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "recipient_company_name",
                    headerName: "Company Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "item_name",
                    headerName: "Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_order",
                    headerName: "Total Order",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_price",
                    headerName: "Total Price",
                    heaedrAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_amount",
                    headerName: "Total Amount",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "payment_status",
                    headerName: "Payment Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "invoice_date",
                    headerName: "Invoice Date",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                },
                {
                    field: "invoice_time",
                    headerName: "Invoice Time",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                },
                {
                    field: "remark",
                    headerName: "Remark",
                    headerAlign: "center",
                    align: "left",
                    width: 200
                }
            ]

            return columns;
        } else {
            columns = [
                {
                    field: "stock_order_id",
                    headerName: "ID",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "invoice_id",
                    headerName: "Invoice",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "recipient_company_name",
                    headerName: "Company Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_name",
                    headerName: "Product Name",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_brand_name",
                    headerName: "Brand",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_serial_no",
                    headerName: "Serial No",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_sku",
                    headerName: "SKU",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_category",
                    headerName: "Category",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "product_package",
                    headerName: "Package",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_order",
                    headerName: "Total Order",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_price",
                    heaerName: "Total Price",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "total_amount",
                    headerName: "Total Amount",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "payment_status",
                    headerName: "Payment Status",
                    headerAlign: "center",
                    align: "center",
                    width: 180
                },
                {
                    field: "invoice_date",
                    headerName: "Invoice Date",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                },
                {
                    field: "invoice_time",
                    headerName: "Invoice Time",
                    headerAlign: "center",
                    align: "center",
                    width: 100
                },
                {
                    field: "remark",
                    headerName: "Remark",
                    headerAlign: "center",
                    align: "left",
                    width: 200
                }
            ]

            return columns
        }
        
    }

    const manageColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                    variant="contained"
                    color="info"
                    size="small"
                    sx={{
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.background.alt,
                        fontSize: "12px",
                        width: '100%'
                    }}
                    onClick={() => {

                    }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        }
    ];

    const handleSearchSaleItem = () => {
        if(isProducer) {
            if(searchSaleItem) {
                const newItem =  saleRawStockOrder.filter(item => {
                    const itemData = item.item_name ? item.item_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchSaleItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setSaleRawStockOrderFiltered(newItem);
            } else {
                console.log(searchSaleItem);
                setSaleRawStockOrderFiltered(saleRawStockOrder)
            }
        } else {
            if(searchSaleItem) {
                const newItem =  saleStockOrder.filter(item => {
                    const itemData = item.product_name ? item.product_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchSaleItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setSaleStockOrderFiltered(newItem);
            } else {
                setSaleStockOrderFiltered(saleStockOrder)
            }
        }        
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Sale Report" subtitle="Sales Report Activity"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                onClick={() => {

                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                {/* ROW 1 */}
                <StatBox
                    title="Total Issue"
                    value={totalIssue}
                    description="Issue(s)"
                    icon={
                        <ReportProblemOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Sale Revenue"
                    value={totalSaleRevenue}
                    description="Sale Revenue"
                    icon={
                        <MonetizationOnOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
            </Box>
            <Box
                mt="20px"
                gridColumn="span 12"
                gridRow="span 3"
            >
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            variant="fullWidth"
                            onChange={handleChange}
                            textColor="inherit"
                            indicatorColor="secondary"
                        >
                            <Tab icon={<MonetizationOnOutlined/>} iconPosition="start" label="Sale Item" value="1"/>
                            <Tab icon={<ShowChartOutlined/>} iconPosition="start" label="Sale Report" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box
                            mt="10px"
                            mb="20px"
                            display="grid"
                            gridTemplateColumns="repeat(10, 1fr)"
                            gridAutoRows="160px"
                            gap="20px"
                            sx={{
                                "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                            }}
                        >
                            <StatBox
                                title="Total Registered Item"
                                value={totalRegisteredItemToSale}
                                description="Registered Item(s)"
                                icon={
                                    <MonetizationOnOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatBox
                                title="Total Selling Unit"
                                value={totalRegisteredUnitToSale}
                                description="Selling Unit(s)"
                                icon={
                                    <Inventory2Outlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatProgressBox
                                title="Total Remaining Units"
                                value={totalRemainingUnitToSale}
                                rate={`${percentage(totalRemainingUnitToSale, totalRegisteredUnitToSale)}%`}
                                progress={progressRate(totalRemainingUnitToSale, totalRegisteredUnitToSale)}
                                description="Remaining Unit(s)"
                                icon={
                                    <InventoryOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatProgressBox
                                title="Total Available Items"
                                value={totalAvailableItemToSale}
                                rate={`${percentage(totalAvailableItemToSale, totalRegisteredItemToSale)}%`}
                                progress={progressRate(totalAvailableItemToSale, totalRegisteredItemToSale)}
                                description="Available Item(s)"
                                icon={
                                    <CheckOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                            <StatProgressBox
                                title="Total Item Out of Stocks"
                                value={totalOutOfStock}
                                rate={`${percentage(totalOutOfStock, totalRegisteredItemToSale)}%`}
                                progress={progressRate(totalOutOfStock, totalRegisteredItemToSale)}
                                description="Item Out of Stock(s)"
                                icon={
                                    <ClearOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                    />
                                }
                            />
                        </Box>
                        <Box mt="20px">
                            <FlexBetween>
                                <SearchBar
                                    placeholder="Search Item"
                                    value={searchSaleItem}
                                    onChange={newValue => setSearchSaleItem(newValue)}
                                    onSearch={handleSearchSaleItem}
                                />
                                <Box>
                                    <Button
                                        onClick={() => {
                                        }}
                                        variant="contained"
                                        color="info"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <AddOutlined sx={{ mr: "10px" }}/>
                                        Sale Declaration
                                    </Button>
                                </Box>
                            </FlexBetween>
                        </Box>
                        {
                            isProducer ? (
                                <Box
                                    mt="20px"
                                    display="grid"
                                    gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                    justifyContent="space-between"
                                    rowGap="20px"
                                    columnGap="1.33%"
                                    height="60vh"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        overflow: "hidden",
                                        overflowY: "scroll"
                                    }}
                                >
                                    {saleRawStockOrderFiltered.map(
                                        ({
                                            sale_stock_id,
                                            item_name,
                                            price,
                                            unit,
                                            sale_status,
                                            sale_date_register,
                                            sale_time_register,
                                            sale_description
                                        }) => (
                                            <SaleRawItem
                                                key={sale_stock_id}
                                                sale_stock_id={sale_stock_id}
                                                item_name={item_name}
                                                price={price}
                                                unit={unit}
                                                sale_status={sale_status}
                                                sale_date_register={sale_date_register}
                                                sale_time_register={sale_time_register}
                                                sale_description={sale_description}
                                            />
                                        )
                                    )}
                                </Box>
                            ) : (
                                <Box
                                    mt="20px"
                                    display="grid"
                                    gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                    justifyContent="space-between"
                                    rowGap="20px"
                                    columnGap="1.33%"
                                    height="60vh"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        overflow: "hidden",
                                        overflowY: "scroll"
                                    }}
                                >
                                    {saleStockOrderFiltered.map(
                                        ({
                                            sale_stock_id,
                                            product_name,
                                            product_brand_name,
                                            product_category,
                                            product_package,
                                            price,
                                            unit,
                                            sale_status,
                                            product_serial_no,
                                            product_sku,
                                            sale_date_register,
                                            sale_time_register,
                                            sale_description
                                        }) => (
                                            <SaleItem
                                                key={sale_stock_id}
                                                sale_stock_id={sale_stock_id}
                                                product_name={product_name}
                                                product_brand_name={product_brand_name}
                                                product_category={product_category}
                                                product_package={product_package}
                                                price={price}
                                                unit={unit}
                                                sale_status={sale_status}
                                                product_serial_no={product_serial_no}
                                                product_sku={product_sku}
                                                sale_date_register={sale_date_register}
                                                sale_time_register={sale_time_register}
                                                sale_description={sale_description}
                                            />
                                        )
                                    )}
                                </Box>
                            )
                        }
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.stock_order_id}
                                rows={emptyData}
                                columns={saleItemColumn({ isProducer: false }).concat(manageColumn)}
                                components={{ Toolbar: QuickSearchToolbar }}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box
                            mt="10px"
                            mb="20px"
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridAutoRows="160px"
                            gap="20px"
                            sx={{
                                "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                            }}
                        >
                            <Box
                                gridColumn="span 12"
                                gridRow="span 3"
                            >
                                <TabContext value={valueBarChart}>
                                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                        <TabList
                                            variant="fullWidth"
                                            onChange={handleBarChartChange}
                                            textColor="inherit"
                                            indicatorColor="secondary"
                                        >
                                            <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                            <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                            <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <Box
                                            p="1.25rem 1rem"
                                            height="60vh"
                                            backgroundColor={theme.palette.background.alt}
                                            borderRadius="0.55rem"
                                        >
                                            <BarChartSalesDaily isProducer={isProducer}/>
                                        </Box>
                                        <Box
                                            mt="10px"
                                            mb="20px"
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            gridAutoRows="160px"
                                            gap="20px"
                                            sx={{
                                                "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                            }}
                                        >
                                            <StatBox
                                                title={isProducer ? "Total Unit Sold (MT)" : "Total Unit Sold"}
                                                value={totalDailyUnitSold}
                                                description="Daily Unit Sold"
                                                icon={
                                                    <ReceiptOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Sales (MYYR)"
                                                value={totalDailySales}
                                                description="Daily Sales"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Average Unit Sold"
                                                value={0}
                                                description="Daily Average Unit Sold"
                                                icon={
                                                    <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Average Sales (MYR)"
                                                value={0}
                                                description="Daily Average Sales"
                                                icon={
                                                    <ReceiptOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Profit Before Tax (MYR)"
                                                value={0}
                                                description="Daily Profit Before Tax"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Profit After Tax (MYR)"
                                                value={0}
                                                description="Daily Profit After Tax"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Box
                                            p="1.25rem 1rem"
                                            height="60vh"
                                            backgroundColor={theme.palette.background.alt}
                                            borderRadius="0.55rem"
                                        >
                                            <BarChartSalesMonthly isProducer={isProducer}/>
                                        </Box>
                                        <Box
                                            mt="10px"
                                            mb="20px"
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            gridAutoRows="160px"
                                            gap="20px"
                                            sx={{
                                                "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                            }}
                                        >
                                            <StatBox
                                                title={isProducer ? "Total Unit Sold (MT)" : "Total Unit Sold"}
                                                value={totalMonthlyUnitSold}
                                                description="Monthly Unit Sold"
                                                icon={
                                                    <ReceiptOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Sales (MYYR)"
                                                value={totalMonthlySales}
                                                description="Monthly Sales"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Average Unit Sold"
                                                value={0}
                                                description="Monthly Average Unit Sold"
                                                icon={
                                                    <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Average Sales (MYR)"
                                                value={0}
                                                description="Monthly Average Sales"
                                                icon={
                                                    <ReceiptOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Profit Before Tax (MYR)"
                                                value={0}
                                                description="Monthly Profit Before Tax"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Profit After Tax (MYR)"
                                                value={0}
                                                description="Monthly Profit After Tax"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Box
                                            p="1.25rem 1rem"
                                            height="60vh"
                                            backgroundColor={theme.palette.background.alt}
                                            borderRadius="0.55rem"
                                        >
                                            <BarChartSalesYearly isProducer={isProducer}/>
                                        </Box>
                                        <Box
                                            mt="10px"
                                            mb="20px"
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            gridAutoRows="160px"
                                            gap="20px"
                                            sx={{
                                                "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                            }}
                                        >
                                            <StatBox
                                                title={isProducer ? "Total Unit Sold (MT)" : "Total Unit Sold"}
                                                value={totalYearlyUnitSold}
                                                description="Yearly Unit Sold"
                                                icon={
                                                    <ReceiptOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Sales (MYYR)"
                                                value={totalYearlySales}
                                                description="Yearly Sales"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Average Unit Sold"
                                                value={0}
                                                description="Yearly Average Unit Sold"
                                                icon={
                                                    <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Average Sales (MYR)"
                                                value={0}
                                                description="Yearly Average Sales"
                                                icon={
                                                    <ReceiptOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Profit Before Tax (MYR)"
                                                value={0}
                                                description="Yearly Profit Before Tax"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                            <StatBox
                                                title="Total Profit After Tax (MYR)"
                                                value={0}
                                                description="Yearly Profit After Tax"
                                                icon={
                                                    <MonetizationOnOutlined
                                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                    />
                                                }
                                            />
                                        </Box>
                                    </TabPanel>
                                </TabContext>
                                <Box
                                    mt="10px"
                                    height="60vh"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            borderRadius: "5rem"
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: theme.palette.background.alt,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${theme.palette.secondary[200]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.stock_order_id}
                                        rows={emptyData}
                                        columns={saleChartColumn({ isProducer: {isProducer}}).concat(manageColumn)}
                                        components={{ Toolbar: QuickSearchToolbar }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    )
}

export default UserSale;