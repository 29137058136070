import { 
    Avatar, 
    Box, 
    Button, 
    Container, 
    Grid, 
    TextField, 
    Typography, 
    useTheme 
} from "@mui/material";

import logoImage from "assets/kpdn-logo.png"
import { Link } from "react-router-dom";

const ForgotPassword = () => {
    const theme = useTheme();

    const Copyright = (props) => {
        return (
            <Typography variant="body2" align="center" {...props}>
                Copyright © {new Date().getFullYear()} Dataran Teknologi Sdn. Bhd. All Rights Reserved.
            </Typography>
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email')
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Avatar
                    src={logoImage}
                    sx={{
                        width: "120px",
                        height: "120px",
                        m: 1,
                    }}
                />
                <Typography
                    mt="10px" 
                    component="h1" 
                    align="center"
                    variant="h5"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Kementerian Perdangangan Dalam Negeri & Kos Sara Hidup
                </Typography>
                <Typography 
                    mt="10px"
                    component="h1" 
                    align="center"
                    variant="h6"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Logistic Interface Computive Automation (LICA)
                </Typography>
                <Typography
                    component="h1" 
                    align="center"
                    variant="h6"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Cooking Oil Monitoring System
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        color="secondary"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ 
                            mt: 3, 
                            mb: 2,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        Submit
                    </Button>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Link to="/kpdn" variant="body2" style={{ color: theme.palette.secondary.light }}>
                                Already remember password? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4}} />
        </Container>
    );
}

export default ForgotPassword;