import { 
    Box, 
    Button, 
    Card, 
    CardContent, 
    CardHeader, 
    Container, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    Divider, 
    Grid, 
    IconButton, 
    TextField, 
    Typography, 
    useTheme 
} from "@mui/material";

import { mockTreeOrg } from "data/mockTree";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./utils/helper";

import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { CloseOutlined } from "@mui/icons-material";
import { Formik } from "formik";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    }
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

const ReceiveSupplyTree = ({data}) => {
    const theme = useTheme();
    const [id, setId] = useState(null);
    const [open, setOpen] = useState(false);
    const [dimensions, translate, containerRef] = useCenteredTree();
    const nodeSize = { x: 210, y: 180 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 60, y: -45 };

    const handleOpen = (id) => {
        setId(id);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const CompanyReceivesRootDetails = ({data}) => {
        const initialValue = {
            uid: data.id,
            name: data.name,
            description: data.description,
            status: data.status,
            license: data.csa_license,
            state: data.state,
            district: data.district,
            total_stock_in: data.attributes.total_stock_in,
            total_stock_out: data.attributes.total_stock_out,
            total_stock: data.attributes.total_stock,
            total_stock_receive: data.attributes.total_stock_receive,
            stock_description: data.attributes.stock_description,
            receive_description: data.attributes.receive_description
        }

        return (
            <Formik
                initialValues={initialValue}
            >
                {({
                    values
                }) => (
                    <form>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem",
                            }}
                        >
                            <CardHeader
                                title="Company Details"
                                subheader={values.description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Status"
                                            name="status"
                                            value={values.status}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="CSA License"
                                            name="csa_license"
                                            value={values.license}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="State"
                                            name="state"
                                            value={values.state}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="District"
                                            name="district"
                                            value={values.district}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardHeader
                                title="Stock Details"
                                subheader={values.stock_description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock In"
                                            name="total_stock_in"
                                            value={values.total_stock_in}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Out"
                                            name="total_stock_out"
                                            value={values.total_stock_out}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock"
                                            name="total_stock"
                                            value={values.total_stock}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardHeader
                                title="Receive Details"
                                subheader={values.receive_description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Receive"
                                            name="total_stock_receive"
                                            value={values.total_stock_receive}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    const CompanyReceiveSubRootDetails = ({data}) => {
        const initialValue = {
            uid: data.id,
            name: data.name,
            description: data.description,
            status: data.status,
            license: data.csa_license,
            state: data.state,
            district: data.district,
            total_stock_in: data.attributes.total_stock_in,
            total_stock_out: data.attributes.total_stock_out,
            total_stock: data.attributes.total_stock,
            total_stock_request: data.attributes.total_stock_request,
            total_stock_receive: data.attributes.total_stock_receive,
            total_stock_distribute: data.attributes.total_stock_distribute,
            request_description: data.attributes.request_description,
            distribution_description: data.attributes.distribution_description,
            stock_description: data.attributes.stock_description,
        }

        return (
            <Formik
                initialValues={initialValue}
            >
                {({
                    values
                }) => (
                    <form>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem",
                            }}
                        >
                            <CardHeader
                                title="Company Details"
                                subheader={values.description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Status"
                                            name="status"
                                            value={values.status}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="CSA License"
                                            name="csa_license"
                                            value={values.license}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="State"
                                            name="state"
                                            value={values.state}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="District"
                                            name="district"
                                            value={values.district}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardHeader
                                title="Request Details"
                                subheader={values.request_description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Request"
                                            value={values.total_stock_request}
                                            name="total_stock_request"
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={{readOnly: true}}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Receive"
                                            value={values.total_stock_receive}
                                            name="total_stock_receive"
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={{readOnly: true}}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardHeader
                                title="Stock Details"
                                subheader={values.stock_description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock In"
                                            name="total_stock_in"
                                            value={values.total_stock_in}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Out"
                                            name="total_stock_out"
                                            value={values.total_stock_out}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock"
                                            name="total_stock"
                                            value={values.total_stock}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardHeader
                                title="Distribution Details"
                                subheader={values.distribution_description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Distribute"
                                            name="total_stock_distribute"
                                            value={values.total_stock_distribute}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    const CompanyReceivesChildrenDetails = ({data}) => {
        const initialValue = {
            uid: data.id,
            name: data.name,
            description: data.description,
            status: data.status,
            license: data.csa_license,
            state: data.state,
            district: data.district,
            total_stock_in: data.attributes.total_stock_in,
            total_stock_out: data.attributes.total_stock_out,
            total_stock_request: data.attributes.total_stock_request,
            total_stock_receive: data.attributes.total_stock_receive,
            total_stock: data.attributes.total_stock,
            request_description: data.attributes.request_description,
            stock_description: data.attributes.stock_description
        }

        return (
            <Formik
                initialValues={initialValue}
            >
                {({
                    values
                }) => (
                    <form>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem",
                            }}
                        >
                            <CardHeader
                                title="Company Details"
                                subheader={values.description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Status"
                                            name="status"
                                            value={values.status}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="CSA License"
                                            name="csa_license"
                                            value={values.license}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="State"
                                            name="state"
                                            value={values.state}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="District"
                                            name="district"
                                            value={values.district}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardHeader
                                title="Request Details"
                                subheader={values.request_description} 
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Request"
                                            value={values.total_stock_request}
                                            name="total_stock_request"
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={{readOnly: true}}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Receive"
                                            value={values.total_stock_receive}
                                            name="total_stock_receive"
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={{readOnly: true}}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardHeader
                                title="Stock Details"
                                subheader={values.stock_description}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock In"
                                            name="total_stock_in"
                                            value={values.total_stock_in}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Total Stock Out"
                                            name="total_stock_out"
                                            value={values.total_stock_out}
                                            variant="outlined"
                                            type="text"
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                 <Grid
                                    item
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Total Stock"
                                        name="total_stock"
                                        value={values.total_stock}
                                        variant="outlined"
                                        type="text"
                                        color="secondary"
                                        inputProps={
                                            {readOnly: true}
                                        }
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        );
    }

    // render foreign object node
    const renderForeignObjectNode = ({ nodeDatum, toggleNode, foreignObjectProps }) => (
        <g>
            <circle
                r={10}
                fill={nodeDatum.color} 
                onClick={toggleNode}
            />
            <foreignObject {...foreignObjectProps}>
                <Box>
                    {
                        nodeDatum.source !== "starter" ? (
                            <Button
                                onClick={() => {
                                    handleOpen(nodeDatum.id)
                                }}
                                size="small"
                                variant="outlined"
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "600",
                                    color: "rgb(32, 33, 36)",
                                }} 
                            >
                                {nodeDatum.name}
                            </Button>
                        ) : (
                            null
                        )
                    }

                    {nodeDatum.attributes?.state && (
                        <Typography
                            ml="10px"
                            sx={{
                                fontSize: 12,
                                fontWeight: "400"
                            }}
                            color="rgb(32, 33, 36)"
                        >
                            State: {nodeDatum.attributes?.state}
                        </Typography>
                    )}
                </Box>
                {nodeDatum.id === id && (
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="view-dialog-title"
                        open={open}
                    >
                        <BootstrapDialogTitle 
                            id="view-dialog-title" 
                            onClose={handleClose}
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.default,
                            }}
                        >
                            <Typography fontWeight="bold" color={theme.palette.secondary[100]}>{nodeDatum.name}</Typography>
                        </BootstrapDialogTitle>
                        <DialogContent 
                            dividers
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.default,
                            }}
                        >
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <Container maxWidth="lg">
                                    <Grid container spacing={3}>
                                        <Grid
                                            item
                                            lg={12}
                                            md={6}
                                            xs={12}
                                        >
                                            {
                                                nodeDatum.source === "parent" ? (
                                                    <CompanyReceivesRootDetails data={nodeDatum}/>
                                                ) :
                                                    nodeDatum.source ==="subParent" ? (
                                                        <CompanyReceiveSubRootDetails data={nodeDatum}/>
                                                    ) : (
                                                        <CompanyReceivesChildrenDetails data={nodeDatum}/>
                                                    )
                                                
                                            }
                                            
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </DialogContent>
                    </BootstrapDialog>
                )}
            </foreignObject>
        </g>
    );

    return (
        <Box display='flex' flexGrow={1} flexDirection='column'>
            <Box 
                ref={containerRef}
                sx={{
                    height: "80vh",
                    background: 'rgb(232, 234, 237)',
                }} 
            >
                <Tree
                    data={data}
                    enableLegacyTransitions={true}
                    initialDepth={2}
                    nodeSize={nodeSize}
                    dimensions={dimensions}
                    translate={translate}
                    separation={{ siblings: 2, nonSiblings: 3 }}
                    collapsible={true}
                    renderCustomNodeElement={(rd3tProps) =>
                        renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                    }
                    orientation="vertical"
                    zoomable={false}
                    depthFactor={-140}
                />
            </Box>
        </Box>
    );
}

export default ReceiveSupplyTree;