import { useTheme, Card, CardContent, Typography, TextField, CardActions, Button, Collapse } from "@mui/material";
import { useState } from "react";
import FlexBetween from "modules/FlexBetween";

const WarehousePackagerItem = ({
    prod_id,
    wid,
    item_name,
    pid,
    bid,
    product_name,
    product_brand_name,
    product_serial_no,
    product_sku,
    product_category,
    product_package,
    stock_prod_in,
    stock_prod_out,
    total_raw_stock,
    remark,
    prod_date,
    prod_time
}) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem"
            }}
        >
            <CardContent>
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold"
                    }}
                    color={theme.palette.secondary[400]}
                    glutterBottom
                >
                    {product_name}
                </Typography>
                <TextField
                    size="small"
                    label="Production ID"
                    value={prod_id}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    margin="dense"
                    inputProps={
                        {readOnly: true}
                    }
                />
                <FlexBetween>
                    <TextField
                        size="small"
                        label="Product ID"
                        value={pid}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        margin="dense"
                        inputProps={
                            {readOnly: true}
                        }
                    />
                    <TextField
                        size="small"
                        label="Brand ID"
                        value={bid}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        margin="dense"
                        inputProps={
                            {readOnly: true}
                        }
                    />
                </FlexBetween>
                <TextField
                    size="small"
                    label="Brand Name"
                    value={product_brand_name}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    margin="dense"
                    inputProps={
                        {readOnly: true}
                    }
                />
                <FlexBetween>
                    <TextField
                        size="small"
                        label="Serial Number"
                        value={product_serial_no}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        margin="dense"
                        inputProps={
                            {readOnly: true}
                        }
                    />
                    <TextField
                        size="small"
                        label="SKU"
                        value={product_sku}
                        type="text"
                        color="secondary"
                        margin="dense"
                        inputProps={
                            {readOnly: true}
                        }
                    />
                </FlexBetween>
                <FlexBetween>
                    <TextField
                        size="small"
                        label="Category"
                        value={product_category}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        margin="dense"
                        inputProps={
                            {readOnly: true}
                        }
                    />
                    <TextField
                        size="small"
                        label="Package"
                        value={product_package}
                        type="text"
                        color="secondary"
                        margin="dense"
                        inputProps={
                            {readOnly: true}
                        }
                    />
                </FlexBetween>
            </CardContent>
            <CardActions>
                <Button
                    variant="primary"
                    size="small"
                    sx={{
                        width: "100%"
                    }}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See Details
                </Button>
            </CardActions>
            <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300]
                }}
            >
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold"
                    }}
                    color={theme.palette.secondary[300]}
                    gutterBottom
                >
                    Warehouse Details
                </Typography>
                <TextField
                    label="Warehouse ID"
                    value={wid}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    margin="dense"
                    size="small"
                />
                <TextField
                    label="Item Name"
                    value={item_name}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    margin="dense"
                    size="small"
                />
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold"
                    }}
                    color={theme.palette.secondary[300]}
                    gutterBottom
                >
                    Production Details
                </Typography>
                <FlexBetween>
                    <TextField
                        label="Stock Production In"
                        value={stock_prod_in}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Stock Production Out"
                        value={stock_prod_out}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                </FlexBetween>
                <TextField
                    label="Total Raw Stock"
                    value={total_raw_stock}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    margin="dense"
                    size="small"
                />
                <TextField
                    label="Remark"
                    value={remark}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    margin="dense"
                    size="small"
                    multiline
                />
                <FlexBetween>
                    <TextField
                        label="Production Date"
                        value={prod_date}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Production Time"
                        value={prod_time}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                </FlexBetween>
            </Collapse>
        </Card>
    )
}

export default WarehousePackagerItem;