import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "./FlexBetween";

const StatBoxCompany = ({ title, value, quota, icon, description1, description2 }) => {
    const theme = useTheme();
    return (
        <Box
            gridColumn="span 2"
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p="1.25rem 1rem"
            flex="1 1 100%"
            backgroundColor={theme.palette.background.alt}
            borderRadius="0.55rem"
        >
            <FlexBetween>
                <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
                    {title}
                </Typography>
                {icon}
            </FlexBetween>
            <FlexBetween>
                <Typography
                    variant="h5"
                    fontWeight="600"
                    sx={{ color: theme.palette.secondary[200] }}
                >
                    {quota}
                </Typography>
                <Typography>{description1}</Typography>
            </FlexBetween>
            <FlexBetween gap="0.3rem">
                <Typography
                    variant="h5"
                    fontStyle="italic"
                    sx={{ color: theme.palette.secondary.light }}
                >
                    {value}
                </Typography>
                <Typography>{description2}</Typography>
            </FlexBetween>
        </Box>
    );
};

export default StatBoxCompany;