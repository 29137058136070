import { 
    CheckOutlined,
    CloseOutlined,
    BusinessCenterOutlined, 
    DownloadOutlined,
    FactoryOutlined, 
    Inventory2Outlined, 
    LocalShippingOutlined, 
    AccessAlarmOutlined
} from "@mui/icons-material";

import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { getAlarms } from "state/fetch/alarms/alarmApi";

import {
    Box, 
    Button, 
    Dialog, 
    DialogTitle, 
    IconButton, 
    Tab, 
    useMediaQuery, 
    useTheme, 
    Grid,
    Card,
    CardHeader,
    Divider,
    CardContent,
    TextField,
    CardActions,
    Typography,
    DialogContent,
    Container
} from "@mui/material";

import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import FlexBetween from "modules/FlexBetween";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MapAlarmIssue from "modules/maps/alarms/MapAlarmIssues";

import { 
    getAlarmProducerCompanyID,
    updateAlarmProducerCompany,
    updateProducerAlarm
} from "state/fetch/alarms/singles/alarmProducerCompany"

import * as yup from "yup"
import { Formik } from "formik";
import { getAlarmPackagerCompanyID, updateAlarmPackagerCompany, updatePackagerAlarm } from "state/fetch/alarms/singles/alarmPackagerCompany";
import { getAlarmWholesalerCompanyID, updateAlarmWholesalerCompany, updateWholesalerAlarm } from "state/fetch/alarms/singles/alarmWholesalerCompany";
import { getAlarmRetailerCompanyID, updateAlarmRetailerCompany, updateRetailerAlarm } from "state/fetch/alarms/singles/alarmRetailerCompany";

const BootstrapDialog = styled(Dialog) (({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}))

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const Settings = ({isDashboard = false}) => {
    const theme = useTheme();

    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");

    const [settingValue, setSettingValue] = useState('1');

    const [alarmLevelValue, setAlarmLevelValue] = useState('1');
    const [alarmModeL1Value, setAlarmModeL1Value] = useState('1');
    const [alarmModeL2Value, setAlarmModeL2Value] = useState('1');
    const [alarmModeL3Value, setAlarmModeL3Value] = useState('1');
    const [alarmModeL4Value, setAlarmModeL4Value] = useState('1');

    const [totalProducerIssues, setTotalProducerIssues] = useState(0)
    const [totalPackagerIssues, setTotalPackagerIssues] = useState(0)
    const [totalWholesalerIssues, setTotalWholesalerIssues] = useState(0)
    const [totalRetailerIssues, setTotalRetailerIssues] = useState(0)

    const [alarmProducers, setAlarmProducers] = useState([])
    const [alarmPackagers, setAlarmPackagers] = useState([])
    const [alarmWholesalers, setAlarmWholesalers] = useState([])
    const [alarmRetailers, setAlarmRetailers] = useState([])

    const [alarmProducerData, setAlarmProducerData] = useState({})
    const [alarmPackagerData, setAlarmPackagerData] = useState({})
    const [alarmWholesalerData, setAlarmWholesalerData] = useState({})
    const [alarmRetailerData, setAlarmRetailerData] = useState({})

    const [producerQuota, setProducerQuota] = useState({})
    const [packagerQuota, setPackagerQuota] = useState({})
    const [wholesalerQuota, setWholesalerQuota] = useState({})
    const [retailerQuota, setRetailerQuota] = useState({})

    // dialog box handle open alarm detail
    const [openAlarmDetail, setOpenAlarmDetail] = useState(false)

    // dialog box handle open edit alarm
    const [openEditAlarm, setOpenEditAlarm] = useState(false)

    const [status, setStatus] = useState(100)
    const [statusMessage, setStatusMessage] = useState('Continue')
    const [message, setMessage] = useState('')

    // region data
    const[region, setRegion] = useState({})

    const navigate = useNavigate();

    const emptyData = [];

    // producer columns
    const producerColumns = [
        {
            field: "unique_id",
            headerName: "Company ID",
            headerAlign: "center",
            align: "center",
            width: 180
        },
        {
            field: "company_name",
            headerName: "Company Name",
            headerAlign: "center",
            align: "center",
            width: 300
        },
        {
            field: "alarm_company_status",
            headerName: "Status",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "metric_ton_quota",
            headerName: "MTQ",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "minimum_disbursement",
            headerName: "Minimum Disbursement (%)",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "duration_day",
            headerName: "Duration (Day)",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "alarm_company_description",
            headerName: "Description",
            headerAlign: "center",
            align: "center",
            width: 400
        }
    ]

    // packager columns
    const packagerColumns = [
        {
            field: "unique_id",
            headerName: "Company ID",
            headerAlign: "center",
            align: "center",
            width: 180
        },
        {
            field: "company_name",
            headerName: "Company Name",
            headerAlign: "center",
            align: "center",
            width: 300
        },
        {
            field: "alarm_company_status",
            headerName: "Status",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "package_quota",
            headerName: "Package Quota (KG)",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "minimum_disbursement",
            headerName: "Minimum Disbursement (%)",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "duration_day",
            headerName: "Duration (Day)",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "alarm_company_description",
            headerName: "Description",
            headerAlign: "center",
            align: "center",
            width: 400
        }
    ]

    // wholesaler columns
    const wholesalerColumns = [
        {
            field: "unique_id",
            headerName: "Company ID",
            headerAlign: "center",
            align: "center",
            width: 180
        },
        {
            field: "company_name",
            headerName: "Company Name",
            align: "center",
            width: 300
        },
        {
            field: "alarm_company_status",
            headerName: "Status",
            align: "center",
            width: 100
        },
        {
            field: "distribution_quota",
            headerName: "Distribution Quota (KG)",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "minimum_disbursement",
            headerName: "Minimum Disbursement (%)",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "duration_day",
            headerName: "Duration (Day)",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "alarm_company_description",
            headerName: "Description",
            headerAlign: "center",
            align: "center",
            width: 400
        }
    ]

    const retailerColumns = [
        {
            field: "unique_id",
            headerName: "Company ID",
            headerAlign: "center",
            align: "center",
            width: 180
        },
        {
            field: "company_name",
            headerName: "Company Name",
            headerAlign: "center",
            align: "center",
            width: 300
        },
        {
            field: "alarm_company_status",
            headerName: "Status",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "sales_quota",
            headerName: "Sales Quota",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "minimum_disbursement",
            headerName: "Minimum Disbursement (%)",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "duration_day",
            headerName: "Duration (Day)",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "alarm_company_description",
            headerName: "Description",
            headerAlign: "center",
            align: "center",
            width: 400
        }
    ]

    // producer manage columns
    const producerManageColumns = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmProducerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmProducerData(result)
                                })

                                // handle open alarm detail
                                handleOpenAlarmDetail()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                View
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 120,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmProducerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmProducerData(result)
                                })

                                // handle open edit alarm
                                handleOpenEditAlarm()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        }
    ]

    // packager manage columns
    const packagerManageColumns = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmPackagerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmPackagerData(result)
                                })

                                // handle open alarm detail
                                handleOpenAlarmDetail()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                View
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 120,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmPackagerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmPackagerData(result)
                                })

                                // handle open edit alarm
                                handleOpenEditAlarm()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        }
    ]

    // wholesaler manage columns
    const wholesalerManageColumns = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmWholesalerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmWholesalerData(result)
                                })

                                // handle open alarm detail
                                handleOpenAlarmDetail()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                View
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 120,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmWholesalerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmWholesalerData(result)
                                })

                                // handle open edit alarm
                                handleOpenEditAlarm()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        }
    ]

    // retailer manage columns
    const retailerManageColumns = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmRetailerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmRetailerData(result)
                                })

                                // handle open alarm detail
                                handleOpenAlarmDetail()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                View
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 120,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                                getAlarmRetailerCompanyID({
                                    aid: params.row.aid
                                }).then((result) => {
                                    // initialize data
                                    setAlarmRetailerData(result)
                                })

                                // handle open edit alarm
                                handleOpenEditAlarm()
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        }
    ]

    useEffect(() => {
        getAlarms().then((result) => {

            // initialize data
            setTotalProducerIssues(result.total_producer_issues)
            setTotalPackagerIssues(result.total_packager_issues)
            setTotalWholesalerIssues(result.total_wholesaler_issues)
            setTotalRetailerIssues(result.total_retailer_issues)

            setAlarmProducers(result.alarm_producer)
            setAlarmPackagers(result.alarm_packager)
            setAlarmWholesalers(result.alarm_wholesaler)
            setAlarmRetailers(result.alarm_retailer)

            setProducerQuota(result.producers)
            setPackagerQuota(result.packagers)
            setWholesalerQuota(result.wholesalers)
            setRetailerQuota(result.retailers)

            setRegion({
                center: result.center,
                zoom: result.zoom
            })
        })
    }, [])

    const handleSettingChange = (event, newValue) => {
        setSettingValue(newValue);
    }

    // handle change tab levels
    const handleAlarmLevelChange = (event, newValue) => {
        setAlarmLevelValue(newValue);
    }

    const handleAlarmModeL1Change = (event, newValue) => {
        setAlarmModeL1Value(newValue);
    }

    const handleAlarmModeL2Change = (event, newValue) => {
        setAlarmModeL2Value(newValue);
    }

    const handleAlarmModeL3Change = (event, newValue) => {
        setAlarmModeL3Value(newValue);
    }

    const handleAlarmModeL4Change = (event, newValue) => {
        setAlarmModeL4Value(newValue);
    }

    // handle open & close alarm dialog box
    const handleOpenAlarmDetail = () => {
        setOpenAlarmDetail(true)
    }

    const handleCloseAlarmDetail = () => {
        setOpenAlarmDetail(false)
    }

    // handle open & close edit alarm dialog box
    const handleOpenEditAlarm = () => {
        setOpenEditAlarm(true)
    }

    const handleCloseEditAlarm = () => {
        setOpenEditAlarm(false)
    }

    // refresh page
    const handleRefreshPage = () => {
        window.location.reload(false)
    }

    const EditAlarm = ({data}) => {

        let quota
        let label_quota
        switch(data.company_category) {
            case "Producer":
                quota = data.metric_ton_quota
                label_quota = "Metric Ton Quota"
                break;
            case "Packager":
                quota = data.package_quota
                label_quota = "Package Quota"
                break;
            case "Wholesaler":
                quota = data.distribution_quota
                label_quota = "Distribution Quota"
                break;
            case "Retailer":
                quota = data.sales_quota
                label_quota = "Sales Quota"
                break;
        }
        const initialAlarm = {
            aid: data.id,
            cid: data.cid,
            unique_id: data.unique_id,
            sid: data.sid,
            did: data.did,
            company_name: data.company_name,
            company_level: data.company_level,
            company_category: data.company_category,
            company_ssm: data.company_ssm,
            company_license: data.company_license,
            company_start_csa_license: data.company_start_csa_license,
            company_end_csa_license: data.company_end_csa_license,
            state_name: data.state_name,
            state_category: data.state_category,
            district_name: data.district_name,
            alarm_company_status: data.alarm_company_status,
            alarm_company_description: data.alarm_company_description,
            alarm_quota: quota,
            duration_day: data.duration_day,
            minimum_disbursement: data.minimum_disbursement,
            category_create_date: data.category_create_date,
            category_create_time: data.category_create_time,
            category_start_date: data.category_start_date,
            category_end_date: data.category_end_date,
            alarm_create_date: data.alarm_create_date,
            alarm_create_time: data.alarm_create_time,
            company_latitude: data.company_latitude,
            company_longitude: data.company_longitude
        }

        const handleSubmit = (values) => {
            // reconstruct json data
            let result
            switch(data.company_category) {
                case "Producer":
                    result = {
                        unique_id: data.unique_id,
                        alarm_company_status: data.alarm_company_status,
                        alarm_company_description: values.alarm_company_description,
                        metric_ton_quota: values.alarm_quota,
                        duration_day: values.duration_day,
                        minimum_disbursement: values.minimum_disbursement,
                        category_create_date: data.category_create_date,
                        category_create_time: data.category_create_time,
                        alarm_create_date: data.alarm_create_date,
                        alarm_create_time: data.alarm_create_time
                    }

                    // update alarm producer
                    updateAlarmProducerCompany({
                        aid: data.aid,
                        cid: data.cid,
                        unique_id: data.unique_id,
                        data: result
                    }).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    })
                    break;
                case "Packager":
                    result = {
                        unique_id: data.unique_id,
                        alarm_company_status: data.alarm_company_status,
                        alarm_company_description: values.alarm_company_description,
                        package_quota: values.alarm_quota,
                        duration_day: values.duration_day,
                        minimum_disbursement: values.minimum_disbursement,
                        category_create_date: data.category_create_date,
                        category_create_time: data.category_create_time,
                        alarm_create_date: data.alarm_create_date,
                        alarm_create_time: data.alarm_create_time
                    }

                    // update alarm producer
                    updateAlarmPackagerCompany({
                        aid: data.aid,
                        cid: data.cid,
                        unique_id: data.unique_id,
                        data: result
                    }).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    })
                    break;
                case "Wholesaler":
                    result = {
                        unique_id: data.unique_id,
                        alarm_company_status: data.alarm_company_status,
                        alarm_company_description: values.alarm_company_description,
                        distribution_quota: values.alarm_quota,
                        duration_day: values.duration_day,
                        minimum_disbursement: values.minimum_disbursement,
                        category_create_date: data.category_create_date,
                        category_create_time: data.category_create_time,
                        alarm_create_date: data.alarm_create_date,
                        alarm_create_time: data.alarm_create_time
                    }

                    // update alarm producer
                    updateAlarmWholesalerCompany({
                        aid: data.aid,
                        cid: data.cid,
                        unique_id: data.unique_id,
                        data: result
                    }).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    })
                    break;
                case "Retailer":
                    result = {
                        unique_id: data.unique_id,
                        alarm_company_status: data.alarm_company_status,
                        alarm_company_description: values.alarm_company_description,
                        sales_quota: values.alarm_quota,
                        duration_day: values.duration_day,
                        minimum_disbursement: values.minimum_disbursement,
                        category_create_date: data.category_create_date,
                        category_create_time: data.category_create_time,
                        alarm_create_date: data.alarm_create_date,
                        alarm_create_time: data.alarm_create_time
                    }

                    // update alarm producer
                    updateAlarmRetailerCompany({
                        aid: data.aid,
                        cid: data.cid,
                        unique_id: data.unique_id,
                        data: result
                    }).then((result) => {
                        setStatus(result.status_code)
                        setStatusMessage(result.status_message)
                        setMessage(result.message)
                    })
                    break;
            }
            
            // handle close edit alarm
            handleCloseEditAlarm()

            // handle refresh page
            handleRefreshPage()
        }

        const checkoutSchema = yup.object().shape({
            alarm_quota: yup.number().required("required"),
            duration_day: yup.number().required("required"),
            minimum_disbursement: yup.number().required("required")
        })

        return(
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialAlarm}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title={data.company_name}
                                subheader={data.unique_id}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="company_name"
                                            label="Company Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_name && !!errors.company_name}
                                            helperText={touched.company_name && errors.company_name}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label={label_quota}
                                            name="alarm_quota"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_quota}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.alarm_quota && !!errors.alarm_quota}
                                            helperText={touched.alarm_quota && errors.alarm_quota}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: {min: "1", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Min Disbursement (%)"
                                            name="minimum_disbursement"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.minimum_disbursement}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.minimum_disbursement && !!errors.minimum_disbursement}
                                            helperText={touched.minimum_disbursement && errors.minimum_disbursement}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: {min: "1", max: "100", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Duration (Day)"
                                            name="duration_day"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.duration_day}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.duration_day && !!errors.duration_day}
                                            helperText={touched.duration_day && errors.duration_day}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "30", step: "1" }
                                            }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="alarm_company_description"
                                            label="Description"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_company_description}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.alarm_company_description && !!errors.alarm_company_description}
                                            helperText={touched.alarm_company_description && errors.alarm_company_description}
                                            color="secondary"
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="alarm_create_date"
                                            label="Alarm Create Date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_create_date}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.alarm_create_date && !!errors.alarm_create_date}
                                            helperText={touched.alarm_create_date && errors.alarm_create_date}
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="alarm_create_time"
                                            label="Alarm Create Time"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_create_time}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.alarm_create_time && !!errors.alarm_create_time}
                                            helperText={touched.alarm_create_time && errors.alarm_create_time}
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Box mt="10px" display="flex" flexDirection="row-reverse" gap={2}>
                                    <Button
                                        onClick={() => {
                                            handleCloseEditAlarm();
                                        }}
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CloseOutlined sx={{ mr: "10px" }}/>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light,
                                            color: theme.palette.background.alt,
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            padding: "10px 20px"
                                        }}
                                    >
                                        <CheckOutlined sx={{ mr: "10px" }}/>
                                        Confirm
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    const AlarmDetail = ({data}) => {

        let quota
        switch(data.company_category) {
            case "Producer":
                quota = data.metric_ton_quota
                break;
            case "Packager":
                quota = data.package_quota
                break;
            case "Wholesaler":
                quota = data.distribution_quota
                break;
            case "Retailer":
                quota = data.sales_quota
                break;
        }

        const initialAlarm = {
            aid: data.id,
            cid: data.cid,
            unique_id: data.unique_id,
            sid: data.sid,
            did: data.did,
            company_name: data.company_name,
            company_level: data.company_level,
            company_category: data.company_category,
            company_ssm: data.company_ssm,
            company_license: data.company_license,
            company_start_csa_license: data.company_start_csa_license,
            company_end_csa_license: data.company_end_csa_license,
            state_name: data.state_name,
            state_category: data.state_category,
            district_name: data.district_name,
            alarm_company_status: data.alarm_company_status,
            alarm_company_description: data.alarm_company_description,
            alarm_quota: quota,
            duration_day: data.duration_day,
            minimum_disbursement: data.minimum_disbursement,
            category_create_date: data.category_create_date,
            category_create_time: data.category_create_time,
            category_start_date: data.category_start_date,
            category_end_date: data.category_end_date,
            alarm_create_date: data.alarm_create_date,
            alarm_create_time: data.alarm_create_time,
            company_latitude: data.company_latitude,
            company_longitude: data.company_longitude
        }

        return (
            <Formik
                initialValues={initialAlarm}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange
                }) => (
                    <form>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title={data.company_name}
                                subheader={data.unique_id}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="cid"
                                            label="Alarm Company ID"
                                            onBlur={handleBlur}
                                            value={values.cid}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.cid && !!errors.cid}
                                            helperText={touched.cid && errors.cid}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company Name"
                                            name="company_name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_name}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_name && !!errors.company_name}
                                            helperText={touched.company_name && errors.company_name}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company Category"
                                            name="company_category"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_category}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_category && !!errors.company_category}
                                            helperText={touched.company_category && errors.company_category}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company SSM"
                                            name="company_ssm"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_ssm}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_ssm && !!errors.company_ssm}
                                            helperText={touched.company_ssm && errors.company_ssm}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company CSA License"
                                            name="company_license"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_license && !!errors.company_license}
                                            helperText={touched.company_license && errors.company_license}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company Start CSA License"
                                            name="company_start_csa_license"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_start_csa_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_start_csa_license && !!errors.company_start_csa_license}
                                            helperText={touched.company_start_csa_license && errors.company_start_csa_license}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Company End CSA License"
                                            name="company_end_csa_license"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.company_end_csa_license}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.company_end_csa_license && !!errors.company_end_csa_license}
                                            helperText={touched.company_end_csa_license && errors.company_end_csa_license}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="metric_ton_quota"
                                            label="Metric Ton Quota (MTQ)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_quota}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.alarm_quota && !!errors.alarm_quota}
                                            helperText={touched.alarm_quota && errors.alarm_quota}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="minimum_disbursement"
                                            label="Min Disbursement (%)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.minimum_disbursement}
                                            type="number"
                                            error={!!touched.minimum_disbursement && !!errors.minimum_disbursement}
                                            helperText={touched.minimum_disbursement && errors.minimum_disbursement}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="duration_day"
                                            label="Duration (Day)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.duration_day}
                                            type="number"
                                            error={!!touched.duration_day && !!errors.duration_day}
                                            helperText={touched.duration_day && errors.duration_day}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="alarm_company_description"
                                            label="Description"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_company_description}
                                            variant="outlined"
                                            type="text"
                                            error={!!touched.alarm_company_description && !!errors.alarm_company_description}
                                            helperText={touched.alarm_company_description && errors.alarm_company_description}
                                            color="secondary"
                                            multiline
                                            rows={4}
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Alarm Start Date"
                                            name="cateogry_start_date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.category_start_date}
                                            type="text"
                                            error={!!touched.category_start_date && !!errors.category_start_date}
                                            helperText={touched.category_start_date && errors.category_start_date}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Alarm End Date"
                                            name="category_end_date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.category_end_date}
                                            type="text"
                                            error={!!touched.category_end_date && !!errors.category_end_date}
                                            helperText={touched.category_end_date && errors.category_end_date}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Alarm Create Date"
                                            name="alarm_create_date"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_create_date}
                                            type="text"
                                            error={!!touched.alarm_create_date && !!errors.alarm_create_date}
                                            helperText={touched.alarm_create_date && errors.alarm_create_date}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Alarm Create Time"
                                            name="alarm_create_time"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.alarm_create_time}
                                            type="text"
                                            error={!!touched.alarm_create_time && !!errors.alarm_create_time}
                                            helperText={touched.alarm_create_time && errors.alarm_create_time}
                                            color="secondary"
                                            inputProps={
                                                {readOnly: true}
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider/>
                            <CardActions>
                                <Button
                                    onClick={() => {
                                        navigate(`/companies/${values.unique_id}`)
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "100%",
                                        padding: "10px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    View Company Details
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleCloseAlarmDetail()
                                    }}
                                    variant="contained"
                                    size="small"
                                    color="error"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        width: "100%",
                                        padding: "10px 20px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    Close Details
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    const ProducerAlarmSettings = ({data}) => {
        
        const handleSubmit = (values) => {
            // reconstruct json data
            let result = {
                cid: data.cid,
                metric_ton_quota: values.metric_ton_quota,
                duration_day: values.duration_day,
                minimum_disbursement: values.minimum_disbursement,
                category_create_date: data.create_date,
                category_create_time: data.create_time
            }

            // update producer alarm category
            updateProducerAlarm({
                data: result
            }).then((result) => {
                setStatus(result.status_code)
                setStatusMessage(result.status_message)
                setMessage(result.message)
            })

            // refresh page
            handleRefreshPage()
        }

        const initialQuota = {
            cid: data.cid,
            metric_ton_quota: data.metric_ton_quota,
            duration_day: data.duration_day,
            minimum_disbursement: data.minimum_disbursement
        }

        const checkoutSchema = yup.object().shape({
            metric_ton_quota: yup.number().required("required"),
            duration_day: yup.number().required("required"),
            minimum_disbursement: yup.number().required("required")
        })
        
        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialQuota}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title="Set All Producer Quota"
                                subheader={data.cid}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="metric_ton_quota"
                                            label="Metric Ton Quota (MTQ)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.metric_ton_quota}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.metric_ton_quota && !!errors.metric_ton_quota}
                                            helperText={touched.metric_ton_quota && errors.metric_ton_quota}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: {min: "1", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="minimum_disbursement"
                                            label="Minimum Disbursement (%)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.minimum_disbursement}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.minimum_disbursement && !!errors.minimum_disbursement}
                                            helperText={touched.minimum_disbursement && errors.minimum_disbursement}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "100", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="duration_day"
                                            label="Duration (Day)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.duration_day}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.duration_day && !!errors.duration_day}
                                            helperText={touched.duration_day && errors.duration_day}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "30", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        width: "100%"
                                    }}
                                >
                                    <CheckOutlined sx={{ mr: "10px" }}/>
                                    Confirm
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    const PackagerAlarmSettings = ({data}) => {

        const handleSubmit = (values) => {
            // reconstruct json data
            let result = {
                cid: data.cid,
                package_quota: values.package_quota,
                duration_day: values.duration_day,
                minimum_disbursement: values.minimum_disbursement,
                category_create_date: data.create_date,
                category_create_time: data.create_time
            }

            // update packager alarm category
            updatePackagerAlarm({
                data: result
            }).then((result) => {
                setStatus(result.status_code)
                setStatusMessage(result.status_message)
                setMessage(result.message)
            })

            // refresh page
            handleRefreshPage()
        }

        const initialQuota = {
            cid: data.cid,
            package_quota: data.package_quota,
            duration_day: data.duration_day,
            minimum_disbursement: data.minimum_disbursement
        }

        const checkoutSchema = yup.object().shape({
            package_quota: yup.number().required("required"),
            duration_day: yup.number().required("required"),
            minimum_disbursement: yup.number().required("required")
        })

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialQuota}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title="Set All Packager Quota"
                                subheader={data.cid}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="package_quota"
                                            label="Package Quota (KG)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.package_quota}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.package_quota && !!errors.package_quota}
                                            helperText={touched.package_quota && errors.package_quota}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", step: "1" }
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="minimum_disbursement"
                                            label="Minimum Disbursement (%)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.minimum_disbursement}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.minimum_disbursement && !!errors.minimum_disbursement}
                                            helperText={touched.minimum_disbursement && errors.minimum_disbursement}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "100", step: "1" }
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="duration_day"
                                            label="Duration (Day)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.duration_day}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.duration_day && !!errors.duration_day}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "30", step: "1" }
                                            }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        width: "100%"
                                    }}
                                >
                                    <CheckOutlined sx={{ mr: "10px" }}/>
                                    Confirm
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    const WholesalerAlarmSettings = ({data}) => {

        const handleSubmit = (values) => {
            // reconstruct json data
            let result = {
                cid: data.cid,
                distribution_quota: values.distribution_quota,
                duration_day: values.duration_day,
                minimum_disbursement: values.minimum_disbursement,
                category_create_date: data.create_date,
                category_create_time: data.create_time
            }

            // update wholesaler alarm category
            updateWholesalerAlarm({
                data: result
            }).then((result) => {
                setStatus(result.status_code)
                setStatusMessage(result.status_message)
                setMessage(result.message)
            })

            // refresh page
            handleRefreshPage()
        }

        const initialQuota = {
            cid: data.cid,
            distribution_quota: data.distribution_quota,
            duration_day: data.duration_day,
            minimum_disbursement: data.minimum_disbursement
        }

        const checkoutSchema = yup.object().shape({
            distribution_quota: yup.number().required("required"),
            duration_day: yup.number().required("required"),
            minimum_disbursement: yup.number().required("required")
        })

        return(
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialQuota}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title="Set All Wholesaler Quota"
                                subheader={data.cid}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="distribution_quota"
                                            label="Distribution Quota (KG)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.distribution_quota}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.distribution_quota && !!errors.distribution_quota}
                                            helperText={touched.distribution_quota && errors.distribution_quota}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", step: "1" }
                                            }}
                                            required
                                        />
                                    </Grid>
                                    
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="minimum_disbursement"
                                            label="Minimum Disbursement (%)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.minimum_disbursement}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.minimum_disbursement && !!errors.minimum_disbursement}
                                            helperText={touched.minimum_disbursement && errors.minimum_disbursement}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "100", step: "1" }
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="duration_day"
                                            label="Duration (Day)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.duration_day}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.duration_day && !!errors.duration_day}
                                            helperText={touched.duration_day && errors.duration_day}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", step: "1" }
                                            }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        width: "100%"
                                    }}
                                >
                                    <CheckOutlined sx={{ mr: "10px" }}/>
                                    Confirm
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    const RetailerAlarmSettings = ({data}) => {

        const handleSubmit = (values) => {
            // reconstruct json data
            let result = {
                cid: data.cid,
                sales_quota: values.sales_quota,
                duration_day: values.duration_day,
                minimum_disbursement: values.minimum_disbursement,
                category_create_date: data.create_date,
                category_create_time: data.create_time
            }

            // update retailer alarm category
            updateRetailerAlarm({
                data: result
            }).then((result) => {
                setStatus(result.status_code)
                setStatusMessage(result.status_message)
                setMessage(result.message)
            })

            // refresh page
            handleRefreshPage()
        }

        const initialQuota = {
            cid: data.cid,
            sales_quota: data.sales_quota,
            duration_day: data.duration_day,
            minimum_disbursement: data.minimum_disbursement
        }

        const checkoutSchema = yup.object().shape({
            sales_quota: yup.number().required("required"),
            duration_day: yup.number().required("required"),
            minimum_disbursement: yup.number().required("required")
        })

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialQuota}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.alt,
                                borderRadius: "0.55rem"
                            }}
                        >
                            <CardHeader
                                title="Set All Retailer Quota"
                                subheader={data.cid}
                            />
                            <Divider/>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="sales_quota"
                                            label="Sales Quota"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.sales_quota}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.sales_quota && !!errors.sales_quota}
                                            helperText={touched.sales_quota && errors.sales_quota}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: {min: "1", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="minimum_disbursement"
                                            label="Minimum Disbursement (%)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.minimum_disbursement}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.minimum_disbursement && !!errors.minimum_disbursement}
                                            helperText={touched.minimum_disbursement && errors.minimum_disbursement}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "100", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            name="duration_day"
                                            label="Duration (Day)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.duration_day}
                                            variant="outlined"
                                            type="number"
                                            error={!!touched.duration_day && !!errors.duration_day}
                                            helperText={touched.duration_day && errors.duration_day}
                                            color="secondary"
                                            InputProps={{
                                                inputProps: { min: "1", max: "30", step: "1"}
                                            }}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.background.alt,
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        width: "100%"
                                    }}
                                >
                                    <CheckOutlined sx={{mr : "10px" }}/>
                                    Confirm
                                </Button>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Formik>
        )
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Settings" subtitle="System Configurations"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : 
                    (
                        <Box>
                            <Button
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
                
            </FlexBetween>
            <TabContext value={settingValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        variant="fullWidth"
                        onChange={handleSettingChange}
                        textColor="inherit"
                        indicatorColor="secondary"
                    >
                        <Tab icon={<AccessAlarmOutlined/>} iconPosition="start" label="Alarms" value="1"/>
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Box
                        mt="20px"
                        mb="10px"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="160px"
                        gap="20px"
                        sx={{
                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                        }}
                    >
                        <StatBox
                            title="Producer"
                            value={totalProducerIssues}
                            icon={
                                <FactoryOutlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                            description="Total Issue(s)"
                        />
                        <StatBox
                            title="Packager"
                            value={totalPackagerIssues}
                            icon={
                                <Inventory2Outlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                            description="Total Issue(s) "
                        />
                        <StatBox
                            title="Wholesaler"
                            value={totalWholesalerIssues}
                            icon={
                                <LocalShippingOutlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                            description="Total Issue(s)"
                        />
                        <StatBox
                            title="Retailer"
                            value={totalRetailerIssues}
                            icon={
                                <BusinessCenterOutlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                            description="Total Issue(s)"
                        />
                    </Box>
                    <Box
                        mt="20px"
                        gridColumn="span 12"
                        gridRow="span 3"
                    >
                        <TabContext value={alarmLevelValue}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    variant="fullWidth"
                                    onChange={handleAlarmLevelChange}
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                >
                                    <Tab icon={<FactoryOutlined/>} iconPosition="start" label="Producer (Level 1)" value="1"/>
                                    <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Packager (Level 2)" value="2"/>
                                    <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Wholesaler (Level 3)" value="3"/>
                                    <Tab icon={<BusinessCenterOutlined/>} iconPosition="start" label="Retailer (Level 4)" value="4"/>
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <TabContext value={alarmModeL1Value}>
                                    <Box height="100%" width="100%">
                                        <MapAlarmIssue items={alarmProducers} position={region}/>
                                    </Box>
                                    <Box mt="5px">
                                        <ProducerAlarmSettings data={producerQuota}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        height="60vh"
                                        sx={{
                                            "& .MuiDataGrid-root": {
                                                border: "none",
                                                borderRadius: "5rem"
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                backgroundColor: theme.palette.background.alt,
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderTop: "none",
                                            },
                                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                color: `${theme.palette.secondary[200]} !important`,
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            getRowId={(row) => row.unique_id}
                                            rows={alarmProducers?.length ? alarmProducers : emptyData}
                                            columns={producerColumns.concat(producerManageColumns)}
                                            components={{ Toolbar: QuickSearchToolbar }}
                                        />
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openAlarmDetail}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseAlarmDetail}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Alarm Producer Detail
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <AlarmDetail data={alarmProducerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openEditAlarm}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseEditAlarm}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Edit Alarm Producer
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <EditAlarm data={alarmProducerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                    </Box>
                                </TabContext>
                            </TabPanel>
                            <TabPanel value="2">
                                <TabContext value={alarmModeL2Value}>
                                    <Box height="100%" width="100%">
                                        <MapAlarmIssue items={alarmPackagers} position={region}/>
                                    </Box>
                                    <Box mt="5px">
                                        <PackagerAlarmSettings data={packagerQuota}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        height="60vh"
                                        sx={{
                                            "& .MuiDataGrid-root": {
                                                border: "none",
                                                borderRadius: "5rem"
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                backgroundColor: theme.palette.background.alt,
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderTop: "none",
                                            },
                                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                color: `${theme.palette.secondary[200]} !important`,
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            getRowId={(row) => row.unique_id}
                                            rows={alarmPackagers?.length ? alarmPackagers : emptyData}
                                            columns={packagerColumns.concat(packagerManageColumns)}
                                            components={{ Toolbar: QuickSearchToolbar }}
                                        />
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openAlarmDetail}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseAlarmDetail}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Alarm Packager Detail
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <AlarmDetail data={alarmPackagerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openEditAlarm}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseEditAlarm}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Edit Alarm Packager
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <EditAlarm data={alarmPackagerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                    </Box>
                                </TabContext>
                            </TabPanel>
                            <TabPanel value="3">
                                <TabContext value={alarmModeL3Value}>
                                    <Box height="100%" width="100%">
                                        <MapAlarmIssue items={alarmWholesalers} position={region}/>
                                    </Box>
                                    <Box mt="5px">
                                        <WholesalerAlarmSettings data={wholesalerQuota}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        height="60vh"
                                        sx={{
                                            "& .MuiDataGrid-root": {
                                                border: "none",
                                                borderRadius: "5rem"
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                backgroundColor: theme.palette.background.alt,
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderTop: "none",
                                            },
                                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                color: `${theme.palette.secondary[200]} !important`,
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            getRowId={(row) => row.unique_id}
                                            rows={alarmWholesalers?.length ? alarmWholesalers : emptyData}
                                            columns={wholesalerColumns.concat(wholesalerManageColumns)}
                                            components={{ Toolbar: QuickSearchToolbar }}
                                        />
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openAlarmDetail}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseAlarmDetail}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Alarm Wholesaler Detail
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <AlarmDetail data={alarmWholesalerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openEditAlarm}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseEditAlarm}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Edit Alarm Wholesaler
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <EditAlarm data={alarmWholesalerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                    </Box>
                                </TabContext>
                            </TabPanel>
                            <TabPanel value="4">
                                <TabContext value={alarmModeL4Value}>
                                    <Box height="100%" width="100%">
                                        <MapAlarmIssue items={alarmRetailers} position={region}/>
                                    </Box>
                                    <Box mt="5px">
                                        <RetailerAlarmSettings data={retailerQuota}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        height="60vh"
                                        sx={{
                                            "& .MuiDataGrid-root": {
                                                border: "none",
                                                borderRadius: "5rem"
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderBottom: "none",
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                backgroundColor: theme.palette.background.alt,
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                backgroundColor: theme.palette.background.alt,
                                                color: theme.palette.secondary[100],
                                                borderTop: "none",
                                            },
                                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                                color: `${theme.palette.secondary[200]} !important`,
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            getRowId={(row) => row.unique_id}
                                            rows={alarmRetailers?.length ? alarmRetailers : emptyData}
                                            columns={retailerColumns.concat(retailerManageColumns)}
                                            components={{ Toolbar: QuickSearchToolbar }}
                                        />
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openAlarmDetail}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseAlarmDetail}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Alarm Retailer Detail
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <AlarmDetail data={alarmRetailerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                        <BootstrapDialog
                                            aria-labelledby="view-dialog-title"
                                            open={openEditAlarm}
                                        >
                                            <BootstrapDialogTitle
                                                id="view-dialog-title"
                                                onClose={handleCloseEditAlarm}
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                }}
                                            >
                                                <Typography fontWeight="bold" color={theme.palette.secondary[100]}>
                                                    Edit Alarm Retailer
                                                </Typography>
                                            </BootstrapDialogTitle>
                                            <DialogContent
                                                dividers
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default
                                                }}
                                            >
                                                <Box
                                                    component="main"
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Container maxWidth="lg">
                                                        <EditAlarm data={alarmRetailerData}/>
                                                    </Container>
                                                </Box>
                                            </DialogContent>
                                        </BootstrapDialog>
                                    </Box>
                                </TabContext>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </TabPanel>
            </TabContext>
            
        </Box>
    );
}

export default Settings;