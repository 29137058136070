import { 
    Box, 
    useTheme 
} from "@mui/material";
import { useCallback } from "react";

import Force from 'react-force-graph-2d'

const SingleDistributeSupplyForce = ({forceData}) => {
    const theme = useTheme();
    const height = window.outerHeight;
    const width = window.outerWidth;

    const parsedWidth = width * 0.71;
    const parsedHeight = height * 0.73;

    const textCanvas = useCallback((node, ctx, globalScale) => {
        const label = node.id;
        const fontSize = 14 / globalScale;

        ctx.font = `${fontSize}px Sans-Serif`;

        const textWidth = ctx.measureText(label).width;
        const backgroundDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2);

        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.fillRect(node.x - backgroundDimensions[0] / 2, node.y - backgroundDimensions[1] / 2, ...backgroundDimensions);

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = node.color;

        ctx.fillText(label, node.x, node.y);

        node.backgroundDimensions = backgroundDimensions; // to re-use in nodePointerAreaPaint
    })

    return (
        <Box display="flex" flexGrow={1} flexDirection='column'>
            <Box
                sx={{
                    height: "80vh",
                    background: 'white'
                }}
            >
                <Force
                    height={parsedHeight}
                    width={parsedWidth}
                    graphData={forceData}
                    nodeLabel="id"
                    nodeAutoColorBy="group"
                    nodeCanvasObject={textCanvas}
                    onNodeDragEnd={node => {
                        node.fx = node.x;
                        node.fy = node.y;
                    }}
                    linkCurvature={0.25}
                    linkDirectionalArrowLength={3.5}
                    linkDirectionalArrowRelPos={1}
                    linkDirectionalParticles="value"
                    linkDirectionalParticleSpeed={d => d.value * 0.001}
                    minZoom={1.5}
                />
            </Box>
        </Box>
    )
}

export default SingleDistributeSupplyForce;