import React, { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    Box, 
    Button,
    Dialog, 
    DialogTitle, 
    DialogContent, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery,
    useTheme,
    CardHeader,
    CardContent,
    Grid,
    TextField,
    Card,
    Divider,
    InputLabel,
    Select
} from "@mui/material";

import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab"

import {
    DownloadOutlined,
    OilBarrelOutlined,
    FactoryOutlined,
    LocalShippingOutlined,
    CloseOutlined,
    Inventory2Outlined,
    LinkOutlined,
    InsertLinkOutlined,
    CallReceivedOutlined
} from "@mui/icons-material"

import { network } from "state/fetch/network"
import { socketEvents } from 'state/constants'
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { getDistributions } from "state/fetch/distributions/distributionApi";
import MapProducerDistribution from "modules/maps/distributions/MapProducerDistribution";
import MapPackagerDistribution from "modules/maps/distributions/MapPackagerDistribution";

import io from 'socket.io-client'
import StatesDistributionStat from "modules/statesDistricts/distributions/StatesDistributionStat";
import MapWholesalerDistribution from "modules/maps/distributions/MapWholesalerDistribution";
import DistributeSupplyForce from "modules/forces/DistributeSupplyForce";
import { mockDummy, mockForceData } from "data/mockForce";
import { mockGovDistribution } from "data/mockDistributeData";

const socket = io(network.domain)

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const Distribution = ({ isDashboard = false }) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    // region data
    const [region, setRegion] = useState({})

    // states and districts data
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])

    // distribution state data
    const [producerState, setProducerState] = useState([]);
    const [packagerState, setPackagerState] = useState([]);
    const [wholesalerState, setWholesalerState] = useState([]);

    const [producerCompanies, setProducerCompanies] = useState([])
    const [packagerCompanies, setPackagerCompanies] = useState([])
    const [wholesalerCompanies, setWholesalerCompanies] = useState([])

    const [producerNetwork, setProducerNetwork] = useState({ "nodes": [], "links": [] });
    const [packagerNetwork, setPackagerNetwork] = useState({  "nodes": [], "links": [] });
    const [wholesalerNetwork, setWholesalerNetwork] = useState({  "nodes": [], "links": [] });

    // socket connection data
    const [isConnected, setIsConnected] = useState(socket.connected)

    // socket delivery data
    const [producerDeliveryLocation, setProducerDeliveryLocation] = useState({})
    const [producerDropLocation, setProducerDropLocation] = useState({})
    const [producerPickupLocation, setProducerPickupLocation] = useState({})

    // tabbing handle change
    const [value, setValue] = useState('1')
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    useEffect(() => {
        getDistributions().then((result) => {
            
            // initialize data
            setProducerState(result.distribution_producer_states)
            setPackagerState(result.distribution_packager_states)
            setWholesalerState(result.distribution_wholesaler_states)

            setProducerCompanies(result.producer_companies)
            setPackagerCompanies(result.packager_companies)
            setWholesalerCompanies(result.wholesaler_companies)

            setProducerNetwork(result.producer_networks)
            setPackagerNetwork(result.packager_networks)
            setWholesalerNetwork(result.wholesaler_networks)

            // initialize data
            setRegion({
                center: result.center,
                zoom: result.zoom
            })

            setStates(result.states)
            setDistricts(result.districts)
        })
    }, [])

    useEffect(() => {
        // establish socket
        socket.on('connect', () => {
            setIsConnected(true);
        })

        socket.on('disconnect', () => {
            setIsConnected(false);
        })

        socket.emit(socketEvents.SUBSCRIBE_TO_SHIPMENT, {
            shipmentId: "654b8dcd9eeb99fea33173a6"
        })

        socket.on(socketEvents.DA_LOCATION_CHANGED, (data) => {
            try {
                const coorArr = data?.currentLocation?.coordinates;
                
                setProducerDeliveryLocation({
                    longitude: coorArr[0],
                    latitude: coorArr[1]
                })

                console(producerDeliveryLocation)
            } catch(error) {
                console.error(error)
            }
        })

        socket.on(socketEvents.SHIPMENT_UPDATED, (data) => {
            try {
                if(data.deliveryAssociateId) {
                    socket.emit(socketEvents.SUBSCRIBE_TO_DA, {
                        deliveryAssociateId: data.deliveryAssociateId
                    })

                    const coorDropArr = data?.dropLocation?.coordinates;
                    const coorPickupArr = data?.pickupLocation?.coordinates;

                    setProducerDropLocation({
                        longitude: coorDropArr[0],
                        latitude: coorDropArr[1]
                    })

                    setProducerPickupLocation({
                        longitude: coorPickupArr[0],
                        latitude: coorPickupArr[1]
                    })
                }
            } catch(error) {
                console.error(error);
            }
        })

        return () => {
            socket.off('connect')
            socket.off('disconnect')
            socket.off('pong')
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {

        }, 60000)

        return () => clearInterval(interval)
    }, [])

    const emptyData = [];

    /**
     * Create distribution column
     * 
     * @param {*} param0 is producer or otherwise
     */
    const createDistributionColumn = ({ isProducer = false }) => {
        const columns = [
            {
                field: "qr_id",
                headerName: "QRID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "delivery_order_id",
                headerName: "Delivery Order ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            
            {
                field: "supplier_company_id",
                headerName: "Supplier Company ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "supplier_company_name",
                headerName: "Supplier Company Name",
                heaedrAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "requester_company_id",
                headerName: "Requester Company ID",
                heaedrAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "requester_company_name",
                headerName: "Requester Company Name",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "states",
                headerName: "States",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "districts",
                headerName: "Districts",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "total_stock_distribute",
                headerName: isProducer ? "Total Stock Distribute (MT)" : "Total Stock Distribute",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "total_stock_receive",
                headerName: isProducer ? "Total Stock Receive (MT)" : "Total Stock Receive",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "delivery_status",
                headerName: "Delivery Status",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "delivery_date",
                headerName: "Delivery Date",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "delivery_time",
                headerName: "Delivery Time",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "delivery_duration",
                headerName: "Delivery Duration",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "receive_date",
                headerName: "Receive Date",
                headerAlign: "center",
                align: "center",
                width: 120
            },
            {
                field: "receive_time",
                headerName: "Receive Time",
                headerAlign: "center",
                align: "center",
                width: 120
            },
        ]

        return columns
    }

    const manageColumn = [
        {
            field: "details",
            headerName: "Details",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: "100%"
                        }}
                        onClick={() => {

                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            Details
                        </Typography>
                    </Button>
                )
            }
        },
        {
            field: "issues",
            headerName: "Issues",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: "100%"
                        }}
                        onClick={() => {

                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            Issues
                        </Typography>
                    </Button>
                )
            }
        }
    ]

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Distribution Dashboard" subtitle="Distribution Activity"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                onClick={() => {
                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <StatBox
                    title="Total Delivery"
                    value={0}
                    description="Producer"
                    icon={
                        <OilBarrelOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px"}}
                        />
                    }
                />
                <StatBox
                    title="Total Delivery"
                    value={0}
                    description="Packager"
                    icon={
                        <FactoryOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />
                <StatBox
                    title="Total Delivery"
                    value={0}
                    description="Wholesaler"
                    icon={
                        <LocalShippingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                />

                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                variant="fullWidth"
                                onChange={handleChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                            >
                                <Tab icon={<FactoryOutlined/>} iconPosition="start" label="Producer (Level 1)" value="1"/>
                                <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Packager (Level 2)" value="2"/>
                                <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Wholesaler (Level 3)" value="3"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box height="100%" width="100%">
                                <MapProducerDistribution 
                                    delivery={producerDeliveryLocation} 
                                    drop={producerDropLocation}
                                    position={region}/>
                            </Box>
                            <Box
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Links"
                                    value={10}
                                    description="Number of distribution network link"
                                    icon={
                                        <LinkOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Active Links"
                                    value={5}
                                    description="Number of active distribution network link"
                                    icon={
                                        <InsertLinkOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Complete Links"
                                    value={1}
                                    description="Number of complete distribution network link"
                                    icon={
                                        <CallReceivedOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                            </Box>
                            <Box mt="20px">
                                <Card
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.alt,
                                        borderRadius: "0.55rem",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold"
                                            }}
                                            color={theme.palette.secondary[300]}
                                            gutterBottom
                                        >
                                            SS Distribution Network Flow (Producer to Packager)
                                        </Typography>
                                        <DistributeSupplyForce forceData={producerNetwork}/>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.delivery_order_id}
                                    rows={mockGovDistribution}
                                    columns={createDistributionColumn({ isProducer: true }).concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                />
                            </Box>
                            <Box 
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                justifyContent="space-between"
                                rowGap="20px"
                                columnGap="1.33%"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                {producerState.map(
                                    ({
                                        sid,
                                        state_name,
                                        state_category,
                                        total_company,
                                        description,
                                        total_issues,
                                        consist_issue,
                                        total_daily_delivery,
                                        total_monthly_delivery,
                                        total_yearly_delivery
                                    }) => (
                                        <StatesDistributionStat
                                            key={sid}
                                            sid={sid}
                                            state_name={state_name}
                                            state_category={state_category}
                                            total_company={total_company}
                                            description={description}
                                            total_issues={total_issues}
                                            consist_issue={consist_issue}
                                            total_daily_delivery={total_daily_delivery}
                                            total_monthly_delivery={total_monthly_delivery}
                                            total_yearly_delivery={total_yearly_delivery}
                                        />
                                    )
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box height="100%" width="100%">
                                <MapPackagerDistribution position={region}/>
                            </Box>
                            <Box
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Links"
                                    value={0}
                                    description="Number of distribution network link"
                                    icon={
                                        <LinkOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Active Links"
                                    value={0}
                                    description="Number of active distribution network link"
                                    icon={
                                        <InsertLinkOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Complete Links"
                                    value={0}
                                    description="Number of complete distribution network link"
                                    icon={
                                        <CallReceivedOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                            </Box>
                            <Box mt="20px">
                                <Card
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.alt,
                                        borderRadius: "0.55rem",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold"
                                            }}
                                            color={theme.palette.secondary[300]}
                                            gutterBottom
                                        >
                                            SS Distribution Network Flow (Packager to Wholesaler)
                                        </Typography>
                                        <DistributeSupplyForce isDistributer={true} forceData={packagerNetwork}/>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.delivery_order_id}
                                    rows={mockGovDistribution}
                                    columns={createDistributionColumn({ isProducer: false }).concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                />
                            </Box>
                            <Box 
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                justifyContent="space-between"
                                rowGap="20px"
                                columnGap="1.33%"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                {packagerState.map(
                                    ({
                                        sid,
                                        state_name,
                                        state_category,
                                        total_company,
                                        description,
                                        total_issues,
                                        consist_issue,
                                        total_daily_delivery,
                                        total_monthly_delivery,
                                        total_yearly_delivery
                                    }) => (
                                        <StatesDistributionStat
                                            key={sid}
                                            sid={sid}
                                            state_name={state_name}
                                            state_category={state_category}
                                            total_company={total_company}
                                            description={description}
                                            total_issues={total_issues}
                                            consist_issue={consist_issue}
                                            total_daily_delivery={total_daily_delivery}
                                            total_monthly_delivery={total_monthly_delivery}
                                            total_yearly_delivery={total_yearly_delivery}
                                        />
                                    )
                                )}
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Box height="100%" width="100%">
                                <MapWholesalerDistribution position={region}/>
                            </Box>
                            <Box
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Links"
                                    value={0}
                                    description="Number of distribution network link"
                                    icon={
                                        <LinkOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Active Links"
                                    value={0}
                                    description="Number of active distribution network link"
                                    icon={
                                        <InsertLinkOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Complete Links"
                                    value={0}
                                    description="Number of complete distribution network link"
                                    icon={
                                        <CallReceivedOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                            </Box>
                            <Box mt="20px">
                                
                                <Card
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.alt,
                                        borderRadius: "0.55rem",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold"
                                            }}
                                            color={theme.palette.secondary[300]}
                                            gutterBottom
                                        >
                                            SS Distribution Network Flow (Wholesaler to Retailer)
                                        </Typography>
                                        <DistributeSupplyForce forceData={wholesalerNetwork}/>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.delivery_order_id}
                                    rows={mockGovDistribution}
                                    columns={createDistributionColumn({ isProducer: false }).concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                />
                            </Box>
                            <Box 
                                mt="20px"
                                display="grid"
                                gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                                justifyContent="space-between"
                                rowGap="20px"
                                columnGap="1.33%"
                                sx={{
                                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                }}
                            >
                                {wholesalerState.map(
                                    ({
                                        sid,
                                        state_name,
                                        state_category,
                                        total_company,
                                        description,
                                        total_issues,
                                        consist_issue,
                                        total_daily_delivery,
                                        total_monthly_delivery,
                                        total_yearly_delivery
                                    }) => (
                                        <StatesDistributionStat
                                            key={sid}
                                            sid={sid}
                                            state_name={state_name}
                                            state_category={state_category}
                                            total_company={total_company}
                                            description={description}
                                            total_issues={total_issues}
                                            consist_issue={consist_issue}
                                            total_daily_delivery={total_daily_delivery}
                                            total_monthly_delivery={total_monthly_delivery}
                                            total_yearly_delivery={total_yearly_delivery}
                                        />
                                    )
                                )}
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
        </Box>
    );
}

export default Distribution;