import { useTheme, Card, CardContent, Typography, TextField, CardActions, Button, Collapse } from "@mui/material";
import { useState } from "react";
import FlexBetween from "modules/FlexBetween";

const SaleRawItem = ({
    sale_stock_id,
    item_name,
    price,
    unit,
    sale_status,
    sale_date_register,
    sale_time_register,
    sale_description
}) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem"
            }}
        >
            <CardContent>
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold"
                    }}
                    color={theme.palette.secondary[400]}
                    glutterBottom
                >
                    {item_name}
                </Typography>
                <TextField
                    size="small"
                    label="ID"
                    value={sale_stock_id}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    margin="dense"
                    inputProps={
                        {readOnly: true}
                    }
                    sx={{
                        mb: "20px"
                    }}
                />
                <FlexBetween>
                    <TextField
                        label="Price (MYR)"
                        value={`RM${price}`}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Unit"
                        value={unit}
                        variant="outlined"
                        type="number"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                </FlexBetween>
                <TextField
                    label="Sale Status"
                    value={sale_status}
                    variant="outlined"
                    type="text"
                    color="secondary"
                    inputProps={
                        {readOnly: true}
                    }
                    margin="dense"
                    size="small"
                />
            </CardContent>
            <CardActions>
                <Button
                    variant="primary"
                    size="small"
                    sx={{
                        width: "100%"
                    }}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See Details
                </Button>
            </CardActions>
            <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300]
                }}
            >
                <Typography variant="body2">{sale_description}</Typography>
                <FlexBetween>
                    <TextField
                        label="Sale Date"
                        value={sale_date_register}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                    <TextField
                        label="Sale Time"
                        value={sale_time_register}
                        variant="outlined"
                        type="text"
                        color="secondary"
                        inputProps={
                            {readOnly: true}
                        }
                        margin="dense"
                        size="small"
                    />
                </FlexBetween>
            </Collapse>
        </Card>
    );
}

export default SaleRawItem;