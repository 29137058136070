import { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    Box, 
    Button,
    DialogTitle, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery,
    useTheme} from "@mui/material";

import {
    DownloadOutlined,
    FactoryOutlined,
    LocalShippingOutlined,
    Inventory2Outlined,
    MonetizationOnOutlined,
    ReceiptOutlined,
    CalendarTodayOutlined,
    CalendarMonthOutlined,
    ArrowBackOutlined
} from "@mui/icons-material"

import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab"

import PropTypes from "prop-types"
import { useNavigate } from "react-router";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { getSaleDistrict } from "state/fetch/sales/saleApi";
import MapSales from "modules/maps/sales/MapSales";
import BarChartDaily from "components/barcharts/sales/BarChartSalesDaily";
import BarChartMonthly from "components/barcharts/sales/BarChartSalesMonthly";
import BarChartYearly from "components/barcharts/sales/BarChartSalesYearly";

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const SaleDistrict = () => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    const path = window.location.pathname.split('/');

    // region data
    const [region, setRegion] = useState({})
    
    // states and districts data
    const [state, setState] = useState("")
    const [district, setDistrict] = useState("")

    //tabbing handle change
    const [value, setValue] = useState('1')

    // handle table change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    // handle bar chart tab change
    const [valueBarChartProducer, setValueBarChartProducer] = useState('1')
    const [valueBarChartPackager, setValueBarChartPackager] = useState('1')
    const [valueBarChartWholesaler, setValueBarChartWholesaler] = useState('1')
    const [valueBarChartRetailer, setValueBarChartRetailer] = useState('1')

    // producer total unit sold and sales
    const [totalProducerDailyUnitSold, setTotalProducerDailyUnitSold] = useState(0);
    const [totalProducerDailySales, setTotalProducerDailySales] = useState(0);
    const [totalProducerMonthlyUnitSold, setTotalProducerMonthlyUnitSold] = useState(0);
    const [totalProducerMonthlySales, setTotalProducerMonthlySales] = useState(0);
    const [totalProducerYearlyUnitSold, setTotalProducerYearlyUnitSold] = useState(0);
    const [totalProducerYearlySales, setTotalProducerYearlySales] = useState(0);

    // packager total unit sold and sales
    const [totalPackagerDailyUnitSold, setTotalPackagerDailyUnitSold] = useState(0);
    const [totalPackagerDailySales, setTotalPackagerDailySales] = useState(0);
    const [totalPackagerMonthlyUnitSold, setTotalPackagerMonthlyUnitSold] = useState(0);
    const [totalPackagerMonthlySales, setTotalPackagerMonthlySales] = useState(0)
    const [totalPackagerYearlyUnitSold, setTotalPackagerYearlyUnitSold] = useState(0);
    const [totalPackagerYearlySales, setTotalPackagerYearlySales] = useState(0);

    // wholesaler total unit sold and sales
    const [totalWholesalerDailyUnitSold, setTotalWholesalerDailyUnitSold] = useState(0);
    const [totalWholesalerDailySales, setTotalWholesalerDailySales] = useState(0);
    const [totalWholesalerMonthlyUnitSold, setTotalWholesalerMonthlyUnitSold] = useState(0);
    const [totalWholesalerMonthlySales, setTotalWholesalerMonthlySales] = useState(0);
    const [totalWholesalerYearlyUnitSold, setTotalWholesalerYearlyUnitSold] = useState(0);
    const [totalWholesalerYearlySales, setTotalWholesalerYearlySales] = useState(0);

    // retailer total unit sold and sales
    const [totalRetailerDailyUnitSold, setTotalRetailerDailyUnitSold] = useState(0);
    const [totalRetailerDailySales, setTotalRetailerDailySales] = useState(0);
    const [totalRetailerMonthlyUnitSold, setTotalRetailerMonthlyUnitSold] = useState(0);
    const [totalRetailerMonthlySales, setTotalRetailerMonthlySales] = useState(0);
    const [totalRetailerYearlyUnitSold, setTotalRetailerYearlyUnitSold] = useState(0);
    const [totalRetailerYearlySales, setTotalRetailerYearlySales] = useState(0);

    // total sales revenue
    const [totalProducerSalesRevenue, setTotalProducerSalesRevenue] = useState(0);
    const [totalPackagerSalesRevenue, setTotalPackagerSalesRevenue] = useState(0);
    const [totalWholesalerSalesRevenue, setTotalWholesalerSalesRevenue] = useState(0);
    const [totalRetailerSalesRevenue, setTotalRetailerSalesRevenue] = useState(0);

    // sales company
    const [producerCompany, setProducerCompany] = useState([]);
    const [packagerCompany, setPackagerCompany] = useState([]);
    const [wholesaleCompany, setWholesalerCompany] = useState([]);
    const [retailerCompany, setRetailerCompany] = useState([]);

    // handle bar chart tab change
    const handleBarChartProducerChange = (event, newValue) => {
        setValueBarChartProducer(newValue);
    }

    const handleBarChartPackagerChange = (event, newValue) => {
        setValueBarChartPackager(newValue);
    }

    const handleBarChartWholesalerChange = (event, newValue) => {
        setValueBarChartWholesaler(newValue);
    }

    const handleBarChartRetailerChange = (event, newValue) => {
        setValueBarChartRetailer(newValue);
    }

    useEffect(() => {
        getSaleDistrict({
            sid: path[path.length - 2],
            did: path[path.length - 1]
        }).then((result) => {
            // initialize producer total unit sold and sales
            // initialize producer total unit sold and sales
            setTotalProducerDailyUnitSold(result.total_producer_daily_unit_sold)
            setTotalProducerDailySales(result.total_producer_daily_sales)
            setTotalProducerMonthlyUnitSold(result.total_producer_monthly_unit_sold)
            setTotalProducerMonthlySales(result.total_producer_monthly_sales)
            setTotalProducerYearlyUnitSold(result.total_producer_yearly_unit_sold)
            setTotalProducerYearlySales(result.total_producer_yearly_sales)

            // initialize packager total unit sold and sales
            setTotalPackagerDailyUnitSold(result.total_packager_daily_unit_sold)
            setTotalPackagerDailySales(result.total_packager_daily_sales)
            setTotalPackagerMonthlyUnitSold(result.total_packager_monthly_unit_sold)
            setTotalPackagerMonthlySales(result.total_packager_monthly_sales)
            setTotalPackagerYearlyUnitSold(result.total_packager_yearly_unit_sold)
            setTotalPackagerYearlySales(result.total_packager_yearly_sales)

            // initialize wholesaler total unit sold and sales
            setTotalWholesalerDailyUnitSold(result.total_wholesaler_daily_unit_sold)
            setTotalWholesalerDailySales(result.total_wholesaler_daily_sales)
            setTotalWholesalerMonthlyUnitSold(result.total_wholesaler_monthly_unit_sold)
            setTotalWholesalerMonthlySales(result.total_wholesaler_monthly_sales)
            setTotalWholesalerYearlyUnitSold(result.total_wholesaler_yearly_unit_sold)
            setTotalWholesalerYearlySales(result.total_wholesaler_yearly_sales)

            // initialize retailer total unit sold and sales
            setTotalRetailerDailyUnitSold(result.total_retailer_daily_unit_sold)
            setTotalRetailerDailySales(result.total_retailer_daily_sales)
            setTotalRetailerMonthlyUnitSold(result.total_retailer_monthly_unit_sold)
            setTotalRetailerMonthlySales(result.total_retailer_monthly_sales)
            setTotalRetailerYearlyUnitSold(result.total_retailer_yearly_unit_sold)
            setTotalRetailerYearlySales(result.total_retailer_yearly_sales)

            setProducerCompany(result.producers)
            setPackagerCompany(result.packagers)
            setWholesalerCompany(result.wholesalers)
            setRetailerCompany(result.retailers)

            // initialize region data
            setRegion({
                center: result.center,
                zoom: result.zoom
            })
            setState(result.state)
            setDistrict(result.district)
        })
    }, [])

    const emptyData = [];

    const createColumn = ({ isProducer = false }) => {
        const columns = [
            {
                field: "unique_id",
                headerName: "ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "company_name",
                headerName: "Company Name",
                headerAlign: "center",
                align: "center",
                width: 300
            },
            {
                field: "daily_total_unit_sold",
                headerName: isProducer ? "Daily Total Unit Sold (MT)" : "Daily Total Unit Sold",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "daily_total_sales",
                headerName: "Daily Total Sales (MYR)",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "monthly_total_unit_sold",
                headerName: isProducer ? "Monthly Total Unit Sold (MT)" : "Monthly Total Unit Sold",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "monthly_total_sales",
                headerName: "Monthly Total Sales (MYR)",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "yearly_total_unit_sold",
                headerName: isProducer ? "Yearly Total Unit Sold (MT)" : "Yearly Total Unit Sold",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "yearly_total_sales",
                headerName: "Yearly Total Sales (MYR)",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "total_sales_revenue",
                headerName: "Total Sales Revenue (MYR)",
                headerAlign: "center",
                align: "center",
                width: 180
            }
        ]
        return columns
    }

    const manageColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: "100%"
                        }}
                        onClick={() => {
                            navigate(`${params.row.unique_id}`)
                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        }
    ]

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
            <Box display="flex" justifyContent="space-between">
                    <IconButton
                        onClick={() => { navigate(-1) }}
                        color="info"
                        sx={{
                            color: theme.palette.secondary.light,
                            marginRight: '10px'
                        }}
                    >
                        <ArrowBackOutlined/>
                    </IconButton>
                    <Header title="Sales Report Dashboard" subtitle={`List of Sales Report Activity in ${state}, ${district}`}/>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="info"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        <DownloadOutlined sx={{ mr: "10px" }}/>
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <StatBox
                    title="Total Unit Sold"
                    value={0}
                    description="Daily"
                    icon={
                        <Inventory2Outlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                        />
                    }
                />
                <StatBox
                    title="Total Sales"
                    value={0}
                    description="Daily"
                    icon={
                        <MonetizationOnOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                        />
                    }
                />
                <StatBox
                    title="Total Unit Sold"
                    value={0}
                    description="Monthly"
                    icon={
                        <Inventory2Outlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                        />
                    }
                />
                <StatBox
                    title="Total Sales"
                    value={0}
                    description="Monthly"
                    icon={
                        <MonetizationOnOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                        />
                    }
                />
                <StatBox
                    title="Total Unit Sold"
                    value={0}
                    description="Yearly"
                    icon={
                        <Inventory2Outlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                        />
                    }
                />
                <StatBox
                    title="Total Sales"
                    value={0}
                    description="Yearly"
                    icon={
                        <MonetizationOnOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                        />
                    }
                />
                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                variant="fullWidth"
                                onChange={handleChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                            >
                                <Tab icon={<FactoryOutlined/>} iconPosition="start" label="Producer (Level 1)" value="1"/>
                                <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Packager (Level 2)" value="2"/>
                                <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Wholesaler (Level 3)" value="3"/>
                                <Tab icon={<MonetizationOnOutlined/>} iconPosition="start" label="Retailer (Level 4)" value="4"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box
                               mt="10px"
                               mb="20px"
                               display="grid"
                               gridTemplateColumns="repeat(10, 1fr)"
                               gridAutoRows="160px"
                               gap="20px"
                               sx={{
                                   "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                               }} 
                            >
                                <StatBox
                                    title="Total Unit Sold (MT)"
                                    value={totalProducerDailyUnitSold}
                                    description="Daily"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalProducerDailySales}
                                    description="Daily"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold (MT)"
                                    value={totalProducerMonthlyUnitSold}
                                    description="Monthly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalProducerMonthlySales}
                                    description="Monthly"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold (MT)"
                                    value={totalProducerYearlyUnitSold}
                                    description="Yearly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalProducerYearlySales}
                                    description="Yearly"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales Revenue (MYR)"
                                    value={totalProducerSalesRevenue}
                                    description="Revenue"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                            </Box>
                            <Box height="100%" width="100%">
                                <MapSales items={producerCompany} position={region}/>
                            </Box>
                            <TabContext value={valueBarChartProducer}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleBarChartProducerChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartDaily isProducer={true}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold (MT)"
                                            value={0}
                                            description="Daily Unit Sold "
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Daily Sales "
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold (MT)"
                                            value={0}
                                            description="Daily Average Unit Sold "
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Daily Average Sales "
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Daily Profilt Before Tax "
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            description="Daily Profile After Tax "
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartMonthly isProducer={true}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold (MT)"
                                            value={0}
                                            description="Monthly Unit Sold "
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Monthly Sales "
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold (MT)"
                                            value={0}
                                            description="Monthly Average Unit Sold "
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Monthly Average Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Monthly Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            progress={0}
                                            description="Monthly Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel  value="3">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartYearly isProducer={true}/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold (MT)"
                                            value={0}
                                            description="Yearly Total Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Yearly Total Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold (MT)"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Yearly Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Yearly Average Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Yearly Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            description="Yearly Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <Box
                                    mt="10px"
                                    height="60vh"
                                    sx={{
                                        "& .MuiDataGrid-root": {
                                            border: "none",
                                            borderRadius: "5rem"
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderBottom: "none",
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            backgroundColor: theme.palette.background.alt,
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            backgroundColor: theme.palette.background.alt,
                                            color: theme.palette.secondary[100],
                                            borderTop: "none",
                                        },
                                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                            color: `${theme.palette.secondary[200]} !important`,
                                        },
                                    }}
                                >
                                    <DataGrid
                                        getRowId={(row) => row.unique_id}
                                        rows={emptyData}
                                        columns={createColumn({ isProducer: true }).concat(manageColumn)}
                                        components={{ Toolbar: QuickSearchToolbar }}
                                    />
                                </Box>
                            </TabContext>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalPackagerDailyUnitSold}
                                    description="Daily"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalPackagerDailySales}
                                    description="Daily"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalPackagerMonthlyUnitSold}
                                    description="Monthly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalPackagerMonthlySales}
                                    description="Monthly"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalPackagerYearlyUnitSold}
                                    description="Yearly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalPackagerYearlySales}
                                    description="Yearly"
                                    icon={
                                        <MonetizationOnOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales Revenue (MYR)"
                                    value={totalPackagerSalesRevenue}
                                    description="Revenue"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                            </Box>
                            <Box height="100%" width="100%">
                                <MapSales items={packagerCompany} position={region}/>
                            </Box>
                            <TabContext value={valueBarChartPackager}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleBarChartPackagerChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartDaily/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Daily Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Daily Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Daily Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Daily Average Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Daily Profile Before Tal"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            description="Daily Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartMonthly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Monthly Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Monthly Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            description="Monthly Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Monthly Average Sales"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profite Before Tax (MYR)"
                                            value={0}
                                            progress={0}
                                            description="Monthly Profile Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            progress={0}
                                            description="Monthly Profile After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartYearly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Yearly Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Yearly Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Yearly Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Yearly Average Sales"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            progress={0}
                                            description="Yearly Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            progress={0}
                                            description="Yearly Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                            </TabContext>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.unique_id}
                                    rows={emptyData}
                                    columns={createColumn({ isProducer: false }).concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalWholesalerDailyUnitSold}
                                    description="Daily"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalWholesalerDailySales}
                                    description="Daily"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalWholesalerMonthlyUnitSold}
                                    description="Monthly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalWholesalerMonthlySales}
                                    description="Monthly"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalWholesalerYearlyUnitSold}
                                    description="Yearly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalWholesalerYearlySales}
                                    description="Yearly"
                                    icon={
                                        <MonetizationOnOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales Revenue (MYR)"
                                    value={totalWholesalerSalesRevenue}
                                    description="Revenue"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                            </Box>
                            <Box height="100%" width="100%">
                                <MapSales items={wholesaleCompany} position={region}/>
                            </Box>
                            <TabContext value={valueBarChartWholesaler}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleBarChartWholesalerChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartDaily/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Daily Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Daily Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            description="Daily Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Daily Average Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Daily Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            description="Daily Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartMonthly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Monthly Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Monthly Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            description="Monthly Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Monthly Average Sales"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Monthly Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            description="Monthly Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartYearly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Yearly Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Yearly Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Yearly Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Yearly Average Sales"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Yearly Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            description="Yearly Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                            </TabContext>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.unique_id}
                                    rows={emptyData}
                                    columns={createColumn({ isProducer: false }).concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="4">
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(10, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalRetailerDailyUnitSold}
                                    description="Daily"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalRetailerDailySales}
                                    description="Daily"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalRetailerMonthlyUnitSold}
                                    description="Monthly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalRetailerMonthlySales}
                                    description="Monthly"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}  
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Unit Sold"
                                    value={totalRetailerYearlyUnitSold}
                                    description="Yearly"
                                    icon={
                                        <ReceiptOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales (MYR)"
                                    value={totalRetailerYearlySales}
                                    description="Yearly"
                                    icon={
                                        <MonetizationOnOutlined
                                        sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Sales Revenue (MYR)"
                                    value={totalRetailerSalesRevenue}
                                    description="Revenue"
                                    icon={
                                        <MonetizationOnOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} 
                                        />
                                    }
                                />
                            </Box>
                            <Box height="100">
                                <MapSales items={retailerCompany} position={region}/>
                            </Box>
                            <TabContext value={valueBarChartRetailer}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleBarChartRetailerChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                        <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartDaily/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Daily Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Daily Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Daily Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            rate="0"
                                            progress={0}
                                            description="Daily Average Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            progress={0}
                                            description="Daily Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            progress={0}
                                            description="Daily Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartMonthly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Monthly Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Monthly Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Average Unit Sold"
                                            value={0}
                                            description="Monthly Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Monthly Average Sales"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Monthly Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            description="Monthly Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Box
                                        p="1.25rem 1rem"
                                        height="60vh"
                                        backgroundColor={theme.palette.background.alt}
                                        borderRadius="0.55rem"
                                    >
                                        <BarChartYearly/>
                                    </Box>
                                    <Box
                                        mt="10px"
                                        mb="20px"
                                        display="grid"
                                        gridTemplateColumns="repeat(10, 1fr)"
                                        gridAutoRows="160px"
                                        gap="20px"
                                        sx={{
                                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                        }}
                                    >
                                        <StatBox
                                            title="Total Unit Sold"
                                            value={0}
                                            description="Yearly Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Sales (MYR)"
                                            value={0}
                                            description="Yearly Sales"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Unit Sold"
                                            value={0}
                                            description="Yearly Average Unit Sold"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Average Sales (MYR)"
                                            value={0}
                                            description="Yearly Average Sales"
                                            icon={
                                                <ReceiptOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit Before Tax (MYR)"
                                            value={0}
                                            description="Yearly Profit Before Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                        <StatBox
                                            title="Total Profit After Tax (MYR)"
                                            value={0}
                                            description="Yearly Profit After Tax"
                                            icon={
                                                <MonetizationOnOutlined
                                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                                />
                                            }
                                        />
                                    </Box>
                                </TabPanel>
                            </TabContext>
                            <Box
                                mt="10px"
                                height="60vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                        borderRadius: "5rem"
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        backgroundColor: theme.palette.background.alt,
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        backgroundColor: theme.palette.background.alt,
                                        color: theme.palette.secondary[100],
                                        borderTop: "none",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: `${theme.palette.secondary[200]} !important`,
                                    },
                                }}
                            >
                                <DataGrid
                                    getRowId={(row) => row.unique_id}
                                    rows={emptyData}
                                    columns={createColumn({ isProducer: false }).concat(manageColumn)}
                                    components={{ Toolbar: QuickSearchToolbar }}
                                />
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
        </Box>
    )
}

export default SaleDistrict;