import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "modules/navbars/NavbarKPDN";
import Sidebar from "modules/sidebars/Sidebar";

const Layout = () => {
    const isNoneMobile = useMediaQuery("(min-width: 600px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const userId = useSelector((state) => state.global.userId);

    return (
        <Box
            display={isNoneMobile ? "flex" : "block"}
            width="100%"
            height="100%"
        >
            <Sidebar
                user={{}}
                isNonMobile={isNoneMobile}
                drawerWidth="230px"
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
            />
            <Box flexGrow={1}>
                <Navbar
                    user={{}}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
                <Outlet/>
            </Box>
        </Box>
    );
};

export default Layout;