import { network } from "../network";

const url_geocoding = `${network.domain_api}/geocoding`

/* View API */

/**
 * Get list of geocodings
 * 
 * @param {*} data geocode query data
 * @returns list of geocodings
 */
export const getGeocodings = async (data) => {
    let response
    try {
        response = await fetch(`${url_geocoding}`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get single geocoding
 * 
 * @returns single geocoding
 */
export const getSingleGeocoding = async (data) => {
    console.log(data)
    let response
    try {
        response = await fetch(`${url_geocoding}/single`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json();
    console.log(json)
    return json;
}