import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";

import Layout from "scenes/layouts/layout";
import Dashboard from "scenes/govs/dashboard";
import Company from "scenes/govs/companies/company";
import CompanyProfile from "scenes/govs/companies/company_profile";
import LayoutCompany from "scenes/layouts/layout_company";
import LayoutLogin from "scenes/layouts/layout_login";
import Login from "scenes/auth/logins/login";
import Register from "scenes/auth/registers/register";
import ForgotPassword from "scenes/auth/passwords/forgot_password";
import LoginCompany from "scenes/auth/logins/login_company";
import RegisterCompany from "scenes/auth/registers/register_company";
import ForgotPasswordCompany from "scenes/auth/passwords/forgot_password_company";
import Products from "scenes/govs/products/product";
import Issues from "scenes/govs/issues/issue";
import Settings from "scenes/govs/settings";
import Distribution from "scenes/govs/distributions/distribution";
import CompanyStates from "scenes/govs/companies/company_states";
import CompanyDistricts from "scenes/govs/companies/company_districts";
import UserEmailVeification from "scenes/verification/user_email_verification";
import DistributionStates from "scenes/govs/distributions/distribution_states";
import DistributionDistrict from "scenes/govs/distributions/distribution_districts";
import Sale from "scenes/govs/sales/sale";
import SaleState from "scenes/govs/sales/sale_states";
import SaleDistrict from "scenes/govs/sales/sale_districts";
import ProductsState from "scenes/govs/products/product_states";
import ProductsDistrict from "scenes/govs/products/product_districts";
import Order from "scenes/govs/orders/order";
import OrderState from "scenes/govs/orders/order_states";
import OrderDistrict from "scenes/govs/orders/order_districts";

import UserDistribution from "scenes/users/distributions";
import UserDashboard from "scenes/users/dashboard";
import UserSale from "scenes/users/sales";
import UserOrders from "scenes/users/orders";
import UserIssues from "scenes/users/issues";
import UserStocks from "scenes/users/stocks";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      
      {/** Browser router in KDPN web page environment */}
      <BrowserRouter basename="lica/kpdn">
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Routes>
            {/* KPDN (domain) */}
            <Route element={<LayoutLogin/>}>
              {/* Login */}
              <Route path="" element={<Login/>}/>
              <Route path="register" element={<Register/>} />
              <Route path="recover" element={<ForgotPassword/>}/>
              <Route path="user">
                <Route path="email">
                  <Route path="verification">
                    <Route path=":id" element={<UserEmailVeification/>}/>
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route element={<Layout/>}>
              {/* Dashboard */}
              <Route path="dashboard">
                <Route index element={<Dashboard/>}/>
                <Route path="companies">
                  <Route index element={<Company/>}/>
                  <Route path=":id" element={<CompanyProfile/>}/>
                  <Route path="states">
                    <Route path=":id" element={<CompanyStates/>}/>
                  </Route>
                  <Route path="states">
                    <Route path=":id">
                      <Route path=":id" element={<CompanyProfile/>}/>
                    </Route>
                  </Route>
                  <Route path="districts">
                    <Route path=":id">
                      <Route path=":id" element={<CompanyDistricts/>}/>
                    </Route>
                  </Route>
                </Route>
              </Route>
              
              {/* Company */}
              <Route path="companies">
                <Route index element={<Company/>}/>
                <Route path=":id" element={<CompanyProfile/>}/>
                <Route path="states">
                  <Route path=":id" element={<CompanyStates/>}/>
                </Route>
                <Route path="states">
                  <Route path=":id">
                    <Route path=":id" element={<CompanyProfile/>}/>
                  </Route>
                </Route>
                <Route path="districts">
                  <Route path=":id">
                    <Route path=":id" element={<CompanyDistricts/>}/>
                  </Route>
                </Route>
              </Route>

              {/* Products */}
              <Route path="products">
                <Route index  element={<Products/>}/>
                <Route path="states">
                  <Route path=":id" element={<ProductsState/>}/>
                </Route>
                <Route path="districts">
                  <Route path=":id">
                    <Route path=":id" element={<ProductsDistrict/>}/>
                  </Route>
                </Route>
              </Route>

              {/* Distributions */}
              <Route path="distributions" >
                <Route index element={<Distribution/>}/>
                <Route path="states">
                  <Route path=":id" element={<DistributionStates/>}/>
                </Route>
                <Route path="districts">
                  <Route path=":id">
                    <Route path=":id" element={<DistributionDistrict/>}/>
                  </Route>
                </Route>
              </Route>

              {/* Sales */}
              <Route path="sales">
                <Route index element={<Sale/>}/>
                <Route path="states">
                  <Route path=":id" element={<SaleState/>}/>
                </Route>
                <Route path="districts">
                  <Route path=":id">
                    <Route path=":id" element={<SaleDistrict/>}/>
                  </Route>
                </Route>
              </Route>

              {/* Orders */}
              <Route path="orders">
                <Route index element={<Order/>}/>
                <Route path="states">
                  <Route path=":id" element={<OrderState/>}/>
                </Route>
                <Route path="districts">
                  <Route path=":id">
                    <Route path=":id" element={<OrderDistrict/>}/>
                  </Route>
                </Route>
              </Route>

              {/* Issues */}
              <Route path="issues" element={<Issues/>}/>

              {/* Settings */}
              <Route path="settings" element={<Settings/>}/>
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
          
      {/** Browser router in company web page environment */}
      <BrowserRouter basename="lica/company">
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Routes>
            <Route element={<LayoutLogin/>}>
              {/* Login */}
              <Route path="" element={<LoginCompany/>}/>
              <Route path="register" element={<RegisterCompany/>}/>
              <Route path="recover" element={<ForgotPasswordCompany/>}/>
            </Route>
            <Route element={<LayoutCompany/>}>
              <Route path="dashboard" element={<UserDashboard/>}/>
              <Route path="distributions" element={<UserDistribution/>}/>
              <Route path="sales" element={<UserSale/>}/>
              <Route path="orders" element={<UserOrders/>}/>
              <Route path="stocks" element={<UserStocks/>}/>
              <Route path="issues" element={<UserIssues/>}/>
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
