import React, { useState } from "react";
import {
    LightModeOutlined,
    DarkModeOutlined,
} from "@mui/icons-material";

import FlexBetween from "modules/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";

import { 
    AppBar,
    IconButton,
    Toolbar,
    useTheme,
} from "@mui/material";

const NavbarLogin = ()=> {
    const dispatch = useDispatch();
    const theme = useTheme();

    return (
        <AppBar
            sx={{
                position: "static",
                background: "none",
                boxShadow: "none",
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <FlexBetween/>
                <FlexBetween gap="1.5rem">
                </FlexBetween>
            </Toolbar>
        </AppBar>
    );
}

export default NavbarLogin;