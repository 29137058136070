import AsyncStorage from "@react-native-async-storage/async-storage";

export const storeData = async(key, value) => {
    try {
        return await AsyncStorage.setItem(key, value);
    } catch(error) {
        console.log(error)
    }
}

export const getData = async (key) => {
    try {
        let data = await AsyncStorage.getItem(key);
        return key !== null ? data : null;
    } catch(error) {
        console.log(error)
    }
}

export const storeDataJson = async(key, value) => {
    try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem(key, jsonValue)
    } catch(error) {
        console.log(error);
    }
}

export const getDataJson = async (key) => {
    try {
        const jsonValue = await AsyncStorage.getItem(key);
        return jsonValue !== null ? JSON.parse(jsonValue) : null;
    } catch(error) {
        console.log(error)
    }
}

export const clearData = async() => {
    AsyncStorage.clear()
}