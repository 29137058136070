import { 
    AttachMoneyOutlined, 
    DownloadOutlined, 
    Inventory2Outlined, 
    LocalShippingOutlined, 
    RequestQuoteOutlined
} from "@mui/icons-material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    mockDeliveryListIssue, 
    mockQuotaListIssue, 
    mockStockListIssue, 
    mockTransactionListIssue 
} from "data/mockDataIssue";

import {
    mockConsistIssue, 
    mockDeliveryIssue,
    mockOverallIssue, 
    mockStateQuotaIssue, 
    mockStockIssue, 
    mockTransactionIssue 
} from "data/mockDataStateIssues";

import FlexBetween from "modules/FlexBetween";
import StatesIssueDeliveryStat from "modules/statesDistricts/issues/states/StatesIssueDeliveryStat";
import StatesIssueQuotaStat from "modules/statesDistricts/issues/states/StatesIssueQuotaStat";
import StatesIssueStockStat from "modules/statesDistricts/issues/states/StatesIssueStockStat";
import StatesIssueTransactionStat from "modules/statesDistricts/issues/states/StatesIssueTransactionStat";
import { useState } from "react";

const Issues = ({isDashboard = false}) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const [issueValue, setIssueValue] = useState('1');

    const handleIssueChange = (event, newValue) => {
        setIssueValue(newValue)
    }

    const emptyData = [];
    const columns = [
        {
            field: "issue_id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 120
        },
        {
            field: "issue_name",
            headerName: "Issue Name",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "issue_category",
            headerName: "Issue Category",
            headerAlign: "center",
            align: "center",
            width: 200
        },
        {
            field: "issue_status",
            headerName: "Status",
            headerAlign: "center",
            align: "center",
            width: 160
        },
        {
            field: "issue_date_register",
            headerName: "Issue Date",
            headerAlign: "center",
            align: "center",
            width: 100
        },
        {
            field: "issue_time_register",
            headerName: "Issue Time",
            headerAlign: "center",
            align: "center",
            width: 100
        }
    ];

    const viewColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            width: 120,
            renderCell: (params) => {
                return (
                    <Box
                        width="70%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{ 
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: '100%'
                            }}
                        >
                            <Typography sx={{ fontSize: "12px",fontWeight: "bold" }}>
                                View
                            </Typography>
                        </Button>
                    </Box>
                );
            }
        }
    ];

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Issues Dashboard" subtitle="List of Issue Occurs"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
                
            </FlexBetween>
            <Box
                mt="20px"
                mb="10px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <StatBox
                    title="Quota Issues"
                    value={mockOverallIssue.overall_quota_issue}
                    icon={
                        <RequestQuoteOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                    description="Issue Occurs"
                />
                <StatBox
                    title="Deliverable Issues"
                    value={mockOverallIssue.overall_stock_issue}
                    icon={
                        <Inventory2Outlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                    description="Issue Occurs"
                />
                <StatBox
                    title="Transportation Issues"
                    value={mockOverallIssue.overall_devlivery_issue}
                    icon={
                        <LocalShippingOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                    description="Issue Occurs"
                />
                <StatBox
                    title="Transaction Issues"
                    value={mockOverallIssue.overall_transaction_issue}
                    icon={
                        <AttachMoneyOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                        />
                    }
                    description="Issue Occurs"
                />
            </Box>
            <Box
                mt="20px"
                gridColumn="span 12"
                gridRow="span 3"
            >
                <TabContext value={issueValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            variant="fullWidth"
                            onChange={handleIssueChange}
                            textColor="inherit"
                            indicatorColor="secondary"
                        >
                            <Tab icon={<RequestQuoteOutlined/>} iconPosition="start" label="Quota Issues" value="1" sx={{ backgroundColor: mockConsistIssue.quota_consist_issue ? "red" : "" }}/>
                            <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Deliverable Issues" value="2" sx={{ backgroundColor: mockConsistIssue.stock_consist_issue ? "red" : "" }}/>
                            <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Transportation Issues" value="3" sx={{ backgroundColor: mockConsistIssue.stock_consist_issue ? "red" : "" }}/>
                            <Tab icon={<AttachMoneyOutlined/>} iconPosition="start" label="Transaction Issues" value="4" sx={{ backgroundColor: mockConsistIssue.transaction_consist_issue ? "red" : "" }}/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.issue_id}
                                rows={mockQuotaListIssue?.length ? mockQuotaListIssue : emptyData}
                                columns={columns.concat(viewColumn)}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </Box>
                        <Box
                            mt="20px"
                            display="grid"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            justifyContent="space-between"
                            rowGap="20px"
                            columnGap="1.33%"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {mockStateQuotaIssue.map(
                                ({
                                    sid,
                                    state_name,
                                    state_category,
                                    total_company,
                                    description,
                                    total_issues,
                                    consist_issue,
                                    total_exceed_quota_metric_ton,
                                    total_exceed_quota_packaging,
                                    total_exceed_quota_distribution,
                                    total_exceed_quota_unit_sold
                                }) => (
                                    <StatesIssueQuotaStat
                                        key={sid}
                                        sid={sid}
                                        state_name={state_name}
                                        state_category={state_category}
                                        total_company={total_company}
                                        total_issues={total_issues}
                                        consist_issue={consist_issue}
                                        description={description}
                                        total_exceed_quota_metric_ton={total_exceed_quota_metric_ton}
                                        total_exceed_quota_packaging={total_exceed_quota_packaging}
                                        total_exceed_quota_distribution={total_exceed_quota_distribution}
                                        total_exceed_quota_unit_sold={total_exceed_quota_unit_sold}
                                    />
                                )
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.issue_id}
                                rows={mockStockListIssue?.length ? mockStockListIssue : emptyData}
                                columns={columns.concat(viewColumn)}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </Box>
                        <Box
                            mt="20px"
                            display="grid"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            justifyContent="space-between"
                            rowGap="20px"
                            columnGap="1.33%"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {mockStockIssue.map(
                                ({
                                    sid,
                                    state_name,
                                    state_category,
                                    total_company,
                                    description,
                                    total_issues,
                                    consist_issue,
                                    total_stock_loss,
                                    total_stock_defects,
                                    total_stock_damage,
                                    total_others,
                                }) => (
                                    <StatesIssueStockStat
                                        key={sid}
                                        sid={sid}
                                        state_name={state_name}
                                        state_category={state_category}
                                        total_company={total_company}
                                        description={description}
                                        total_issues={total_issues}
                                        consist_issue={consist_issue}
                                        total_stock_loss={total_stock_loss}
                                        total_stock_defects={total_stock_defects}
                                        total_stock_damage={total_stock_damage}
                                        total_others={total_others}
                                    />
                                )
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value="3">
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.issue_id}
                                rows={mockDeliveryListIssue?.length ? mockDeliveryListIssue : emptyData}
                                columns={columns.concat(viewColumn)}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </Box>
                        <Box
                            mt="20px"
                            display="grid"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            justifyContent="space-between"
                            rowGap="20px"
                            columnGap="1.33%"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {mockDeliveryIssue.map(
                                ({
                                    sid,
                                    state_name,
                                    state_category,
                                    total_company,
                                    total_issues,
                                    consist_issue,
                                    description,
                                    total_delivery_missing,
                                    total_delivery_delay,
                                    total_delivery_misrouted,
                                    total_others
                                }) => (
                                    <StatesIssueDeliveryStat
                                        key={sid}
                                        sid={sid}
                                        state_name={state_name}
                                        state_category={state_category}
                                        total_company={total_company}
                                        total_issues={total_issues}
                                        consist_issue={consist_issue}
                                        description={description}
                                        total_delivery_missing={total_delivery_missing}
                                        total_delivery_delay={total_delivery_delay}
                                        total_delivery_misrouted={total_delivery_misrouted}
                                        total_others={total_others}
                                    />
                                )
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value="4">
                        <Box
                            mt="10px"
                            height="60vh"
                            sx={{
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                    borderRadius: "5rem"
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: theme.palette.background.alt,
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    backgroundColor: theme.palette.background.alt,
                                    color: theme.palette.secondary[100],
                                    borderTop: "none",
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${theme.palette.secondary[200]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                getRowId={(row) => row.issue_id}
                                rows={mockTransactionListIssue?.length ? mockTransactionListIssue : emptyData}
                                columns={columns.concat(viewColumn)}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </Box>
                        <Box
                            mt="20px"
                            display="grid"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            justifyContent="space-between"
                            rowGap="20px"
                            columnGap="1.33%"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {mockTransactionIssue.map(
                                ({
                                    sid,
                                    state_name,
                                    state_category,
                                    total_company,
                                    total_issues,
                                    consist_issue,
                                    description,
                                    total_suspicious_transaction,
                                    total_unbalance_transaction,
                                    total_others
                                }) => (
                                    <StatesIssueTransactionStat
                                        key={sid}
                                        sid={sid}
                                        state_name={state_name}
                                        state_category={state_category}
                                        total_company={total_company}
                                        total_issues={total_issues}
                                        consist_issue={consist_issue}
                                        description={description}
                                        total_suspicious_transaction={total_suspicious_transaction}
                                        total_unbalance_transaction={total_unbalance_transaction}
                                        total_others={total_others}
                                    />
                                )
                            )}
                        </Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    );
}

export default Issues;