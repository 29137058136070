import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    useTheme
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import logoImage from "assets/lica-icon.png";

const LoginCompany = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const Copyright = (props) => {
        return (
            <Typography variant="body2" align="center" {...props}>
                Copyright © {new Date().getFullYear()} Dataran Teknologi Sdn. Bhd. All Rights Reserved.
            </Typography>
        );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('emailUsername'),
            password: data.get('password')
        });
        navigate("/dashboard");
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar 
                    src={logoImage}
                    sx={{ 
                        width: "120px",
                        height: "120px",
                        m: 1,
                    }}
                />
                <Typography 
                    mt="10px"
                    component="h1" 
                    align="center"
                    variant="h5"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Logistic Interface Computive Automation (LICA)
                </Typography>
                <Typography
                    component="h1" 
                    align="center"
                    variant="h6"
                    color={theme.palette.secondary[100]}
                    fontWeight="bold"
                >
                    Cooking Oil Monitoring System
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="emailUsername"
                    label="Username or Email Address"
                    name="emailUsername"
                    autoComplete="emailUsername"
                    color="secondary"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    color="secondary"
                    autoComplete="current-password"
                />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" sx={{}} />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ 
                            mt: 3, 
                            mb: 2,
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/company/recover" variant="body2" style={{ color: theme.palette.secondary.light }}>
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/company/register" variant="body2" style={{ color: theme.palette.secondary.light }}>
                                Don't have an account? Sign Up
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4}}/>
        </Container>
    );
}

export default LoginCompany;