import { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    Box, 
    Button,
    DialogTitle, 
    IconButton, 
    Tab, 
    Typography, 
    useMediaQuery,
    useTheme
} from "@mui/material";

import {
    DownloadOutlined,
    Inventory2Outlined,
    CalendarTodayOutlined,
    CalendarMonthOutlined,
    ReportProblemOutlined,
    AddOutlined,
    PrecisionManufacturingOutlined,
    WarehouseOutlined,
    LogoutOutlined,
    ExitToAppOutlined
} from "@mui/icons-material"

import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab"

import PropTypes from "prop-types"
import { useNavigate } from "react-router";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import SubHeader from "components/SubHeader";
import SearchBar from "components/SearchBar";
import WarehouseProducerItem from "modules/items/warehouses/WarehouseProducerItem";
import WarehousePackagerItem from "modules/items/warehouses/WarehousePackagerItem";
import BarChartStocksDaily from "components/barcharts/stocks/BarChartStocksDaily";
import BarChartStocks from "components/barcharts/stocks/BarChartStocks";
import BarChartStocksMonthly from "components/barcharts/stocks/BarChartStocksMonthly";
import BarChartStocksYearly from "components/barcharts/stocks/BarChartStocksYearly";
import StockProducerItem from "modules/items/stocks/StockProducerItem";
import StockNonProducerItem from "modules/items/stocks/StockNonProducerItem";

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m : 0, p : 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                />
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 1,
            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    )
}

const UserStocks = ({ isDashboard = false, isProducer = false, isPackager = false }) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const isNonMobile = useMediaQuery("(min_width: 1000px)")

    const [value, setValue] = useState('1')
    const [valueBarChart, setValueBarChart] = useState('1')

    const navigate = useNavigate();

    // handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleBarChartChange = (event, newValue) => {
        setValueBarChart(newValue)
    }

    const [totalIssue, setTotalIssue] = useState(0)

    // this section covers all levels (producer, packager, wholesaler, retailer)
    const [totalStock, setTotalStock] = useState(0)
    const [totalStockIn, setTotalStockIn] = useState(0)
    const [totalStockOut, setTotalStockOut] = useState(0)

    const [stockProducer, setStockProducer] = useState([])
    const [stockNonProducer, setStockNonProducer] = useState([])
    
    // this section mainly used for producer
    const [totalWarehouseItem, setTotalWarehouseItem] = useState(0)

    // this section mainly used for packager
    const [totalWarehouseProductionStockIn, setTotalWarehouseProductionStockIn] = useState(0)
    const [totalWarehouseProductionStockOut, setTotalWarehouseProductionStockOut] = useState(0)
    const [totalWarehouseProductionRawStock, setTotalWarehouseProductionRawStock] = useState(0)
    const [totalWarehouseProductionItem, setTotalWarehouseProductionItem] = useState(0)

    const [warehouseProducer, setWarehouseProducer] = useState([])
    const [warehouseProducerFiltered, setWarehouseProducerFiltered] = useState([])
    const [warehousePackager, setWarehousePackager] = useState([])
    const [warehousePackagerFiltered, setWarehousePackagerFiltered] = useState([])

    const [stockItem, setStockItem] = useState([])
    const [stockItemFiltered, setStockItemFiltered] = useState([])
    const [stockProduct, setStockProduct] = useState([])
    const [stockProductFiltered, setStockProductFiltered] = useState([])

    // this section for producer
    const [searchProducerItem, setSearchProducerItem] = useState("");
    

    // this section for packager
    const [searchPackagerItem, setSearchPackagerItem] = useState("")

    const [searchStockProducerItem, setSearchStockProducerItem] = useState("")
    const [searchStockNonProducerItem, setSearchStockNonProducerItem] = useState("")

    useEffect(() => {

    }, [])

    const warehouseProducerColumn = () => {
        const columns = [
            {
                field: 'wid',
                headerName: "Warehouse ID",
                headerAlign: 'center',
                align: 'center',
                width: 100
            },
            {
                field: 'item_name',
                headerName: "Item Name",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'description',
                headerName: 'Description',
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'created_date',
                headerName: "Created Date",
                headerAlign: 'center',
                align: 'center',
                width: 100
            },
            {
                field: 'created_time',
                headerName: "Created Time",
                headerAlign: 'center',
                align: 'center',
                width: 100
            }
        ]
        
        return columns;
    }

    const warehousePackagerColumn = () => {
        const columns = [
            {
                field: 'prod_id',
                headerName: "Production ID",
                headerAlign: 'center',
                align: 'center',
                width: 100
            },
            {
                field: 'wid',
                headerName: "Warehouse ID",
                headerAlign: 'center',
                align: 'center',
                width: 100
            },
            {
                field: 'item_name',
                headerName: "Item Name",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'product_name',
                headerName: "Product Name",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'product_brand_name',
                headerName: "Product Brand Name",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'product_serial_no',
                headerName: "Product Serial No",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'product_sku',
                headerName: "Product SKU",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'product_category',
                headerName: "Product Category",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'product_package',
                headerName: "Product Package",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'stock_prod_in',
                headerName: "Stock Production In",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'stock_prod_out',
                headerName: "Stock Production Out",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'total_raw_stock',
                headerName: "Total Raw Stock",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'remark',
                headerName: "Remark",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'prod_date',
                headerName: "Production Date",
                headerAlign: 'center',
                align: 'center',
                width: 180
            },
            {
                field: 'prod_time',
                headerName: "Production Time",
                headerAlign: 'center',
                align: 'center',
                width: 180
            }
        ]

        return columns;
    }

    const stockProducerColumn = () => {
        const columns = [
            {
                field: "product_stock_id",
                headerName: "Product Stock ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "wid",
                headerName: "Warehouse ID",
                headerAlign: "center",
                align: "center",
                width: 100
            },
            {
                field: "item_name",
                headerName: "Item Name",
                headerAlign: "center",
                align: "center",
                width: 100
            },
            {
                field: "stock_in",
                headerName: "Stock In",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_out",
                headerName: "Stock Out",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "total_stock",
                headerName: "Total Stock",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "description",
                headerName: "Description",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "created_date",
                headerName: "Created Date",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "created_time",
                headerName: "Created Time",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_remark",
                headerName: "Stock Remark",
                headerAlign: "center",
                align: "center"
            },
            {
                field: "stock_date_register",
                headerName: "Stock Date Register",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_time_register",
                headerName: "Stock Time Register",
                headerAlign: "center",
                align: "center",
                width: 180
            }
        ]
        return columns
    }

    const stockNonProducerColumn = () => {
        const columns = [
            {
                field: "product_stock_id",
                headerName: "Product Stock ID",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "pid",
                headerName: "PID",
                headerAlign: "center",
                align: "center",
                width: 100
            },
            {
                field: "bid",
                headerName: "BID",
                headerAlign: "center",
                align: "center",
                width: 100
            },
            {
                field: "product_name",
                headerName: "Product Name",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "product_brand_name",
                headerName: "Product Brand Name",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "product_serial_no",
                headerName: "Serial No",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "product_sku",
                headerName: "SKU",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "product_category",
                headerName: "Category",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "product_package",
                headerName: "Package",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_in",
                headerName: "Stock In",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_out",
                headerName: "Stock Out",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "total_stock",
                headerName: "Total Stock",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_remark",
                headerName: "Stock Remark",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_date_register",
                headerName: "Stock Date Register",
                headerAlign: "center",
                align: "center",
                width: 180
            },
            {
                field: "stock_time_register",
                headerName: "Stock Time Register",
                headerAlign: "center",
                align: "center",
                width: 180
            }
        ]
        return columns;
    }

    const manageWarehouseColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: "100%"
                        }}
                        onClick={() => {
                            
                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {

                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                    </Box>
                );
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                            
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Delete
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        }
    ];

    const manageStockColumn = [
        {
            field: "view",
            headerName: "View",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "12px",
                            width: "100%"
                        }}
                        onClick={() => {
                            
                        }}
                    >
                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                            View
                        </Typography>
                    </Button>
                )
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {

                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Edit
                            </Typography>
                        </Button>
                    </Box>
                );
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return (
                    <Box>
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.secondary.light,
                                color: theme.palette.background.alt,
                                fontSize: "12px",
                                width: "100%"
                            }}
                            onClick={() => {
                            
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                Delete
                            </Typography>
                        </Button>
                    </Box>
                )
            }
        }
    ]

    const handleSearchProducerWarehouseItem = () => {
        if(searchProducerItem) {
            const newIem = warehouseProducer.filter(item => {
                const itemData = item.item_name ? item.item_name.toUpperCase() : ''.toUpperCase();
                const textData = searchProducerItem.toUpperCase();
                return itemData.indexOf(textData) > -1;
            });
            setWarehouseProducerFiltered(newIem);
        } else {
            setWarehouseProducerFiltered(warehouseProducer)
        }
    }

    const handleSearchPackagerWarehouseItem = () => {
        if(searchPackagerItem) {
            const newItem = warehousePackager.filter(item => {
                const itemData = item.product_name ? item.product_name.toUpperCase() : ''.toUpperCase();
                const textData = searchPackagerItem.toUpperCase();
                return itemData.indexOf(textData) > -1;
            });
            setWarehousePackagerFiltered(newItem);
        } else {
            setWarehousePackagerFiltered(warehousePackager)
        }
    }

    const handleSearchItem = () => {
        if(isProducer) {
            if(searchStockProducerItem) {
                const newItem = stockItem.filter(item => {
                    const itemData = item.item_name ? item.item_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchStockProducerItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setStockItemFiltered(newItem);
            } else {
                setStockItemFiltered(stockItem)
            }
        } else {
            if(searchStockNonProducerItem) {
                const newItem = stockProduct.filter(item => {
                    const itemData = item.product_name ? item.product_name.toUpperCase() : ''.toUpperCase();
                    const textData = searchStockNonProducerItem.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setStockProductFiltered(newItem);
            } else {
                setStockProductFiltered(stockProduct)
            }
        }
    }

    const StockStat = () => {
        if(isProducer) {
            return (
                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="160px"
                    gap="20px"
                    sx={{
                        "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                    }}
                >
                    <StatBox
                        title="Total Issue"
                        value={totalIssue}
                        description="Issue(s)"
                        icon={
                            <ReportProblemOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                    <StatBox
                        title="Total Warehouse Item"
                        value={totalWarehouseItem}
                        description="Item(s)"
                        icon={
                            <Inventory2Outlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}   
                            />
                        }
                    />
                    <StatBox
                        title="Total Raw Stock In (MT)"
                        value={totalStockIn}
                        description="Raw Stock(s) In (MT)"
                        icon={
                            <Inventory2Outlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                    <StatBox
                        title="Total Raw Stock Out (MT)"
                        value={totalStockOut}
                        description="Raw Stock(s) Out (MT)"
                        icon={
                            <Inventory2Outlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                    <StatBox
                        title="Total Raw Stock (MT)"
                        value={totalStock}
                        description="Raw Stock(s) (MT)"
                        icon={
                            <Inventory2Outlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
            );
        } else if(isPackager) {
            return(
                <Box>
                    <Box
                        mt="20px"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="160px"
                        gap="20px"
                        sx={{
                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                        }}
                    >
                        <StatBox
                            title="Total Issue"
                            value={totalIssue}
                            description="Issue(s)"
                            icon={
                                <ReportProblemOutlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                        <StatBox
                            title="Total Warehouse Production Item"
                            value={totalWarehouseProductionItem}
                            description="Production(s)"
                            icon={
                                <PrecisionManufacturingOutlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                        <StatBox
                            title="Total Production Stock In"
                            value={totalWarehouseProductionStockIn}
                            description="Production(s) Stock In"
                            icon={
                                <PrecisionManufacturingOutlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }

                        />
                        <StatBox
                            title="Total Production Stock Out"
                            value={totalWarehouseProductionStockOut}
                            description="Production(s) Stock Out"
                            icon={
                                <PrecisionManufacturingOutlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                        <StatBox
                            title="Total Production Stock Raw Stock"
                            value={totalWarehouseProductionRawStock}
                            description="Production(s) Stock Out"
                            icon={
                                <PrecisionManufacturingOutlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Box>
                    <Box
                        mt="20px"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridAutoRows="160px"
                        gap="20px"
                        sx={{
                            "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                        }}
                    >
                        <StatBox
                            title="Total Product Stock In"
                            value={totalStockIn}
                            description="Product Stock(s) In"
                            icon={
                                <Inventory2Outlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                        <StatBox
                            title="Total Product Stock Out"
                            value={totalStockOut}
                            description="Product Stock(s) Out"
                            icon={
                                <Inventory2Outlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                        <StatBox
                            title="Total Product Stock"
                            value={totalStock}
                            description="Product Stock(s)"
                            icon={
                                <Inventory2Outlined
                                    sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Box>
                </Box>
            )
        } else {
            return(
                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="160px"
                    gap="20px"
                    sx={{
                        "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                    }}
                >
                    <StatBox
                        title="Total Issue"
                        value={totalIssue}
                        description="Issue(s)"
                        icon={
                            <ReportProblemOutlined
                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                    <StatBox
                        title="Total Product Stock In"
                        value={totalStockIn}
                        description="Product Stock(s) In"
                        icon={
                            <Inventory2Outlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                    <StatBox
                        title="Total Product Stock Out"
                        value={totalStockOut}
                        description="Product Stock(s) Out"
                        icon={
                            <Inventory2Outlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                    <StatBox
                        title="Total Product Stock"
                        value={totalStock}
                        description="Product Stock(s)"
                        icon={
                            <Inventory2Outlined
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                            />
                        }
                    />
                </Box>
            )
        }
    }

    const WarehouseProducer = () => {
        return (
            <Box>
                <Box>
                    <SubHeader title="Warehouse Item"/>
                </Box>
                <Box mt="20px">
                    <FlexBetween>
                        <SearchBar
                            placehorder="Search Item"
                            value={searchProducerItem}
                            onChange={newValue => setSearchProducerItem(newValue)}
                            onSearch={handleSearchProducerWarehouseItem}
                        />
                        <Box>
                            <Button
                                onClick={() => {
                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <AddOutlined sx={{ mr: "10px" }}/>
                                New Warehouse Item
                            </Button>
                        </Box>
                    </FlexBetween>
                </Box>
                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                    justifyContent="space-between"
                    rowGap="20px"
                    columnGap="1.33%"
                    height="60vh"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        overflow: "hidden",
                        overflowY: "scroll"
                    }}
                >
                    {warehouseProducerFiltered.map(
                        ({
                            wid,
                            item_name,
                            description,
                            created_date,
                            created_time
                        }) => (
                            <WarehouseProducerItem
                                key={wid}
                                wid={wid}
                                item_name={item_name}
                                description={description}
                                created_date={created_date}
                                created_time={created_time}
                            />
                        )
                    )}
                </Box>
                <Box mt="20px">
                    <SubHeader title="List of Warehouse Item"/>
                </Box>
                <Box
                    mt="20px"
                    height="60vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            borderRadius: "5rem"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.alt,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.wid}
                        rows={warehouseProducer}
                        columns={warehouseProducerColumn().concat(manageWarehouseColumn)}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                </Box>
            </Box>
        );
    }

    const WarehousePackager = () => {
        return (
            <Box>
                <Box>
                    <SubHeader title="Warehouse Production Item"/>
                </Box>
                <Box mt="20px">
                    <FlexBetween>
                        <SearchBar
                            placehorder="Search Item"
                            value={searchPackagerItem}
                            onChange={newValue => setSearchPackagerItem(newValue)}
                            onSearch={handleSearchPackagerWarehouseItem}
                        />
                        <Box>
                            <Button
                                onClick={() => {
                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <AddOutlined sx={{ mr: "10px" }}/>
                                New Production Item
                            </Button>
                        </Box>
                    </FlexBetween>
                </Box>
                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                    justifyContent="space-between"
                    rowGap="20px"
                    columnGap="1.33%"
                    height="60vh"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        overflow: "hidden",
                        overflowY: "scroll"
                    }}
                >
                    {warehousePackagerFiltered.map(
                        ({
                            prod_id,
                            wid,
                            item_name,
                            pid,
                            bid,
                            product_name,
                            product_brand_name,
                            product_serial_no,
                            product_sku,
                            product_category,
                            product_package,
                            stock_prod_in,
                            stock_prod_out,
                            total_raw_stock,
                            remark,
                            prod_date,
                            prod_time
                        }) => (
                            <WarehousePackagerItem
                                key={prod_id}
                                wid={wid}
                                item_name={item_name}
                                pid={pid}
                                bid={bid}
                                product_name={product_name}
                                product_brand_name={product_brand_name}
                                product_serial_no={product_serial_no}
                                product_sku={product_sku}
                                product_category={product_category}
                                product_package={product_package}
                                stock_prod_in={stock_prod_in}
                                stock_prod_out={stock_prod_out}
                                total_raw_stock={total_raw_stock}
                                remark={remark}
                                prod_date={prod_date}
                                prod_time={prod_time}
                            />
                        )
                    )}
                </Box>
                <Box mt="20px">
                    <SubHeader title="List of Warehouse Production Item"/>
                </Box>
                <Box
                    mt="20px"
                    height="60vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            borderRadius: "5rem"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.alt,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.prod_id}
                        rows={warehousePackager}
                        columns={warehousePackagerColumn().concat(manageWarehouseColumn)}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                </Box>
            </Box>
        )
    }

    const StockInOut = () => {
        return (
            <Box>
                <Box>
                    <SubHeader title={isProducer ? "Stock Item Activity" : "Stock Product Activity"}/>
                </Box>
                <Box
                    mt="20px"
                    mb="20px"
                    p="1.25rem 1rem"
                    height="60vh"
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="0.55rem"
                >
                    <BarChartStocks/>
                </Box>
                <Box mt="20px">
                    <FlexBetween>
                        <SearchBar
                            placeholder={isProducer ? "Search Item" : "Search Product"}
                            value={isProducer ? searchStockProducerItem : searchStockNonProducerItem}
                            onChange={newValue => isProducer ? setSearchStockProducerItem(newValue) : setSearchStockNonProducerItem(newValue)}
                            onSearch={handleSearchItem}
                        />
                    </FlexBetween>
                </Box>
                {
                    isProducer ? (
                        <Box
                            mt="20px"
                            mb="20px"
                            display="grid"
                            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                            justifyContent="space-between"
                            rowGap="20px"
                            columnGap="1.33%"
                            height="60vh"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                overflow: "hidden",
                                overflowY: "scroll"
                            }}
                        >
                            {stockItemFiltered.map(
                                ({
                                    wid,
                                    item_name,
                                    total_stock_in,
                                    total_stock_out,
                                    total_stock
                                }) => (
                                    <StockProducerItem
                                        key={wid}
                                        wid={wid}
                                        item_name={item_name}
                                        total_stock_in={total_stock_in}
                                        total_stock_out={total_stock_out}
                                        total_stock={total_stock}
                                    />
                                )
                            )}
                        </Box>
                    ) : 
                    (
                        <Box
                            mt="20px"
                            mb="20px"
                            display="grid"
                            gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                            justifyContent="space-between"
                            rowGap="20px"
                            columnGap="1.33%"
                            height="60vh"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                overflow: "hidden",
                                overflowY: "scroll"
                            }}
                        >
                            {stockProductFiltered.map(
                                ({
                                    pid,
                                    bid,
                                    product_name,
                                    product_brand_name,
                                    product_serial_no,
                                    product_sku,
                                    product_category,
                                    product_package,
                                    total_stock_in,
                                    total_stock_out,
                                    total_stock
                                }) => (
                                    <StockNonProducerItem
                                        key={pid}
                                        pid={pid}
                                        bid={bid}
                                        product_name={product_name}
                                        product_brand_name={product_brand_name}
                                        product_serial_no={product_serial_no}
                                        product_sku={product_sku}
                                        product_category={product_category}
                                        product_package={product_package}
                                        total_stock_in={total_stock_in}
                                        total_stock_out={total_stock_out}
                                        total_stock={total_stock}
                                    />
                                )
                            )}
                        </Box>
                    )
                }
                <Box>
                    <SubHeader title="List of Stock Item"/>
                </Box>
                <Box
                    mt="20px"
                    height="60vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            borderRadius: "5rem"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.alt,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        getRowId={(row) => row.wid}
                        rows={isProducer ? stockProducer : stockNonProducer}
                        columns={isProducer ? 
                            stockProducerColumn().concat(manageStockColumn) : 
                            stockNonProducerColumn().concat(manageStockColumn)}
                    />
                </Box>
                <Box
                    mt="20px"
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={valueBarChart}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList
                                variant="fullWidth"
                                onChange={handleBarChartChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                            >
                                <Tab icon={<CalendarTodayOutlined/>} iconPosition="start" label="Daily" value="1"/>
                                <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Monthly" value="2"/>
                                <Tab icon={<CalendarMonthOutlined/>} iconPosition="start" label="Yearly" value="3"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box
                                mt="20px"
                                p="1.25rem 1rem"
                                height="60vh"
                                backgroundColor={theme.palette.background.alt}
                                borderRadius="0.55rem"
                            >
                                <BarChartStocksDaily/>
                            </Box>
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Stock"
                                    value={totalStock}
                                    description="Daily Stock"
                                    icon={
                                        <Inventory2Outlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Stock In"
                                    value={totalStockIn}
                                    description="Daily Stock In"
                                    icon={
                                        <ExitToAppOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Stock Out"
                                    value={totalStockOut}
                                    description="Daily Stock Out"
                                    icon={
                                        <LogoutOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box
                                mt="20px"
                                p="1.25rem 1rem"
                                height="60vh"
                                backgroundColor={theme.palette.background.alt}
                                borderRadius="0.55rem"
                            >
                                <BarChartStocksMonthly/>
                            </Box>
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Stock"
                                    value={totalStock}
                                    description="Monthly Stock"
                                    icon={
                                        <Inventory2Outlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Stock In"
                                    value={totalStockIn}
                                    description="Monthly Stock In"
                                    icon={
                                        <ExitToAppOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Stock Out"
                                    value={totalStockOut}
                                    description="Monthly Stock Out"
                                    icon={
                                        <LogoutOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Box
                                mt="20px"
                                p="1.25rem 1rem"
                                height="60vh"
                                backgroundColor={theme.palette.background.alt}
                                borderRadius="0.55rem"
                            >
                                <BarChartStocksYearly/>
                            </Box>
                            <Box
                                mt="10px"
                                mb="20px"
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridAutoRows="160px"
                                gap="20px"
                                sx={{
                                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                                }}
                            >
                                <StatBox
                                    title="Total Stock"
                                    value={totalStock}
                                    description="Yearly Stock"
                                    icon={
                                        <Inventory2Outlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Stock In"
                                    value={totalStockIn}
                                    description="Yearly Stock In"
                                    icon={
                                        <ExitToAppOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                                <StatBox
                                    title="Total Stock Out"
                                    value={totalStockOut}
                                    description="Yearly Stock Out"
                                    icon={
                                        <LogoutOutlined
                                            sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                        />
                                    }
                                />
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>
        );
    }

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="Stock Report" subtitle="Stock Report Activity"/>
                {
                    isDashboard ? (
                        <Box/>
                    ) : (
                        <Box>
                            <Button
                                onClick={() => {

                                }}
                                variant="contained"
                                color="info"
                                sx={{
                                    backgroundColor: theme.palette.secondary.light,
                                    color: theme.palette.background.alt,
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px"
                                }}
                            >
                                <DownloadOutlined sx={{ mr: "10px" }}/>
                                Download Reports
                            </Button>
                        </Box>
                    )
                }
            </FlexBetween>
            <StockStat/>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            {
                                isProducer | isPackager ? (
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<WarehouseOutlined/>} iconPosition="start" label="Warehouse" value="1"/>
                                        <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Stocks" value="2"/>
                                    </TabList>
                                ) : (
                                    <TabList
                                        variant="fullWidth"
                                        onChange={handleChange}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                    >
                                        <Tab icon={<Inventory2Outlined/>} iconPosition="start" label="Stocks" value="1"/>
                                    </TabList>
                                )
                            }
                        </Box>
                        {
                            isProducer | isPackager ? (
                                <Box>
                                    <TabPanel value="1">
                                        {
                                            isProducer ? (
                                                <WarehouseProducer/>
                                            ) : (
                                                <WarehousePackager/>
                                            )
                                        }
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <StockInOut/>
                                    </TabPanel>
                                </Box>
                            ) : (
                                <Box>
                                    <TabPanel value="1">
                                        <StockInOut/>
                                    </TabPanel>
                                </Box>
                            )
                        }
                    </TabContext>
                </Box>
            </Box>
        </Box>
    );
}

export default UserStocks;