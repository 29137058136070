import { 
    AttachMoneyOutlined, 
    DownloadOutlined, 
    Inventory2Outlined, 
    LocalShippingOutlined, 
    RequestQuoteOutlined
} from "@mui/icons-material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import StatBox from "components/stats/StatBox";

import { 
    mockDeliveryListIssue, 
    mockQuotaListIssue, 
    mockStockListIssue, 
    mockTransactionListIssue 
} from "data/mockDataIssue";

import {
    mockConsistIssue, 
    mockDeliveryIssue,
    mockOverallIssue, 
    mockStateQuotaIssue, 
    mockStockIssue, 
    mockTransactionIssue 
} from "data/mockDataStateIssues";

import FlexBetween from "modules/FlexBetween";
import StatesIssueDeliveryStat from "modules/statesDistricts/issues/states/StatesIssueDeliveryStat";
import StatesIssueQuotaStat from "modules/statesDistricts/issues/states/StatesIssueQuotaStat";
import StatesIssueStockStat from "modules/statesDistricts/issues/states/StatesIssueStockStat";
import StatesIssueTransactionStat from "modules/statesDistricts/issues/states/StatesIssueTransactionStat";
import { useState } from "react";

const UserIssues = ({isDashboard = false}) => {

}

export default UserIssues;