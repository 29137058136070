import { Box, Container, Link, useTheme } from "@mui/material";
import { Typography } from "@mui/material";

import Lottie from "lottie-react"

import email_verification_success from "assets/animations/email-verification-success.json"
import email_verification_error from "assets/animations/email-verification-error.json"
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const UserEmailVeification = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [verification, setVerification] = useState(false)

    const Copyright = (props) => {
        return (
            <Typography variant="body2" align="center" {...props}>
                Copyright © {new Date().getFullYear()} Dataran Teknologi Sdn. Bhd. All Rights Reserved.
            </Typography>
        )
    }

    const Verification = () => {
        if(verification) {
            return (
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                >
                    <Box width={340} height={340}>
                        <Lottie 
                            animationData={email_verification_success} 
                            loop={true}/>
                    </Box>
                    <Typography variant="h5" align="center" color={theme.palette.secondary[300]}>
                        Your account has successfully verified. You can begin to sign in your account.
                    </Typography>
                    <Link 
                        component="button"
                        variant="h5"
                        onClick={() => {
                            navigate("/")
                        }} 
                        style={{ 
                            color: theme.palette.secondary.light,
                            marginTop: 20
                        }}>
                        Let's sign in to your account
                    </Link>
                </Box>
            )
        } else {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Box width={340} height={340}>
                        <Lottie 
                            animationData={email_verification_error} 
                            loop={true}/>
                    </Box>
                    <Typography variant="h5" align="center" color={theme.palette.secondary[300]}>
                        Unable to verify you account. Please check with the account administrator.
                    </Typography>
                    <Link 
                        component="button"
                        variant="h6"
                        onClick={() => {
                            navigate("/")
                        }} 
                        style={{ 
                            color: theme.palette.secondary.light,
                            marginTop: 20
                        }}>
                        Already have an account? Sign in
                    </Link>
                    <Link 
                        component="button"
                        variant="h6"
                        onClick={() => {
                            navigate("/register")
                        }} 
                        style={{ 
                            color: theme.palette.secondary.light,
                        }}>
                        Don't have an account? Sign up
                    </Link>
                </Box>
            )
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Verification/>
            </Box>
            <Copyright sx={{ mt: 20, mb: 4}}/>
        </Container>
    )
}

export default UserEmailVeification