import { network } from "state/fetch/network";

const url_wholesaler_company = `${network.domain_api}/alarm/companies/wholesaler`

/* View API */

/**
 * Get alarm wholesaler company data
 * 
 * @returns alarm wholesaler company data
 */
export const getAlarmWholesalerCompany = async () => {
    let response
    try {
        response = await fetch(`${url_wholesaler_company}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Get alarm wholesaler company data by id
 * 
 * @param {*} param0 alarm wholesaler company id
 * @returns alarm wholesaler company data by id
 */
export const getAlarmWholesalerCompanyID = async ({aid}) => {
    let response
    try {
        response = await fetch(`${url_wholesaler_company}/${aid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/* Controller API */

/**
 * Add alarm wholesaler company data
 * 
 * @param {*} data alarm wholesaler company data
 * @returns added alarm wholesaler company data
 */
export const addAlarmWholesalerCompany = async (data) => {
    let response
    try {
        response = await fetch(`${url_wholesaler_company}/new`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update wholesaler alarm
 * 
 * @param {*} param0    wholesaler category id and data
 * @returns updated wholesaler alarm
 */
export const updateWholesalerAlarm = async ({data}) => {
    let response
    try {
        response = await fetch(`${url_wholesaler_company}/update`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Update alarm wholesaler company data
 * 
 * @param {*} param0 alarm wholesaler company id and data
 * @returns updated alarm wholesaler company data
 */
export const updateAlarmWholesalerCompany = async ({aid, cid, unique_id, data}) => {
    let response
    try {
        response = await fetch(`${url_wholesaler_company}/update/${aid}/${cid}/${unique_id}`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Delete alarm wholesaler company data
 * 
 * @param {*} param0 alarm wholesaler company id
 * @returns deleted whoelsaler company data
 */
export const deleteAlarmWholesalerCompany = async ({aid, cid, unique_id}) => {
    let response
    try {
        response = await fetch(`${url_wholesaler_company}/delete/${aid}/${cid}/${unique_id}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}