import { network } from "../network";

const url_companies = `${network.domain_api}/companies`;
const url_company = `${network.domain_api}/company`;
const url_company_doc = `${network.domain_api}/company/doc`;

/* View API */

/**
 * Get companies data
 * 
 * @returns companies data
 */
export const getCompanies = async () => {
    let response
    try {
        response = await fetch(`${url_companies}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    console.log(json)
    return json;
}

/**
 * Get company document data
 */
export const getCompanyDoc = async () => {
    await fetch(`${url_company_doc}`, {
        method: 'GET',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Disposition": "inline; filename=company-profile.pdf",
            Accept: 'application/pdf',
            "Content-Type": "application/pdf"
        }
    }).then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "company-profile.pdf"
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert("Download company profile PDF document");
    }).catch(() => alert("Unable to download company profile PDF document"))
}

/**
 * Get company state document
 * 
 * @param {*} param0 company doc state id
 */
export const getCompanyStateDoc = async ({sid}) => {
    console.log(sid)
    await fetch(`${url_company_doc}/states/${sid}`, {
        method: 'GET',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Disposition": "inline; filename=company-profile.pdf",
            Accept: 'application/pdf',
            "Content-Type": "application/pdf"
        }
    }).then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "company-profile.pdf"
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert("Download company profile PDF document");
    }).catch(() => alert("Unable to download company profile PDF document"))
}

/**
 * Get company district document
 * 
 * @param {*} param0 company state id and district id
 */
export const getCompanyDistrictDoc = async ({sid, did}) => {
    await fetch(`${url_company_doc}/states/${sid}/${did}`, {
        method: 'GET',
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Disposition": "inline; filename=company-profile.pdf",
            Accept: 'application/pdf',
            "Content-Type": "application/pdf"
        }
    }).then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "company-profile.pdf"
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert("Download company profile PDF document");
    }).catch(() => alert("Unable to download company profile PDF document"))
}

/**
 * Get companies state data
 * 
 * @param {*} param0 companies state id
 * @returns companies state data
 */
export const getCompaniesState = async({sid}) => {
    let response
    try {
        response = await fetch(`${url_companies}/states/${sid}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get companies district data
 * 
 * @param {*} param0 company district id
 * @returns companies district data
 */
export const getCompaniesDistrict = async({sid, did}) => {
    let response
    try {
        response = await fetch(`${url_companies}/states/district/${sid}/${did}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/**
 * Get company data by company unique id
 * 
 * @param {*} unique_id company unique id
 * @returns company data by company unique id
 */
export const getCompany = async({unique_id}) => {
    let response
    try {
        response = await fetch(`${url_company}/${unique_id}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
    } catch(error) {
        response = error
    }
    let json = await response.json();
    return json;
}

/* Controller API */

/**
 * Add company data
 * 
 * @param {*} data company data
 * @returns added company data
 */
export const addCompany = async (data) => {
    let response
    try {
        response = await fetch(`${url_company}/new`, {
            method: 'POST',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
    } catch(error) { 
        response = error
    }
    return await response.json()
}

/**
 * Update company data
 * 
 * @param {*} param0 company id and data
 * @returns updated company data
 */
export const updateCompany = async({unique_id, cid, state_name, district_name, data}) => {
    let response
    try {
        response = await fetch(`${url_company}/update/${unique_id}/${cid}/${state_name}/${district_name}`, {
            method: 'PUT',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
    } catch(error) {
        response = error;
    }
    return await response.json()
}

/**
 * Delete company data
 * 
 * @param {*} param0 company id
 * @returns deleted company data
 */
export const deleteCompany = async ({unique_id, cid}) => {
    let response;
    try {
        response = await fetch(`${url_company}/delete/${unique_id}/${cid}`, {
            method: 'DELETE',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        });
    } catch(error) {
        response = error;
    }
    let json = response.json();
    console.log(json)
    return await json;
}