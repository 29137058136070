import { useEffect, useState } from "react";
import FlexBetween from "modules/FlexBetween";
import Header from "components/Header";

import {
    Box, 
    Button, 
    Tab, 
    useMediaQuery, 
    useTheme
} from "@mui/material";



import {
    TabContext,
    TabList,
    TabPanel
} from "@mui/lab";

import {
    DownloadOutlined,
    WarningOutlined,
    LocalShippingOutlined,
    MonetizationOnOutlined,
    RequestPageOutlined,
    WarehouseOutlined
} from "@mui/icons-material";

import UserDistribution from "../distributions";
import UserSale from "../sales";
import UserOrders from "../orders";
import UserIssues from "../issues";
import UserStocks from "../stocks";

const UserDashboard = ({isProducer = false, isPackager = false}) => {
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)")
    const isNonMobile = useMediaQuery("(min-width: 1000px)");

    const [companyName, setCompanyName] = useState("")
    const [companyType, setCompanyType] = useState("")
    const [value, setValue] = useState('1')
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    useEffect(() => {
        setCompanyName("Sime Darby Oils Langat Refinery Sdn. Bhd.")
        setCompanyType("Producer")
    })

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title={companyName} subtitle={companyType}/>
                <Box>
                    <Button
                        variant="contained"
                        color="info"
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        <DownloadOutlined sx={{ mr: "10px" }}/>
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween>
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& < div": { gridColumn: isNonMediumScreens ? undefined: "span 12" }
                }}
            >
                <Box
                    gridColumn="span 12"
                    gridRow="span 3"
                >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                variant="fullWidth"
                                onChange={handleChange}
                                textColor="inherit"
                                indicatorColor="secondary"
                            >
                                <Tab icon={<LocalShippingOutlined/>} iconPosition="start" label="Distributions" value="1"/>
                                <Tab icon={<MonetizationOnOutlined/>} iconPosition="start" label="Sales" value="2"/>
                                <Tab icon={<RequestPageOutlined/>} iconPosition="start" label="Orders" value="3"/>
                                <Tab icon={<WarehouseOutlined/>} iconPosition="start" label="Stocks" value="4"/>
                                <Tab icon={<WarningOutlined/>} iconPosition="start" label="Issues" value="5"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <UserDistribution isDashboard isProducer={isProducer} isPackager={isPackager}/>
                        </TabPanel>
                        <TabPanel value="2">
                            <UserSale isDashboard isProducer={isProducer} isPackager={isPackager}/>
                        </TabPanel>
                        <TabPanel value="3">
                            <UserOrders isDashboard isProducer={isProducer} isPackager={isPackager}/>
                        </TabPanel>
                        <TabPanel value="4">
                            <UserStocks isDashboard isProducer={isProducer} isPackager={isPackager}/>
                        </TabPanel>
                        {/*
                            <TabPanel value="5">
                                <UserIssues isDashboard isProducer={isProducer} isPackager={isPackager}/>
                            </TabPanel>
                        */}
                    </TabContext>
                </Box>
            </Box>
        </Box>
    );
}

export default UserDashboard;