const url_alarms = 'https://datarans.com.my/api/lica/alarms';
const url_alarms_doc = 'https://datarans.com.my/api/lica/alarms/doc';

/* View API */

// Get list of alarms
export const getAlarms = async () => {
    let response
    try {
        response = await fetch(`${url_alarms}`, {
            method: 'GET',
            headers: {
                'x-api-key': 'my-api-key',
                "Access-Control-Allow-Origin": "*",
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
    } catch(error) {
        response = error
        console.log(response)
    }
    let json = await response.json()
    console.log(json)
    return json
}

/**
 * Get alarms document data
 */
export const getAlarmsDoc = async () => {
    await fetch(`${url_alarms_doc}`, {
        method: 'GET',
        headers: {
            'x-api-key': 'my-api-key',
            "Access-Control-Allow-Origin": "*",
            "Content-Disposition": "inline; filename=company-profile.pdf",
            "Content-Type": "application/pdf"
        }
    }).then(resp => resp.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "alarm-company-profile.pdf"
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert("Download alarm company profile PDF document");
    }).catch(() => alert("Unable to download alarm company profile PDF document"))
}